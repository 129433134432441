<template>
  <span>
    <v-list-item v-if="$_isPerson(value.creator)">
      <v-list-item-avatar class="align-self-start mr-2">
        <v-avatar size="40" :color="color" class="white--text">
          <PortraitImage v-if="false" :value="value.creator" />
          <span v-else>{{ initals }}</span>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content class="received-message">
        <v-card outlined class="flex-none ma-0 pa-0">
          <v-alert
            colored-border
            border="right"
            :color="color"
            class="pt-2 pb-2 ma-0"
          >
            <div class="d-flex flex-column">
              <span class="text-subtitle-1 align-self-end" v-if="value.status">
                <i>{{ value.status.description }}</i>
                <v-icon class="mt-n1" :color="color" right>{{
                  icon
                }}</v-icon></span
              >
              <span
                class="chat-message text-body-2 align-self-end"
                v-if="value.message"
              >
                {{ value.message }}
              </span>
              <span class="text-caption font-italic align-self-end chat-message"
                ><DateValue :value="value.creationDate" long />,
                {{ formatTime(value.creationTime, true) }}</span
              >
            </div>
          </v-alert>
        </v-card>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-else>
      <v-list-item-content class="sent-message justify-end">
        <v-card outlined class="flex-none">
          <v-alert
            colored-border
            border="left"
            :color="color"
            class="pt-2 pb-2 ma-0"
          >
            <div class="d-flex flex-column">
              <span class="text-subtitle-1" v-if="value.status">
                <v-icon class="mt-n1" :color="color" left>{{ icon }}</v-icon>
                <i>{{ value.status.description }}</i></span
              >
              <span
                class="chat-message text-body-2 align-self-end"
                v-if="value.message"
              >
                {{ value.message }}
              </span>
              <span class="text-caption font-italic align-self-end chat-message"
                ><DateValue :value="value.creationDate" long />,
                {{ formatTime(value.creationTime, true) }}</span
              >
            </div>
          </v-alert>
        </v-card>
      </v-list-item-content>

      <v-list-item-avatar class="align-self-start ml-2">
        <v-avatar size="40" :color="color" class="white--text">
          <PortraitImage v-if="false" :value="value.creator" />
          <span v-else>{{ initals }}</span>
        </v-avatar>
      </v-list-item-avatar>
    </v-list-item>
  </span>
</template>
<script>
import { defineComponent } from "vue";
import { formatTime } from "common/utils/date.js";
import {
  justificationStatusColor,
  justificationStatusIcon,
} from "common/utils/icons.js";

import DateValue from "common/components/DateValue.vue";
import PortraitImage from "common/components/PortraitImage.vue";
export default defineComponent({
  components: { DateValue, PortraitImage },
  props: ["value"],

  computed: {
    initals() {
      if (!this.value) {
        return "";
      }
      if (this.value.creator.code) {
        return this.value.creator.code;
      }
      return (
        this.value.creator.firstName.charAt(0) +
        this.value.creator.lastName.charAt(0)
      );
    },
    icon() {
      if (!this.value.status) {
        return "mdi-chat";
      }
      return justificationStatusIcon(this.value.status);
    },
    color() {
      if (!this.value.status) {
        return "primary";
      }
      return justificationStatusColor(this.value.status);
    },
  },
  methods: {
    formatTime,
  },
});
</script>

<style scoped>
.chat-message {
  display: unset !important;
  white-space: break-spaces;
}
.chat-screen {
  max-height: 320px;
  overflow-y: auto;
}
.flex-none {
  flex: unset;
}
.received-message:after {
  position: absolute;
  content: "";
  margin-left: -4px;
  top: 22px;
  width: 0.5rem;
  height: 0.5rem;
  background-color: white;
  border: 1px solid grey;
  border-color: transparent transparent rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12);
  transform: rotate(45deg);
}
.sent-message:after {
  position: absolute;
  content: "";
  margin-right: -4px;
  top: 22px;
  width: 0.5rem;
  height: 0.5rem;
  background-color: white;
  border: 1px solid grey;
  border-color: rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12) transparent transparent;
  transform: rotate(45deg);
}
</style>
