<template>
  <v-bottom-sheet v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-on="on" v-bind="attrs" v-if="value">
        <ListIcon
          ><v-icon>{{ icon }}</v-icon></ListIcon
        >
        <v-list-item-content>
          <v-list-item-title>
            {{ value.description }}
          </v-list-item-title>
          <v-list-item-subtitle>{{
            value.site.description
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <RoomSheet :value="value" @close="dialog = false" />
  </v-bottom-sheet>
</template>
<script>
import { defineComponent } from "vue";

import RoomSheet from "common/components/RoomSheet.vue";

export default defineComponent({
  name: "RoomItem",
  components: { RoomSheet },
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    icon: null,
    value: null,
  },
});
</script>
