<template>
  <v-container>
    <v-app-bar dark app color="primary" dense>
      <v-btn icon>
        <v-icon>mdi-locker-multiple</v-icon>
      </v-btn>

      <v-toolbar-title>Schränke</v-toolbar-title>

      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab :to="{ name: 'LockersList' }"> Schäftli </v-tab>
          <v-tab :to="{ name: 'LockersStudents' }"> Schüler:innen </v-tab>
          <v-tab :to="{ name: 'LockersReports' }"> Berichte </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <router-view></router-view>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Locker",
});
</script>
