<template>
  <v-card class="mb-2">
    <v-system-bar>PersonPicker</v-system-bar>
    <v-card-text>
      <p>Stellt eine Person mit Avatar und Namen dar</p>
      <v-row>
        <v-col
          ><PersonPicker
            v-model="person"
            :group="group"
            :color="color ? color.code : null"
        /></v-col>
        <v-col>
          <LookupValueInput label="group" v-model="selected" :items="groups" />
          <LookupValueInput label="Farbe" v-model="color" :items="colors"
        /></v-col>
      </v-row>
      <code
        >&lt;PersonPicker group="{{ group }}" :color="{{
          color ? color.code : null
        }}" /&gt;</code
      >
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonPicker from "@/components/PersonPicker";
export default defineComponent({
  name: "PersonPickerDemo",
  components: { LookupValueInput, PersonPicker },
  data() {
    return {
      groups: [
        { id: 1, code: "student", description: "Schüler*innen" },
        { id: 2, code: "teacher", description: "Lehrer*innen" },
        { id: 3, code: "person", description: "Personen" },
        { id: 4, code: "parent", description: "Eltern" },
        { id: 5, code: "intern", description: "Praktikant*innen" },
      ],
      color: {},
      colors: [
        { id: 1, code: "primary", description: "Gymer-Blau" },
        { id: 2, code: "info", description: "Unterricht" },
        { id: 3, code: "success", description: "Organisation" },
        { id: 4, code: "error", description: "Menschen" },
        { id: 5, code: "warning", description: "myGymer" },
      ],
      selected: {},
      person: {},
    };
  },
  computed: {
    group() {
      return this.selected ? this.selected.code : null;
    },
  },
  created() {
    this.selected = this.groups[1];
  },
});
</script>
