<template>
  <span v-bind="$attrs" v-on="{ ...$listeners }" v-if="value">{{
    formated
  }}</span>
  <span v-bind="$attrs" v-on="{ ...$listeners }" v-else>{{ placeholder }}</span>
</template>
<script>
import { defineComponent } from "vue";

import { formatTime } from "common/utils/date";
export default defineComponent({
  name: "TimeValue",
  props: {
    value: { type: String },
    placeholder: { type: String, default: "—" },
    seconds: { type: Boolean, default: false },
  },
  computed: {
    formated() {
      return formatTime(this.value, false, this.seconds);
    },
  },
});
</script>
