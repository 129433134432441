var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-toolbar',{staticClass:"mb-4",attrs:{"flat":""}},[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","bottom":""}}),_c('DateInput',{attrs:{"clearable":"","dense":"","hide-details":"","label":"Stichtag"},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}}),_c('v-text-field',{staticClass:"ml-2",attrs:{"dense":"","hide-details":"","label":"Klasse/Stufe"},model:{value:(_vm.filter.schoolClass),callback:function ($$v) {_vm.$set(_vm.filter, "schoolClass", $$v)},expression:"filter.schoolClass"}}),_c('v-spacer'),_vm._v(" "+_vm._s(Object.keys(_vm.itemsFiltered).length)+" SuS "),_c('v-spacer'),_c('ReportButton',{staticClass:"mt-2",attrs:{"color":"primary","resource":"report/portfoliooverview","title":"alle","parameters":{
        type: 'students',
        id: Object.keys(_vm.itemsFiltered),
      }}})],1),_c('v-card',[_vm._l((_vm.itemsFiltered),function(value,key){return [_c('v-row',{key:key},[_c('v-col',[_c('PersonItem',{attrs:{"value":value[0].student}})],1),_c('v-col',_vm._l((value),function(item){return _c('v-chip',{key:item.id,staticClass:"mx-1",attrs:{"to":{ name: 'PortfolioDetail', params: { id: item.id } }}},[_vm._v(_vm._s(item.title))])}),1),_c('v-col',[_c('ReportButton',{staticClass:"mt-2",attrs:{"color":"primary","depressed":"","resource":"report/portfoliooverview","parameters":{
              type: 'student',
              id: value[0].student.id,
            }}})],1)],1),_c('v-divider',{key:'d' + key})]})],2),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }