<template>
  <v-card>
    <v-data-table
      :items="items"
      :headers="headers"
      :search="search"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:item.available="{ item }">
        <v-simple-checkbox
          v-model="item.available"
          disabled
        ></v-simple-checkbox>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "SciencePlusAvailability",
  props: ["search"],
  data() {
    return {
      items: [],
      headers: [
        {
          text: "Fach",
          value: "description",
        },
        {
          text: "Code",
          value: "code",
        },
        {
          text: "Aktiv",
          value: "available",
        },
      ],
    };
  },
  async created() {
    this.items = (
      await this.apiList({
        resource: "optional/scienceplusavailability",
      })
    ).map((el) => ({ ...el.subject, available: true }));
  },
});
</script>
