var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-app-bar',{attrs:{"dark":"","app":"","color":"primary","clipped-right":""}},[_c('v-app-bar-nav-icon',[_c('v-icon',[_vm._v("mdi-account-multiple")])],1),_c('v-toolbar-title',[_vm._v("Schulklassen")]),_c('v-spacer'),_c('SearchField',{attrs:{"hint":"Klasse (z.B. 22, G23, W25a), Klassenlehrer*in, SF (z.B BG, S, PAM)"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',[_c('v-col',[(_vm.schoolClass)?_c('v-card',[_c('v-list-item',[_c('v-list-item-content',[_c('v-row',[_c('v-col',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.schoolClass.code)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.schoolClass.studentCount.total)+" Schüler:innen ("+_vm._s(_vm.schoolClass.studentCount.female)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-gender-female")]),_vm._v(", "+_vm._s(_vm.schoolClass.studentCount.male)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-gender-male")]),(_vm.schoolClass.studentCount.fluid > 0)?_c('span',[_vm._v(", "+_vm._s(_vm.schoolClass.studentCount.fluid)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-gender-transgender")])],1):_vm._e(),_vm._v(") ")],1)],1)],1)],1),_c('v-spacer'),_c('v-col',[_c('PersonItem',{attrs:{"value":{
                    ..._vm.schoolClass.teacher,
                    description: 'Klassenlehrperson',
                  }}}),(_vm.schoolClass.substituteTeacher)?_c('PersonItem',{attrs:{"value":{
                    ..._vm.schoolClass.substituteTeacher,
                    description: 'Stv. Klassenlehrperson',
                  }}}):_vm._e()],1)],1)],1)],1),_c('v-divider'),_c('v-tabs',{attrs:{"icons-and-text":""}},[_c('v-tabs-slider',{attrs:{"color":"error"}}),_c('v-tab',{attrs:{"to":{ name: 'SchoolClassContact' }}},[_vm._v("Kontakt"),_c('v-icon',[_vm._v("mdi-email")])],1),_c('v-tab',{attrs:{"to":{ name: 'SchoolClassList' }}},[_vm._v("Liste"),_c('v-icon',[_vm._v("mdi-format-align-left")])],1),_c('v-tab',{attrs:{"to":{
              name: 'SchoolClassPortrait',
            }}},[_vm._v("Porträts"),_c('v-icon',[_vm._v("mdi-account-box")])],1),_c('v-tab',{attrs:{"to":{ name: 'SchoolClassCourses' }}},[_vm._v("Kurse"),_c('v-icon',[_vm._v("mdi-human-male-board")])],1),_c('v-tab',{attrs:{"to":{ name: 'SchoolClassAbsences' }}},[_vm._v("Absenzen"),_c('v-icon',[_vm._v("mdi-bed")])],1),_c('v-tab',{attrs:{"to":{ name: 'SchoolClassLocker' }}},[_vm._v(" Schränke"),_c('v-icon',[_vm._v("mdi-locker-multiple")])],1),_c('v-tab',{attrs:{"to":{ name: 'SchoolClassReport' }}},[_vm._v("Berichte"),_c('v-icon',[_vm._v("mdi-text-box-multiple")])],1)],1),_c('v-divider'),_c('router-view')],1):_vm._e()],1),_c('v-col',{staticClass:"col-auto"},[_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","permanent":"","right":""}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_vm._l((_vm.filteredItems),function(item){return _c('SchoolClass',{key:item.id,attrs:{"clickable":"","nodetails":"","value":item},on:{"click":function($event){return _vm.selectSchoolClass(item)}}})}),(!_vm.loading && _vm.filteredItems.length == 0)?_c('v-list-item',[_vm._v("keine Suchtreffer")]):_vm._e(),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}}):_vm._e()],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }