<template>
  <v-card class="mb-2">
    <v-system-bar>LookupValue</v-system-bar>
    <v-card-text>
      <p>Stellt einen LookupValue dar.</p>
      <v-row>
        <v-col>
          <LookupValue :value="value" />
        </v-col>
        <v-col> </v-col>
      </v-row>

      <code>&lt;LookupValue :value="value"/&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import LookupValue from "common/components/LookupValue.vue";
export default defineComponent({
  name: "LookupValueDemo",
  components: { LookupValue },
  data() {
    return {
      value: {
        id: 1,
        description: "Geistes- und Humanwissenschaften",
        code: "GH",
      },
    };
  },
});
</script>
