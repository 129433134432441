var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":"","tile":"","loading":_vm.loading}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Übersicht")]),_c('v-list-item-subtitle',[_vm._v("über "+_vm._s(_vm.portfolios.length)+" Portfolios ")])],1),_c('v-list-item-action',[_c('ReportButton',{attrs:{"icon":"","resource":"report/portfoliooverview","parameters":{
                  type: 'student',
                  id: _vm.id,
                  orientation: 'portrait',
                }}})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Portfolios")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.portfolios.length)+" Seiten ")])],1),_c('v-list-item-action',[_c('ReportButton',{attrs:{"icon":"","resource":"report/portfolio","parameters":{
                  type: 'student',
                  id: _vm.id,
                  orientation: 'portrait',
                }}})],1)],1)],1)],1),_c('v-col',[_c('v-list',_vm._l((_vm.portfolios),function(portfolio){return _c('v-list-item',{key:portfolio.id},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(portfolio.type.description))]),_c('v-list-item-subtitle',[_c('DateValue',{attrs:{"value":portfolio.creationDate}}),_vm._v(", "+_vm._s(portfolio.creationTime))],1)],1),_c('v-list-item-action',[_c('ReportButton',{attrs:{"icon":"","resource":"report/portfolio","parameters":{
                  type: 'portfolio',
                  id: portfolio.id,
                  orientation: 'portrait',
                }}})],1)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }