<template>
  <QrcodeVue v-bind="$attrs" :value="value"></QrcodeVue>
</template>
<script>
import { defineComponent } from "vue";
import QrcodeVue from "qrcode.vue";
export default defineComponent({
  components: { QrcodeVue },
  props: ["value"],
});
</script>
