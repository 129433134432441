<template>
  <div>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-file</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>Zuständigkeiten</v-toolbar-title>
      <v-btn
        :to="{ name: 'IndexEdit', params: { id: 0 } }"
        absolute
        bottom
        right
        color="success"
        fab
        small
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-app-bar>
    <v-container fluid>
      <v-row>
        <v-col cols="4">
          <v-card flat :loading="loading">
            <v-list>
              <v-list-item>
                <v-list-item-icon><v-icon>mdi-brain</v-icon></v-list-item-icon>
                <LookupValueInput
                  single-line
                  hide-details
                  dense
                  v-model="filter"
                  label="Typ"
                  :items="types"
                  disabled
                />
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon>mdi-account</v-icon></v-list-item-icon
                >
                <PersonInput
                  single-line
                  hide-details
                  dense
                  v-model="person"
                  label="Personen"
                  :people="people"
                  clearable
                />
              </v-list-item>
              <v-list-item class="mb-4">
                <v-list-item-icon
                  ><v-icon>mdi-magnify</v-icon></v-list-item-icon
                >

                <v-text-field
                  v-model="search"
                  single-line
                  hide-details
                  dense
                  label="Suche"
                />
              </v-list-item>
              <v-divider />
              <v-list-item
                v-for="item in itemsFiltered"
                :key="item.id"
                :to="{ name: 'IndexDetails', params: { id: item.id } }"
              >
                <v-list-item-avatar
                  :color="color(item.type.id)"
                  size="32"
                  class="white--text"
                >
                  {{ item.sortOrder > -1 ? item.sortOrder : "" }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.type.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>

        <v-col cols="8">
          <router-view @dataChanged="fetchData"></router-view>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonInput from "common/components/PersonInput.vue";
export default defineComponent({
  components: { LookupValueInput, PersonInput },
  data() {
    return {
      items: [],
      search: "",
      filter: null,
      person: null,
      types: [],
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (item) =>
          (!this.filter || item.type.id == this.filter.id) &&
          (!this.person ||
            item.people.map((el) => el.person.id).includes(this.person.id)) &&
          (!this.search ||
            item.title.toLowerCase().includes(this.search.toLowerCase()) ||
            (item.keywords != null &&
              item.keywords.toLowerCase().includes(this.search.toLowerCase())))
      );
    },
    people() {
      return [
        ...new Set(
          this.items.reduce(
            (result, value) => [
              ...result,
              ...value.people.map((el) => el.person),
            ],
            []
          )
        ),
      ];
    },
  },
  watch: {
    itemsFiltered() {
      if (this.itemsFiltered.length == 1) {
        const item = this.itemsFiltered[0];
        if (this.$route.params.id != item.id) {
          this.$router.push({
            name: "IndexDetails",
            params: { id: item.id },
          });
        }
      }
    },
  },
  methods: {
    color(id) {
      switch (id) {
        case 1:
          return "error";
        case 2:
          return "info";
        case 3:
          return "success";
        case 4:
          return "warning";
        default:
          return "grey";
      }
    },
    async fetchData() {
      this.loading = true;
      const indices = await this.apiList({ resource: "document/indexentry" });

      this.items = indices.sort((a, b) =>
        a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : 0
      );
      this.types = await this.apiList({ resource: "document/indexentrytype" });
      this.filter = this.types[0];
      this.loading = false;
    },
  },

  created() {
    this.fetchData();
  },
});
</script>
