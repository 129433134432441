<template>
  <v-card v-if="id > 0">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          {{ department.description }} ({{ department.code }})
        </v-list-item-title>
        <v-list-item-subtitle> {{ count }} Mitglieder </v-list-item-subtitle>
      </v-list-item-content>
      <PeopleSort />
    </v-list-item>
    <v-divider />
    <v-tabs v-model="tabs">
      <v-tab :key="1">Liste</v-tab>
      <v-tab :key="2">Porträts</v-tab>
    </v-tabs>
    <v-divider />

    <v-tabs-items v-model="tabs">
      <v-tab-item :key="1">
        <PeopleList :people="department.heads" heading="Vorstand" />

        <PeopleList :people="department.members" heading="Mitglieder" />
      </v-tab-item>
      <v-tab-item :key="2">
        <PeoplePanel :value="department.heads" />
        <v-divider class="my-4" />
        <PeoplePanel :value="department.members" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import { saveChildRoute } from "@/router/helper.js";
import PeopleList from "@/components/PeopleList.vue";
import PeoplePanel from "common/components/PeoplePanel.vue";
import PeopleSort from "@/components/settings/PeopleSort.vue";

export default defineComponent({
  name: "Department",
  components: { PeopleList, PeoplePanel, PeopleSort },
  props: ["id", "search"],
  data() {
    return {
      tabs: 1,
      loading: false,
      department: {},
    };
  },
  computed: {
    count() {
      if (this.department && this.department.heads && this.department.members) {
        return this.department.heads.length + this.department.members.length;
      }
      return 0;
    },
  },
  methods: {
    async getData() {
      if (this.id > 0) {
        this.loading = true;
        this.department = await this.apiGet({
          resource: "person/department",
          id: this.id,
        });
        this.loading = false;
      }
    },
  },
  watch: {
    async id() {
      await this.getData();
    },
  },
  async created() {
    await this.getData();
  },
  beforeRouteUpdate: (to, from, next) => saveChildRoute(to, next),
});
</script>
