<template>
  <v-dialog
    max-width="1024px"
    width="100%"
    :value="true"
    scrollable
    persistent
    @keydown.esc="close"
  >
    <v-card :loading="loading">
      <v-system-bar window :color="titleColor" dark class="mb-2">
        <span v-if="event">
          {{ titleText }}
        </span>
        <v-spacer />
        <v-btn @click="close" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-card-text class="pt-2">
        <v-row>
          <v-col cols="7">
            <v-card>
              <v-list-item>
                <v-text-field
                  label="Beschreibung"
                  v-model="event.description"
                />
              </v-list-item>
              <v-list-item>
                <LookupValueInput
                  label="Abteilung"
                  :items="divisions"
                  v-model="event.division"
                />
              </v-list-item>
            </v-card>
            <v-card class="mt-4">
              <v-list-item>
                <v-row>
                  <v-col>
                    <DateInput
                      class="mr-2"
                      label="von"
                      v-model="event.startDate"
                      clearable
                    />
                  </v-col>
                  <v-col>
                    <TimeInput
                      class="ml-2"
                      label="von"
                      v-model="event.startTime"
                      clearable
                    /> </v-col
                ></v-row>
              </v-list-item>

              <v-list-item>
                <v-row>
                  <v-col>
                    <DateInput label="bis" v-model="event.endDate" clearable />
                  </v-col>
                  <v-col>
                    <TimeInput
                      label="bis"
                      v-model="event.endTime"
                      clearable
                    /> </v-col
                ></v-row>
              </v-list-item>
              <v-list-item><TimeSpan :value="event" long /></v-list-item>
            </v-card>
            <v-card flat class="mt-4">
              <v-list-item>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-model="event.staffOnly"
                      label="nur für LK"
                    ></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      v-model="event.canceled"
                      label="abgesagt"
                    ></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      v-model="event.published"
                      label="veröffentlicht"
                    ></v-checkbox> </v-col
                ></v-row>
              </v-list-item>
            </v-card>
            <v-list-item v-if="!create">
              <v-list-item-subtitle>
                Letzte Änderung: <DateValue :value="event.lastModified" />
              </v-list-item-subtitle>
            </v-list-item>
          </v-col>
          <v-col cols="5">
            <v-card
              v-for="(participant, index) in event.participants"
              :key="index"
              class="mb-4"
            >
              <v-btn
                fab
                absolute
                color="error"
                right
                small
                @click="removeParticipant(index)"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
              <v-list-item>
                <LookupValueInput
                  label="Unterrichtsausfall"
                  :items="eventTypes"
                  v-model="participant.type"
                  @input="$forceUpdate()"
                />
              </v-list-item>
              <v-list-item
                v-if="
                  participant.type &&
                  (participant.type.code == 'text' ||
                    participant.type.code == 'singlePerson')
                "
              >
                <v-text-field
                  label="Beschreibung"
                  v-model="participant.description"
                />
              </v-list-item>
              <v-list-item
                v-if="participant.type && participant.type.code == 'students'"
              >
                <LookupValueInput
                  label="Stufe"
                  :items="grades"
                  v-model="participant.grade"
                />
              </v-list-item>
              <v-list-item
                v-if="
                  participant.type && participant.type.code == 'schoolClass'
                "
              >
                <SchoolClassInput
                  label="Klasse"
                  :items="schoolClasses"
                  v-model="participant.schoolClass"
                />
              </v-list-item>
              <v-list-item
                v-if="participant.type && participant.type.code == 'course'"
              >
                <Course :value="participant.course" />
              </v-list-item>
              <v-list-item
                v-if="participant.type && participant.type.code == 'course'"
              >
                <CourseInput
                  label="Kurs ändern"
                  v-model="participant.course"
                  :startDate="event.startDate"
                  :endDate="event.endDate"
                  :disabled="!event.startDate || !event.endDate"
                />
              </v-list-item>
            </v-card>
            <v-btn outlined depressed block @click="addParticipant"
              >Teilnehmer erfassen</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-alert type="error" v-if="error">{{ error }}</v-alert>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          v-if="!create"
          text
          @click="del"
          :loading="deleting"
          color="danger"
        >
          Löschen</v-btn
        >
        <v-btn text @click="copy" :disabled="create" color="info">
          Kopie erstellen</v-btn
        >
        <v-spacer></v-spacer>

        <v-btn text @click="close">abbrechen</v-btn>
        <v-btn
          text
          @click="save"
          :disabled="!canSave"
          :loading="saving"
          color="primary"
        >
          Speichern</v-btn
        >
      </v-card-actions>
      <v-alert type="error" v-if="error">{{ error }}</v-alert>
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
import Course from "@/components/Course";
import CourseInput from "common/components/CourseInput";
import DateInput from "common/components/DateInput.vue";
import DateValue from "common/components/DateValue.vue";
import TimeInput from "common/components/TimeInput.vue";
import TimeSpan from "@/components/TimeSpan";
import LookupValueInput from "common/components/LookupValueInput.vue";
import SchoolClassInput from "@/components/SchoolClassInput";
export default defineComponent({
  name: "EventsDetails",
  components: {
    Course,
    CourseInput,
    DateValue,
    DateInput,
    TimeInput,
    TimeSpan,
    LookupValueInput,
    SchoolClassInput,
  },
  props: ["id"],
  data() {
    return {
      create: true,
      deleting: false,
      error: null,
      loading: false,
      divisions: [],
      eventTypes: [],
      event: {
        id: 0,
        description: "",
        startDate: null,
        endDate: null,
        participants: [],
      },
      saving: false,
      schoolClasses: [],
    };
  },
  async created() {
    this.loading = true;
    this.divisions = await this.apiList({ resource: "common/division" });
    this.grades = await this.apiList({ resource: "common/grade" });
    this.grades.unshift({ id: 0, code: "alle", description: "alle" });
    this.eventTypes = await this.apiList({ resource: "course/eventtype" });
    this.schoolClasses = await this.apiList({
      resource: "schoolclass/schoolclass",
    });
    if (this.id) {
      this.event = await this.apiGet({ resource: "course/event", id: this.id });
      this.create = false;
    } else {
      this.event.type = this.eventTypes[0];
    }
    this.loading = false;
  },
  computed: {
    canSave() {
      if (!this.event.description) {
        return false;
      }
      if (!this.event.startDate) {
        return false;
      }
      if (!this.event.endDate) {
        return false;
      }
      return true;
    },
    titleColor() {
      return this.create ? "success" : "error";
    },
    titleText() {
      return this.create ? "Termin erstellen" : "Termin ändern";
    },
  },
  methods: {
    addParticipant() {
      this.event.participants.push({});
    },
    removeParticipant(index) {
      this.event.participants.splice(index, 1);
    },
    close() {
      this.$router.back();
    },
    copy() {
      this.event.id = 0;
      this.create = true;
    },
    async del() {
      if (
        await this.$root.confirm({
          message: "Soll dieser Termin wirklich gelöscht werden?",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        this.deleting = true;
        await this.apiDelete({ resource: "course/event", id: this.event.id });
        this.deleting = false;
        this.$root.showSuccess("Der Termin wurde gelöscht.");
        this.$router.push({ name: "Events" });
      }
    },
    async save() {
      await this.apiSave({
        add: this.create,
        resource: "course/event",
        data: this.event,
        onError: (error) => (this.error = error),
        onSuccess: () => {
          this.$root.showSuccess("Der Termin wurde gespeichert.");
          this.$router.push({ name: "Events" });
        },
      });
    },
  },
});
</script>
