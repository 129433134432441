<template>
  <v-autocomplete
    :loading="loading"
    v-bind="$attrs"
    v-model="room"
    ref="roomInput"
    autofocus
    auto-select-first
    menu-props="closeOnContentClick"
    :items="rooms"
    label="Zimmer"
    item-text="description"
    item-value="id"
    single-line
    hide-details=""
    return-object
    clearable
    :search-input.sync="roomSearchInput"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        small
        @click:close="data.parent.selectItem(data.item)"
      >
        {{ data.item.code }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.code }} </v-list-item-title>
        <v-list-item-subtitle
          >{{ data.item.description.replace(data.item.code + " - ", "") }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: ["value"],

  data() {
    return {
      loading: true,
      room: {},
      rooms: [],
      roomSearchInput: null,
    };
  },
  watch: {
    value() {
      this.room = this.value;
    },
    room() {
      this.select();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.rooms = await this.apiList({
        resource: "common/room",
      });
      this.loading = false;
    },
    select() {
      this.$emit("input", this.room);
      this.roomSearchInput = null;
      window.setTimeout(() => this.$refs.roomInput.$refs.input.focus());
    },
  },
  async created() {
    await this.fetchData();
  },
});
</script>
