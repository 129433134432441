<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-text-box-multiple</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Berichte</v-toolbar-title>
      <v-spacer />
      <SearchField v-model="search" hint="Name des Berichts"></SearchField>
    </v-app-bar>
    <v-row>
      <v-col xs="12" sm="6" md="4">
        <v-card>
          <v-system-bar>Favoriten</v-system-bar>
          <v-list v-if="favoriteGroup && favoriteGroup.length > 0">
            <ReportItem
              v-for="(report, index) in filter({ reports: favoriteGroup })"
              v-bind:key="'fav' + index"
              :report="report"
              showFavorite
              :isFavorite="report.isFavorite"
              @toggleFavorite="toggleFavorite"
            >
            </ReportItem>
          </v-list>
          <v-list v-else>
            <v-list-item>
              <v-list-item-subtitle>keine Favoriten</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        xs="12"
        sm="6"
        md="4"
        v-for="(reportGroup, index) in reportGroups"
        v-bind:key="index"
      >
        <v-card>
          <v-system-bar>{{ reportGroup.description }}</v-system-bar>

          <v-list v-if="filter(reportGroup).length > 0">
            <ReportItem
              v-for="(report, index) in filter(reportGroup)"
              v-bind:key="index"
              :report="report"
              showFavorite
              :isFavorite="report.isFavorite"
              @toggleFavorite="toggleFavorite"
            >
            </ReportItem>
          </v-list>
          <v-list v-else>
            <v-list-item>
              <v-list-item-subtitle>keine Übereinstimmung</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { getFavorites, toggleFavorite } from "@/utils/favorites";
import ReportItem from "@/components/ReportItem";
import SearchField from "@/components/SearchField";
export default defineComponent({
  name: "Reports",
  components: { ReportItem, SearchField },
  data() {
    return {
      loading: false,
      reports: [],
      reportGroups: [],
      search: "",
      favoriteGroup: [],
      favoriteKey: "reports",
    };
  },
  async created() {
    this.reports = await this.apiList({
      resource: "app/report",
      query: "type=general",
    });
    const groups = [];
    this.reports.forEach((report) => {
      // create group
      if (report.category) {
        const group = groups.find((i) => i.categoryId === report.category.id);
        if (group) {
          group.reports.push(report);
        } else {
          groups.push({
            categoryId: report.category.id,
            description: report.category.description,
            reports: [report],
          });
        }
      }
    });
    this.reportGroups = groups;
    this.generateFavorites();
  },
  methods: {
    filter(group) {
      if (group && group.reports) {
        return group.reports.filter((item) =>
          item.description.toLowerCase().includes(this.search.toLowerCase())
        );
      }
    },
    generateFavorites() {
      const favorites = getFavorites(this.favoriteKey);
      if (favorites) {
        // add report to Favorites
        this.favoriteGroup = [];
        this.reports.forEach((report) => {
          if (favorites.includes(report.id)) {
            report.isFavorite = true;
          } else {
            report.isFavorite = false;
          }
          if (favorites.includes(report.id)) {
            this.favoriteGroup.push(report);
          }
        });
      }
    },
    toggleFavorite(report) {
      toggleFavorite(this.favoriteKey, report.id);
      this.generateFavorites();
    },
  },
});
</script>
