export function cleanUpSpecialChars(str) {
  return str
    .replace(/[ÀÁÂÃÄÅ]/g, "A")
    .replace(/[àáâãäå]/g, "a")
    .replace(/[ÇĆČ]/g, "C")
    .replace(/[çćč]/g, "c")
    .replace(/[ÈÉÊËĖE]/g, "E")
    .replace(/[èêėëė]/g, "e")
    .replace(/[ÎÏÍĪÌ]/g, "I")
    .replace(/[îïíīì]/g, "i")
    .replace(/[ÑŃ]/g, "N")
    .replace(/[ñń]/g, "n")
    .replace(/[ÖÔÒÓÕØŌ]/g, "O")
    .replace(/[öôòóõøō]/g, "o")
    .replace(/[ß]/g, "ss")
    .replace(/[ŚŠ]/g, "S")
    .replace(/[śš]/g, "s")
    .replace(/[ÜÛÙÚŪ]/g, "U")
    .replace(/[üûùúū]/g, "u")
    .replace(/[Ÿ]/g, "Y")
    .replace(/[ÿ]/g, "y")
    .replace(/[^a-z]/gi, ""); // final clean up
}

export function genderfy(person, female, fluid, male) {
  switch (person.gender.code) {
    case "F":
      return female;
    case "D":
      return fluid;
    case "M":
      return male;
    default:
      return "";
  }
}

export function searchPerson(person, search) {
  if (!search) {
    return false;
  }
  if (!person) {
    return false;
  }
  const s = search.toLowerCase();
  const firstName = person.firstName ? person.firstName.toLowerCase() : "–";
  const lastName = person.lastName ? person.lastName.toLowerCase() : "–";
  return (
    firstName.includes(s) ||
    lastName.includes(s) ||
    (person.schoolClass && person.schoolClass.code.toLowerCase().includes(s)) ||
    (person.code && person.code == s) ||
    (lastName + " " + firstName).includes(s) ||
    (firstName + " " + lastName).includes(s)
  );
}

export function personName(person) {
  if (getPeopleSort() === "firstName") {
    return person.firstName + " " + person.lastName;
  } else {
    return person.lastName + " " + person.firstName;
  }
}

export function personCode(person) {
  if (!person || !person.type) {
    return "";
  }
  switch (person.type.code) {
    case "student":
      return person.schoolClass ? person.schoolClass.code : "–";
    case "employee":
    case "teacher":
      return person.code;
  }
  return "";
}

export function personSubtitle(person) {
  if (!person) return "";
  if (person.description) return person.description;
  let result = "";
  if (person.type && person.gender) {
    switch (person.type.code) {
      case "employee":
        result += genderfy(
          person,
          "Mitarbeiterin ",
          "Mitarbeiter:in ",
          "Mitarbeiter "
        );
        if (person.division) result += person.division.code;
        break;
      case "legalGuardian":
        result += genderfy(person, "Mutter", "Elternteil", "Vater");
        break;
      case "student":
        result += genderfy(person, "Schülerin ", "Schüler:in ", "Schüler ");
        if (person.schoolClass) result += person.schoolClass.code;
        break;
      case "teacher":
        result += genderfy(person, "Lehrerin ", "Lehrperson ", "Lehrer ");
        if (person.division) result += person.division.code;
        break;
    }
  }
  return result;
}

export function getPeopleSort() {
  return localStorage.getItem("peopleSort") || "firstName";
}

export function setPeopleSort(peopleSort) {
  localStorage.setItem("peopleSort", peopleSort);
}

export function sortPeople(people) {
  if (!people) {
    return people;
  }
  return people.slice().sort((a, b) => {
    return comparePeople(a, b);
  });
}

export function compareStudentsBySchoolClass(a, b) {
  let aValue = (a.schoolClass ? a.schoolClass.code : "___") + personName(a);
  let bValue = (b.schoolClass ? b.schoolClass.code : "___") + personName(b);
  return aValue.localeCompare(bValue, "de-CH");
}

export function comparePeople(a, b) {
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }
  let aValue = personName(a);
  let bValue = personName(b);

  return aValue.localeCompare(bValue, "de-CH");
}
