<template>
  <v-text-field
    v-bind="$attrs"
    class="mr-4"
    prefix="CHF"
    inputmode="decimal"
    dense
    v-model="modelValue"
  ></v-text-field>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: { type: Number, default: 0.0 },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  watch: {
    modelValue() {
      const val = Number.parseFloat(this.modelValue);
      this.$emit("input", isNaN(val) ? 0.0 : val);
    },
    value() {
      this.modelValue = this.value;
    },
  },
});
</script>
