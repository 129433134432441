<template>
  <v-card class="mb-2">
    <v-system-bar>TimePicker</v-system-bar>
    <v-card-text>
      <p>Stellt ein Json-Zeit in normalen Format dar.</p>
      <v-row>
        <v-col
          ><TimePicker v-model="time" :color="color ? color.code : null"
        /></v-col>
        <v-col
          ><LookupValueInput label="Farbe" v-model="color" :items="colors"
        /></v-col>
      </v-row>

      <code
        >&lt;TimePicker v-model="time" color="{{ color ? color.code : null }}"
        /&gt;</code
      >
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import TimePicker from "@/components/TimePicker";
import LookupValueInput from "common/components/LookupValueInput.vue";

export default defineComponent({
  name: "DatePickerDemo",
  components: { TimePicker, LookupValueInput },
  data() {
    return {
      time: "",
      color: {},
      colors: [
        { id: 1, code: "primary", description: "Gymer-Blau" },
        { id: 2, code: "info", description: "Unterricht" },
        { id: 3, code: "success", description: "Organisation" },
        { id: 4, code: "error", description: "Menschen" },
        { id: 5, code: "warning", description: "myGymer" },
      ],
    };
  },
});
</script>
