<template>
  <v-dialog
    v-model="dialog"
    width="500"
    scrollable
    :fullscreen="$vuetify.breakpoint.xs"
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="[attrs, $attrs]"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </template>

    <ReservationAddForm
      @updated="$emit('updated')"
      @close="dialog = false"
      :defaults="defaults"
    />
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
import ReservationAddForm from "common/components/ReservationAddForm.vue";

export default defineComponent({
  name: "ReservationAddButton",
  components: { ReservationAddForm },
  props: {
    defaults: {},
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    canEdit() {
      return true;
    },
  },
});
</script>
