<template>
  <div v-if="value && value.length > 0">
    <ReservationItem
      icon="mdi-map-marker-plus"
      v-if="single"
      :value="value[0]"
      @updated="$emit('updated')"
    ></ReservationItem>
    <v-list-group v-else :value="false">
      <template v-slot:activator>
        <ListIcon><v-icon>mdi-map-marker-plus</v-icon></ListIcon>
        <v-list-item-content>
          <v-list-item-title>{{ codes }}</v-list-item-title>
        </v-list-item-content>
      </template>

      <ReservationItem
        v-for="item in value"
        :key="item.id"
        :value="item"
        @updated="$emit('updated')"
      ></ReservationItem>
    </v-list-group>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ReservationItem from "common/components/ReservationItem.vue";

export default defineComponent({
  name: "ReservationItems",
  props: ["value"],
  components: {
    ReservationItem,
  },
  computed: {
    codes() {
      return this.value
        .map((item) => (item.room ? item.room.code : ""))
        .join(", ");
    },
    single() {
      return this.value && this.value.length === 1;
    },
  },
});
</script>
