<template>
  <v-img
    :src="portraitUrl"
    :aspect-ratio="3 / 4"
    :class="$attrs['disabled'] ? 'disabled' : ''"
  />
</template>
<script>
import { defineComponent } from "vue";
import { LRU } from "common/utils/cache";

const PORTRAIT_CACHE = new LRU(100);

export default defineComponent({
  name: "PortraitImage",
  props: { value: Object, large: { type: Boolean, default: false } },
  data() {
    return {
      abortController: null,
      portraitUrl: require("common/assets/portrait-placeholder.png"),
    };
  },
  watch: {
    async value() {
      this.fetchPortraitImage();
    },
  },
  methods: {
    async fetchPortraitImage() {
      this.portraitUrl = require("common/assets/portrait-placeholder.png");
      if (this.value && !this.value.portraitAvailable) {
        return;
      }
      if (this.value && this.value.id) {
        if (!this.large) {
          let cached = PORTRAIT_CACHE.read(this.value.id);
          if (cached) {
            this.portraitUrl = cached;
            return;
          }
          if (this.abortController) {
            this.abortController.abort();
          }
          this.abortController = new AbortController();
          const url = await this.apiBlob({
            path: "person/portrait/" + this.value.id + "?small",
            signal: this.abortController.signal,
          });
          if (url) {
            this.portraitUrl = url;
            PORTRAIT_CACHE.write(this.value.id, url);
          }
          return;
        }
        const url = await this.apiBlob({
          path: "person/portrait/" + this.value.id,
        });
        if (url) {
          this.portraitUrl = url;
        }
      }
    },
  },
  async created() {
    if (this.value && this.value.id) {
      this.fetchPortraitImage();
    }
  },
});
</script>
<style scoped>
.disabled :deep(.v-image__image.v-image__image--cover) {
  opacity: 0.5 !important;
}
</style>
