<template>
  <v-btn x-small color="danger" outlined @click="deleteAbsence()"
    ><v-icon left>mdi-trash-can</v-icon>löschen</v-btn
  >
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: { absence: {} },

  methods: {
    async deleteAbsence() {
      if (
        await this.$root.confirm({
          message: `Soll diese Absenz gelöscht werden?`,
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({
          resource: "absence/absence",
          id: this.absence.id,
        });
        this.$emit("updated");
      }
    },
  },
});
</script>
