<template>
  <v-card>
    <v-system-bar
      ><v-icon>{{ icon }}</v-icon
      >{{ title }}</v-system-bar
    >
    <slot></slot>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      hide-default-footer
      disable-pagination
      sort-by="sequence"
      show-expand
      @click:row="clickRow"
      :expanded.sync="expanded"
      :item-class="() => 'clickable'"
    >
      <template v-slot:item.frequency="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"
              >{{ getJobFrequencyIcon(item.frequency) }}
            </v-icon>
          </template>
          <span>{{ item.frequency.description }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"
              >{{ getJobStatusIcon(item.status) }}
            </v-icon>
          </template>
          <span>{{ item.status.description }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.lastStart="{ item }">
        <DateValue :value="item.lastStartDate" long />,
        <TimeValue :value="item.lastStartTime" seconds></TimeValue>
      </template>
      <template v-slot:item.lastEnd="{ item }">
        <DateValue :value="item.lastEndDate" long />,
        <TimeValue :value="item.lastEndTime" seconds></TimeValue>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-alert type="info" text class="ma-0">
            <v-row align="center">
              <v-col class="grow">
                <strong>{{ item.className }}</strong
                ><br />{{
                  item.description ? item.description : "(keine Beschreibung)"
                }}
              </v-col>
              <v-col class="shrink">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      outlined
                      small
                      @click="runTask(item)"
                      >starten
                    </v-btn>
                  </template>
                  <span>Starten</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-alert>
          <v-alert
            type="error"
            color="danger"
            text
            class="ma-0"
            prominent
            v-if="item.status.code === 'failed'"
          >
            <pre
              style="white-space: pre-wrap; font-size: small"
              v-html="item.lastError"
            ></pre>
          </v-alert>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import TimeValue from "common/components/TimeValue.vue";

export default defineComponent({
  name: "JobsTable",
  components: { DateValue, TimeValue },
  props: ["title", "items", "loading", "icon"],
  data: () => ({
    expanded: [],
    headers: [
      { text: "Reihenfolge", value: "sequence" },
      { text: "Name", value: "name" },
      { text: "Status", value: "status" },
      { text: "zuletzt gestartet", value: "lastStart" },
      { text: "zuletzt beendet", value: "lastEnd" },
      { text: "Dauer [s]", value: "lastDuration" },
      { text: "", value: "data-table-expand" },
    ],
  }),
  methods: {
    clickRow(item, event) {
      if (event.isExpanded) {
        const indexExpanded = this.expanded.findIndex((i) => i === item);
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded.push(item);
      }
    },
    async runTask(task) {
      this.$emit("runTask", task);
    },
    getJobStatusIcon(status) {
      switch (status.code) {
        case "none":
          return "mdi-square-outline";
        case "failed":
          return "mdi-alert";
        case "running":
          return "mdi-progress-clock";
        case "scheduled":
          return "mdi-pause-circle-outline";
        case "succeeded":
          return "mdi-check";
        case "waiting":
          return "mdi-sleep";
        default:
          return "";
      }
    },
  },
});
</script>
