<template>
  <v-card class="mb-2">
    <v-system-bar>Course</v-system-bar>
    <v-card-text>
      <p>Stellt einen Kurs als v-list-item dar</p>
      <v-row>
        <v-col><Course :value="course" /></v-col>
        <v-col>
          <v-slider
            v-if="courses.length > 0"
            v-model="index"
            :max="courses.length - 1"
            :min="0"
          ></v-slider>
          <v-btn @click="course = null">null</v-btn>
        </v-col>
      </v-row>

      <code>&lt;Course :value="course" :to="to" /&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import Course from "@/components/Course";
export default defineComponent({
  name: "CourseDemo",
  components: { Course },
  data() {
    return { index: 0, courses: {}, course: null };
  },
  watch: {
    index() {
      this.course = this.courses[this.index];
    },
  },
  async created() {
    this.courses = await this.apiList({
      resource: "course/course",
      query: "search=IN",
    }).courses;
    this.course = this.courses[this.index];
  },
});
</script>
