<template>
  <v-container fluid>
    <v-toolbar flat class="mb-2">
      <SchoolClassInput
        :deactivated="withoutlocker"
        label="Klasse wählen"
        hide-details
        clearable
        v-model="schoolClass"
        class="mr-2"
      />

      <PersonInput
        group="student"
        label="Schüler:in wählen"
        hide-details
        clearable
        v-model="student"
        class="ml-2"
      />

      <v-btn
        text
        color="primary"
        class="ml-2"
        @click="withoutlocker = !withoutlocker"
        :input-value="withoutlocker"
        >ohne Schäftli</v-btn
      >
      <v-btn
        text
        color="primary"
        class="ml-2"
        @click="withoutclass = !withoutclass"
        :input-value="withoutclass"
        >ohne Klasse</v-btn
      >
    </v-toolbar>

    <v-card>
      <v-data-table
        width="300"
        :loading="loading"
        :headers="headers"
        :items="items"
        :items-per-page="30"
        :must-sort="true"
        sort-by="student"
        :item-class="() => 'clickable'"
        @click:row="(item, row) => showDetails(item.student.id, row.index)"
      >
        <template v-slot:item.student="{ item }">
          <PersonItem small :value="item.student" />
        </template>
        <template v-slot:item.lockers="{ item }">
          <v-chip v-for="locker in item.lockers" :key="`locker${locker.id}`">{{
            locker.code
          }}</v-chip>
          <v-chip v-for="locker in item.current" :key="`locker${locker.id}`">{{
            locker.code
          }}</v-chip>
        </template>
      </v-data-table>
    </v-card>

    <router-view @update="fetchData" @next="next" @prev="prev"></router-view>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import { comparePeople } from "common/utils/people.js";

import PersonItem from "common/components/PersonItem";
import PersonInput from "common/components/PersonInput.vue";
import SchoolClassInput from "@/components/SchoolClassInput";

export default defineComponent({
  name: "Locker",
  components: {
    PersonItem,
    PersonInput,
    SchoolClassInput,
  },
  data: () => ({
    index: 0,
    loading: false,
    items: [],
    schoolClass: {},
    search: "",
    student: {},
    withoutclass: false,
    withoutlocker: false,
    headers: [
      {
        text: "Schüler*in",
        value: "student",
        sort: comparePeople,
      },
      {
        text: "Kommentar",
        value: "comment",
      },
      {
        text: "Schrank",
        value: "lockers",
      },
    ],
  }),
  watch: {
    withoutclass(val) {
      if (val) {
        this.schoolClass = null;
        this.withoutlocker = false;
        this.fetchData();
      }
    },
    withoutlocker(val) {
      if (val) {
        this.schoolClass = null;
        this.withoutclass = false;
        this.fetchData();
      }
    },
    schoolClass(val) {
      if (val) {
        this.withoutlocker = false;
        this.withoutclass = false;
        this.fetchData();
      }
    },
    student() {
      if (this.student) {
        this.$router.push({
          name: "LockerStudentDetails",
          params: { id: this.student.id },
        });
      }
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = [];
      if (this.withoutlocker) {
        this.items = await this.apiList({
          resource: "person/locker",
          query: "noLocker",
        });
      } else if (this.withoutclass) {
        this.items = await this.apiList({
          resource: "person/locker",
          query: "noClass",
        });
      } else if (this.schoolClass) {
        window.localStorage.setItem(
          "lockerSchoolClass",
          JSON.stringify(this.schoolClass)
        );
        this.items = await this.apiGet({
          resource: "schoolclass/locker",
          id: this.schoolClass.id,
        });
      }
      this.loading = false;
    },
    goto(index) {
      const id = this.items[index] ? this.items[index].student.id : false;
      if (id) {
        this.showDetails(id, index);
      }
    },
    prev() {
      this.goto(this.index - 1);
    },
    next() {
      this.goto(this.index + 1);
    },
    showDetails(id, index) {
      this.index = index;
      this.$router.push({ name: "LockerStudentDetails", params: { id: id } });
    },
  },
  created() {
    if (window.localStorage.getItem("lockerSchoolClass")) {
      this.schoolClass = JSON.parse(
        window.localStorage.getItem("lockerSchoolClass")
      );
    }
  },
});
</script>
