<template>
  <v-btn-toggle tile group v-model="emailClient" mandatory>
    <v-btn small value="mailto"> Mail-App </v-btn>
    <v-btn small value="outlook"> Outlook Web </v-btn>
  </v-btn-toggle>
</template>
<script>
import { defineComponent } from "vue";
import { getEmailClient, setEmailClient } from "@/services/settings.service";
import { updateEmailClient } from "@/services/bus.service";
export default defineComponent({
  name: "EmailClient",
  data() {
    return {
      emailClient: "",
    };
  },
  watch: {
    emailClient() {
      setEmailClient(this.emailClient);
      updateEmailClient(this.emailClient);
    },
  },
  created() {
    this.emailClient = getEmailClient();
  },
});
</script>
