<template>
  <v-list-item class="px-4">
    <v-list-item-content>
      <v-list-item-title>{{ title }}</v-list-item-title>
      <v-list-item-subtitle>
        {{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import { defineComponent } from "vue";
import { formatDatespan, formatTimespan } from "common/utils/date.js";

export default defineComponent({
  name: "TimeSpan",
  props: {
    value: null,
    long: { type: Boolean, default: false },
  },
  computed: {
    title() {
      return this.value
        ? formatDatespan(this.value.startDate, this.value.endDate, this.long)
        : "";
    },
    subtitle() {
      return this.value
        ? formatTimespan(
            this.value.startDate,
            this.value.startTime,
            this.value.endDate,
            this.value.endTime
          )
        : "";
    },
  },
});
</script>
