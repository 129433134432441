<template>
  <v-card class="mb-2">
    <v-system-bar>PersonName</v-system-bar>
    <v-card-text>
      <p>
        Stellt den Namen einer Person gemäss den Einstellungen zu
        Namenssortierung dar.
      </p>
      <v-row>
        <v-col
          ><PersonName
            v-if="person"
            :value="person"
            :address="address"
            :title="title"
            :code="code"
            :ext="ext"
        /></v-col>
        <v-col
          ><v-text-field
            type="number"
            label="personId"
            outlined
            v-model="id"
          ></v-text-field>
          <v-checkbox
            v-model="address"
            :label="`address: ${address.toString()}`"
            persistent-hint
            hint="Anrede (Herr oder Frau) wird angezeigt."
          ></v-checkbox>
          <v-checkbox
            v-model="title"
            :label="`title: ${title.toString()}`"
            persistent-hint
            hint="Akademischer Titel (z.B. Dr.) wird angezeigt."
          ></v-checkbox>
          <v-checkbox
            v-model="code"
            :label="`code: ${code.toString()}`"
            persistent-hint
            hint="Kürzel anzeigen"
          ></v-checkbox>
          <v-checkbox
            v-model="ext"
            :label="`ext: ${ext.toString()}`"
            persistent-hint
            hint="Extension (Kürzel/Klasse) anzeigen"
          ></v-checkbox>
        </v-col>
      </v-row>

      <code
        >&lt;PersonName :value="person" :address="address" :title="title"
        :code="code" :ext="ext" /&gt;</code
      >
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import PersonName from "common/components/PersonName";
export default defineComponent({
  name: "PersonNameDemo",
  components: { PersonName },
  data() {
    return {
      id: 34,
      person: null,
      title: false,
      address: false,
      code: false,
      ext: true,
    };
  },
  methods: {
    async getData() {
      this.person = await this.apiGet({
        resource: "person/person",
        id: this.id,
      });
    },
  },
  watch: {
    id() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
});
</script>
