<template>
  <v-container>
    <v-app-bar dark app color="primary" clipped-right>
      <v-app-bar-nav-icon>
        <v-icon>mdi-printer</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Druckkonti</v-toolbar-title>

      <v-spacer />
      <PersonInput
        group="student"
        @input="
          (ev) =>
            ev ? (selectSchoolClass(ev.schoolClass), showDetails(ev.id)) : ''
        "
        label="Schüler:in wählen"
        hide-details
        single-line
        clearable
      />
    </v-app-bar>

    <v-alert
      text
      v-if="lastImport && lastImport.state === 'current'"
      type="info"
    >
      Die Druck- und Kopierbewegungen werden nur einmal pro Tag aktualisiert.<br />
      Zuletzt geschah dies am <DateValue :value="lastImport.date" /> um
      {{ lastImport.time }}.
    </v-alert>
    <v-alert
      text
      v-if="lastImport && lastImport.state === 'old'"
      type="warning"
    >
      Die Druck- und Kopierbewegungen sind schon länger nicht mehr aktualisiert
      worden, zuletzt am <DateValue :value="lastImport.date" /> um
      {{ lastImport.time }}.
    </v-alert>
    <v-alert
      text
      v-if="lastImport && lastImport.state === 'running'"
      type="warning"
    >
      Die Druck- und Kopierbewegungen werden gerade aktualisiert.
    </v-alert>
    <v-row>
      <v-col>
        <v-card v-if="schoolClass">
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">
                        {{ schoolClass.code }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          schoolClass.studentCount
                            ? schoolClass.studentCount.total
                            : "–"
                        }}
                        Schüler:innen
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <PersonItem
                    :value="schoolClass.teacher"
                    clickable
                    description="Klassenlehrperson"
                  />
                  <PersonItem
                    v-if="schoolClass.substituteTeacher"
                    :value="schoolClass.substituteTeacher"
                    clickable
                    description="Stv. Klassenlehrperson"
                  />
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-toolbar flat>
            <v-dialog v-model="newDialog" width="500" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="selectedStudents.length < 1"
                  depressed
                  small
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon left>mdi-plus</v-icon> buchen</v-btn
                >
              </template>

              <v-card>
                <v-system-bar window>Buchung hinzufügen </v-system-bar>
                <v-card-text class="mt-4 pb-2">
                  <v-chip-group show-arrows column>
                    <PersonItem
                      small
                      hideExt
                      v-for="student in selectedStudents"
                      :value="student"
                      :key="'chip' + student.id"
                    />
                  </v-chip-group>
                  <v-text-field
                    label="Beschreibung"
                    v-model="newItem.description"
                  />
                  <v-text-field
                    type="number"
                    label="Betrag"
                    v-model="newItem.amount"
                  />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn text @click="newDialog = false">abbrechen</v-btn
                  ><v-btn
                    text
                    color="primary"
                    :disabled="!saveable"
                    :loading="saving"
                    @click="createTransactions"
                    >speichern</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer />
            <v-dialog v-model="balanceDialog" width="500" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :loading="balancing"
                  :disabled="selectedStudents.length < 1"
                  depressed
                  small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  >saldieren</v-btn
                >
              </template>

              <v-card>
                <v-system-bar window>Schlussbuchung</v-system-bar>
                <v-card-text class="mt-4 pb-2">
                  <v-chip-group show-arrows column>
                    <PersonItem
                      small
                      hideExt
                      v-for="student in selectedStudents"
                      :value="student"
                      :key="'chip' + student.id"
                    />
                  </v-chip-group>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn text @click="balanceDialog = false">abbrechen</v-btn
                  ><v-btn
                    text
                    color="primary"
                    :loading="balancing"
                    @click="createBalanceTransactions"
                    >saldieren</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-divider />
          <v-data-table
            v-model="selectedItems"
            show-select
            item-key="student.id"
            :loading="loadingOverview"
            :headers="headers"
            :items="items"
            disable-pagination
            hide-default-footer
            :item-class="() => 'clickable'"
            @click:row="(item) => showDetails(item.student.id)"
          >
            <template v-slot:item.student="{ item }">
              <PersonItem hideExt small :value="item.student" />
            </template>
            <template v-slot:item.balance="{ item }">
              <CurrencyValue :value="item.balance" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col class="col-auto">
        <v-navigation-drawer app clipped permanent right>
          <v-list nav dense>
            <v-list-item>
              <SearchField label="Klassensuche" v-model="search"></SearchField
            ></v-list-item>
            <SchoolClass
              v-for="item in filteredSchoolClasses"
              :key="item.id"
              nodetails
              nolink
              clickable
              @click="selectSchoolClass(item)"
              :value="item"
            />
            <v-list-item v-if="!loading && filteredSchoolClasses.length == 0"
              >keine Suchtreffer</v-list-item
            >
            <v-skeleton-loader
              v-if="loading"
              type="list-item-two-line"
            ></v-skeleton-loader>
          </v-list>
        </v-navigation-drawer>
      </v-col>
    </v-row>
    <router-view @dataUpdated="fetchData"></router-view>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import CurrencyValue from "@/components/CurrencyValue";
import PersonItem from "common/components/PersonItem";
import PersonInput from "common/components/PersonInput.vue";
import SchoolClass from "@/components/SchoolClass";
import SearchField from "@/components/SearchField";

import { comparePeople } from "common/utils/people.js";

export default defineComponent({
  components: {
    DateValue,
    CurrencyValue,
    PersonItem,
    PersonInput,
    SchoolClass,
    SearchField,
  },
  data() {
    return {
      balancing: false,
      balanceDialog: false,
      loading: false,
      loadingOverview: false,
      lastImport: null,
      newDialog: false,
      newItem: {
        description: "",
        amount: "",
      },
      saving: false,
      selectedItems: [],
      schoolClass: {},
      schoolClasses: [],
      items: [],
      search: "",
      headers: [
        { text: "Schüler:in", value: "student", sort: comparePeople },
        { text: "Kartennummer", value: "legicUid" },
        { text: "Anzahl", value: "cardCount" },
        { text: "Saldo", value: "balance", align: "right" },
      ],
    };
  },
  computed: {
    saveable() {
      return (
        this.newItem.amount &&
        this.newItem.description &&
        this.selectedStudents.length > 0
      );
    },
    selectedStudents() {
      return this.selectedItems.map((el) => el.student);
    },
    filteredSchoolClasses() {
      if (!this.search) {
        return this.schoolClasses;
      }
      return this.schoolClasses.filter(
        (item) =>
          item.code.toLowerCase().includes(this.search.toLowerCase()) ||
          (item.majorSubjects &&
            item.majorSubjects.includes(this.search.toUpperCase())) ||
          (item.classTeacher &&
            item.classTeacher.code === this.search.toLowerCase()) ||
          (item.assistantClassTeachers &&
            item.assistantClassTeachers.some(
              (teacher) => teacher.code === this.search.toLowerCase()
            ))
      );
    },
  },
  watch: {
    newDialog() {
      this.newItem = {
        description: "",
        amount: "",
      };
    },
    async schoolClass(oldValue, newValue) {
      if (oldValue != newValue) {
        this.fetchData();
      }
    },
  },
  methods: {
    async fetchData() {
      this.loadingOverview = true;
      this.items = await this.apiList({
        resource: "finance/printoverview",
        query: `schoolClass=${this.schoolClass.id}`,
      });

      this.lastImport = await this.apiList({
        resource: "finance/printimportstate",
      });
      localStorage.setItem("financeSchoolClass", this.schoolClass.id);

      if (this.$route.query.schoolClass != this.schoolClass.id) {
        this.$router.push({
          query: { schoolClass: this.schoolClass.id },
        });
      }
      this.loadingOverview = false;
    },
    async createBalanceTransactions() {
      if (
        await this.$root.confirm({
          message: `Soll saldiert werden?`,
          color: "info",
          icon: "mdi-alert",
        })
      ) {
        this.balancing = true;
        const selectedIds = this.selectedStudents.map((el) => el.id);
        for await (const studentId of selectedIds) {
          const item = this.selectedItems.find(
            (el) => el.student.id == studentId
          );
          if (item.balance == 0) {
            const index = this.selectedItems.findIndex(
              (el) => el.student.id == studentId
            );
            this.selectedItems.splice(index, 1);
          } else {
            const data = {
              amount: -item.balance,
              description: "Schlussbuchung",
              person: studentId,
            };
            await this.apiPost({
              resource: "finance/printtransaction",
              data: data,
              onError: (message) => {
                this.$root.showError(`Fehler: <strong>${message}</strong>`);
              },
              onSuccess: () => {
                const index = this.selectedItems.findIndex(
                  (el) => el.student.id == studentId
                );
                this.selectedItems.splice(index, 1);
              },
            });
          }
        }
        setTimeout(() => {
          this.fetchData();
          this.balancing = false;
          if (this.selectedItems.length == 0) {
            this.balanceDialog = false;
          }
        }, 1200);
      }
    },
    async createTransactions() {
      this.saving = true;
      const selectedIds = this.selectedStudents.map((el) => el.id);
      for await (const studentId of selectedIds) {
        const data = {
          ...this.newItem,
          person: studentId,
        };
        await this.apiPost({
          resource: "finance/printtransaction",
          data: data,
          onError: (message) => {
            this.$root.showError(`Fehler: <strong>${message}</strong>`);
          },
          onSuccess: () => {
            const index = this.selectedItems.findIndex(
              (el) => el.student.id == studentId
            );
            this.selectedItems.splice(index, 1);
          },
        });
      }
      setTimeout(() => {
        this.fetchData();
        this.saving = false;
        if (this.selectedItems.length == 0) {
          this.newDialog = false;
        }
      }, 1200);
    },
    selectSchoolClass(item) {
      this.schoolClass = item;
    },
    showDetails(id) {
      this.$router.push({
        name: "FinancePrintsDetail",
        params: { id: id },
        query: this.$route.query,
      });
    },
  },
  async created() {
    this.loading = true;
    this.schoolClasses = await this.apiList({
      resource: "schoolclass/schoolclass",
    });
    if (this.$route.query.schoolClass) {
      this.schoolClass = this.schoolClasses.find(
        (item) => item.id === parseInt(this.$route.query.schoolClass)
      );
    } else if (localStorage.getItem("financeSchoolClass")) {
      this.schoolClass = this.schoolClasses.find(
        (item) =>
          item.id === parseInt(localStorage.getItem("financeSchoolClass"))
      );
    }
    this.loading = false;
  },
});
</script>
