<template>
  <v-card width="200">
    <v-system-bar color="success">
      Absenzenabschluss <v-spacer />{{ this.items.length }}
    </v-system-bar>
    <v-list>
      <SchoolClass
        small
        v-for="item in items"
        :key="item.id"
        :value="item"
        clickable
      />
    </v-list>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import SchoolClass from "@/components/SchoolClass";

export default defineComponent({
  name: "AbsenceBalance",
  props: ["items"],
  components: { SchoolClass },
});
</script>
