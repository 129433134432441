<template>
  <v-dialog
    v-model="dialog"
    width="500"
    scrollable
    :fullscreen="$vuetify.breakpoint.xs"
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="[attrs, $attrs]"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </template>

    <NoteForm
      :id="0"
      @updated="$emit('updated')"
      @close="dialog = false"
      :defaults="defaults"
    />
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
import { noteColor } from "common/utils/icons.js";

import NoteForm from "common/components/NoteForm.vue";

export default defineComponent({
  name: "NoteAddButton",
  components: { NoteForm },
  props: {
    defaults: {},
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    canEdit() {
      return true;
    },
    color() {
      return noteColor(this.value);
    },
  },
});
</script>
