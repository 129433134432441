<template>
  <span>
    <slot v-if="hasSlot"></slot>

    <v-btn
      :disabled="disabled"
      :href="link"
      :target="mailto ? '' : '_blank'"
      color="primary"
      icon
    >
      <v-icon small v-if="mailto">mdi-email-outline</v-icon>
      <v-icon small v-else>mdi-email</v-icon>
    </v-btn>
    <CopyButton :value="dl.join(', ')" />
  </span>
</template>

<script>
import { defineComponent } from "vue";
import { bus } from "@/services/bus.service";
import { getEmailClient } from "@/services/settings.service";
import CopyButton from "@/components/CopyButton";
export default defineComponent({
  name: "Mailto",
  components: { CopyButton },
  props: {
    dl: { type: Array },
    bcc: { type: Boolean, default: false },
  },
  data() {
    return {
      emailClient: "mailto",
      link: "",
    };
  },
  computed: {
    disabled() {
      // Outlook Web doesn't support bcc in links.
      return !this.mailto && this.bcc;
    },
    mailto() {
      return this.emailClient === "mailto";
    },
    hasSlot() {
      return this.$slots != null && this.$slots.default != null;
    },
  },
  methods: {
    createLink() {
      if (this.mailto) {
        if (this.bcc) {
          this.link = "mailto:?bcc=" + this.dl.join("&bcc=");
        } else {
          this.link = "mailto:" + this.dl.join(",");
        }
      } else {
        this.link =
          "https://outlook.office365.com/owa/?rru=compose&to=" +
          this.dl.join("&to=");
      }
    },
  },
  watch: {
    dl() {
      this.createLink();
    },
  },
  async created() {
    this.emailClient = getEmailClient();
    bus.$on("updateEmailClient", (client) => {
      this.emailClient = client;
      this.createLink();
    });
    this.createLink();
  },
});
</script>
