<template>
  <v-bottom-sheet v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-on="on" v-bind="attrs" v-if="value">
        <ListIcon
          ><v-icon>{{ icon }}</v-icon></ListIcon
        >
        <v-list-item-content>
          <v-list-item-title>
            {{ value.room.description }}
          </v-list-item-title>
          <v-list-item-subtitle
            >{{ value.room.site.description
            }}<span v-id="value.description">
              ({{ value.description }})</span
            ></v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-action v-if="canDelete">
          <v-btn icon @click.stop="deleteReservation()"
            ><v-icon>mdi-trash-can</v-icon></v-btn
          >
        </v-list-item-action>
        <v-list-item-action-text v-else>
          {{ value.comment }} ({{
            value.reservedFor.code
          }})</v-list-item-action-text
        >
      </v-list-item>
    </template>
    <ReservationSheet
      :value="value"
      @close="dialog = false"
      @updated="$emit('updated')"
    />
  </v-bottom-sheet>
</template>
<script>
import { defineComponent } from "vue";
import ReservationSheet from "common/components/ReservationSheet.vue";

export default defineComponent({
  name: "ReservationItem",
  components: { ReservationSheet },
  props: {
    icon: null,
    value: null,
  },

  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    canDelete() {
      return this.value.reservedFor.id == this.$_profilePerson.id;
    },
  },
  methods: {
    async deleteReservation() {
      if (
        await this.$root.confirm({
          message: `Soll diese Reservation gelöscht werden?`,
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({
          resource: "register/reservation",
          id: this.value.id,
        });
        this.$emit("updated");
      }
    },
  },
});
</script>
