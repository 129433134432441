<template>
  <v-expand-transition>
    <v-alert
      colored-border
      border="left"
      :color="color"
      :icon="icon"
      dismissible
      elevation="5"
      ><div v-html="messageShort"></div>
    </v-alert>
  </v-expand-transition>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: ["message", "icon", "color"],
  computed: {
    dark() {
      return this.options.color == "primary";
    },
    messageShort() {
      const maxLenght = 500;
      if (this.message.length > maxLenght) {
        return this.message.substring(0, maxLenght) + "…";
      }
      return this.message;
    },
  },
  methods: {},
});
</script>
