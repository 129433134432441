<template>
  <v-dialog v-model="dialog" scrollable max-width="1000">
    <v-card>
      <v-system-bar
        >myGymer-Anmeldungen<v-spacer></v-spacer
        ><v-btn @click="dialog = false" icon
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      ><v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" md="4">
            <v-card class="mb-4" flat>
              <v-card-text class="title pt-8 text-center">
                <PersonName :value="data.student" />
              </v-card-text>
              <v-card-text class="display text-center">
                <v-avatar>
                  <PortraitImage :value="data.student" />
                </v-avatar>
                <br />
                {{ student.ext }}</v-card-text
              >
            </v-card>
            <template v-if="data.enabled">
              <div v-if="data.hasConfirmed">
                <v-alert
                  type="success"
                  text
                  :prominent="!$vuetify.breakpoint.xs"
                >
                  Wahl ist bestätigt!
                </v-alert>
                <v-btn color="error" outlined block @click="confirm(false)"
                  >zurücknehmen</v-btn
                >
              </div>
              <div v-else>
                <v-alert type="error" text :prominent="!$vuetify.breakpoint.xs"
                  >Wahl noch nicht bestätigt!
                </v-alert>
                <v-btn color="success" block outlined @click="confirm(true)"
                  >bestätigen</v-btn
                >
              </div>
            </template>
            <SciencePlus v-if="data.student" :id="data.student.id" />
          </v-col>
          <v-col>
            <v-card flat max-width="500">
              <v-card class="mb-4">
                <v-system-bar>dieses Semester besucht</v-system-bar>
                <v-card-text>
                  <v-chip
                    class="mr-1 mb-1"
                    v-for="item in now"
                    :key="'now' + item.id"
                  >
                    {{ item.description }}
                  </v-chip>
                  <template v-if="now.length == 0"> – </template>
                </v-card-text>
              </v-card>
              <v-card class="mb-4" v-if="data.hasConfirmed">
                <v-system-bar>nächstes Semester gewählt</v-system-bar>
                <v-card-text>
                  <v-chip
                    class="mr-1 mb-1"
                    v-for="item in chosen"
                    :key="'chosen' + item.id"
                  >
                    {{ item.description }}
                  </v-chip>
                  <template v-if="chosen.length == 0"> – </template>
                </v-card-text>
              </v-card>
              <v-card class="mb-4" v-else>
                <v-system-bar>nächstes Semester gewählt</v-system-bar>
                <v-list>
                  <ChoiceItem
                    v-for="item in chosen"
                    :key="'chosen' + item.id"
                    :item="item"
                    :student="student"
                    @updated="fetchData"
                  >
                  </ChoiceItem>
                </v-list>
              </v-card>
              <v-card class="mb-4" v-if="available.length > 0">
                <v-system-bar>zur Wahl stehen</v-system-bar>
                <v-list>
                  <ChoiceItem
                    v-for="item in available"
                    :key="'available' + item.id"
                    :item="item"
                    :student="student"
                    @updated="fetchData"
                    :disabled="disabled(item)"
                  >
                  </ChoiceItem>
                </v-list>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions
        ><v-spacer /><v-btn @click="dialog = false" text
          >schliessen</v-btn
        ></v-card-actions
      ></v-card
    ></v-dialog
  >
</template>

<script>
import { defineComponent } from "vue";
import ChoiceItem from "./ChoiceItem.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import SciencePlus from "./SciencePlus.vue";
export default defineComponent({
  components: {
    ChoiceItem,
    PersonName,
    PortraitImage,
    SciencePlus,
  },
  props: { student: { type: Number, default: 0 } },
  data() {
    return {
      dialog: true,
      loading: false,
      saving: false,
      search: "",
      data: { optionalSubjects: [] },
    };
  },
  computed: {
    forMe() {
      return this.student == 0;
    },
    chosenSlotIds() {
      return [
        ...new Set(
          this.data.optionalSubjects
            .filter((el) => el.hasChosenNext && el.optionalSlot)
            .map((el) => el.optionalSlot.id)
        ),
      ];
    },
    available() {
      return this.data.optionalSubjects.filter(
        (el) => el.canChoose && !el.hasChosenNext
      );
    },
    chosen() {
      return this.data.optionalSubjects.filter((el) => el.hasChosenNext);
    },

    now() {
      return this.data.optionalSubjects.filter((el) => el.hasChosenNow);
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$router.push({
          name: "OptionalChoice",
        });
      }
    },
  },
  methods: {
    disabled(item) {
      return (
        item.optionalSlot &&
        this.chosenSlotIds.find((x) => x == item.optionalSlot.id) != null
      );
    },
    async confirm(value) {
      this.saving = true;
      await this.apiPost({
        resource: "optional/choice",
        data: { confirm: value, student: this.student },
      });
      this.saving = false;
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;

      if (this.student) {
        this.data = await this.apiList({
          resource: "optional/choice",
          query: "student=" + this.student,
        });
      }

      this.loading = false;
    },
    async fetchAll() {
      this.loading = true;
      this.settings = await this.apiList({ resource: "optional/settings" });
      await this.fetchData();
      this.loading = false;
    },
    async fetchItemData(item) {
      const update = await this.apiList({
        resource: "optional/choice",
        query: "student=" + this.student,
      });
      const index = update.optionalSubjects.findIndex(
        (el) => el.id === item.id
      );
      this.data.optionalSubjects.splice(
        index,
        1,
        update.optionalSubjects[index]
      );
    },
    async toggle(item) {
      this.$set(item, "loading", true);
      const data = {
        optionalSubject: item.id,
        choose: !item.hasChosenNext,
        student: this.student,
      };
      await this.apiPost({ resource: "optional/choice", data: data });
      this.fetchItemData(item);
    },
  },

  async created() {
    this.fetchAll();
  },
});
</script>
