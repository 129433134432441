<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-btn icon>
        <v-icon>mdi-calendar</v-icon>
      </v-btn>

      <v-toolbar-title>Terminliste </v-toolbar-title>

      <v-spacer />

      <v-text-field
        class="mr-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        clearable
        single-line
        hide-details
        id="search"
      ></v-text-field>

      <template v-slot:extension>
        <DivisionTabs
          align-with-title
          all
          v-model="division"
          style="width: auto; margin-right: 2rem"
        />
        <TermTabs v-model="term" />
        <v-btn
          @click="showDetails(0)"
          absolute
          bottom
          right
          fab
          color="success"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-app-bar>

    <v-toolbar flat class="mb-4">
      <v-btn-toggle dense v-model="filter">
        <v-btn text v-for="f in filters" :key="f.value" :value="f.value">
          <v-icon v-if="f.icon" left>{{ f.icon }}</v-icon
          >{{ f.label }}
        </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-tooltip top v-if="filter == 1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            fab
            small
            depressed
            @click="publishAll"
            color="error"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-eye-check</v-icon>
          </v-btn>
        </template>
        alle unveröffentlichten Termine veröffentlichen
      </v-tooltip>
      <ReportButton
        v-if="filter != 1"
        outlined
        text
        resource="report/events"
        color="info"
        :parameters="{
          divisions: !division || division.id == 0 ? [1, 2, 3] : [division.id],
          term: term ? term.id : 0,
          changedOnly: false,
          futureOnly: false,
        }"
        >PDF</ReportButton
      >
    </v-toolbar>

    <v-card class="mt-2">
      <v-data-table
        :loading="loading"
        :headers="headers"
        hide-default-footer
        :items="itemsFiltered"
        :items-per-page="-1"
        :item-class="() => 'clickable'"
        :custom-sort="customSort"
        :must-sort="true"
        sort-by="dateTime"
        @click:row="(item) => showDetails(item.id)"
      >
        <template v-slot:item.dateTime="{ item }">
          <TimeSpan :value="item" long />
        </template>
        <template v-slot:item.description="{ item }">
          <v-list-item :three-line="item.type && item.type.id > 1">
            <v-list-item-content>
              <v-list-item-title
                ><strong
                  :style="item.canceled ? 'text-decoration:line-through' : ''"
                  >{{ item.description }}</strong
                ></v-list-item-title
              >
              <v-list-item-subtitle>
                <strong v-if="item.division">{{ item.division.code }}: </strong>
                {{ item.participantsText }}
                <span v-if="item.cancelText" class="red--text">{{
                  item.cancelText
                }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item.icons="{ item }">
          <v-tooltip v-if="item.published" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense v-bind="attrs" v-on="on"> mdi-eye</v-icon>
            </template>
            <span>veröffentlicht</span>
          </v-tooltip>
          <v-tooltip v-else top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense v-bind="attrs" v-on="on"> mdi-eye-off</v-icon>
            </template>
            <span>nicht veröffentlicht</span>
          </v-tooltip>
          <v-tooltip v-if="item.staffOnly" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dense v-bind="attrs" v-on="on"> mdi-school</v-icon>
            </template>
            <span>nur für LK</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import DivisionTabs from "@/components/DivisionTabs";
import ReportButton from "@/components/ReportButton";
import TermTabs from "@/components/TermTabs";
import TimeSpan from "@/components/TimeSpan";
import { today } from "common/utils/date.js";

export default defineComponent({
  name: "Events",
  components: {
    DivisionTabs,
    ReportButton,
    TermTabs,
    TimeSpan,
  },
  data: () => ({
    loading: false,
    search: "",
    term: null,
    //division: { id: 0, code: 'Alle' },
    division: {},
    filter: 0,
    filters: [
      {
        label: "kein Filter",
        value: 0,
      },
      {
        icon: "mdi-eye-off",
        label: "unveröffentlichte",
        value: 1,
      },
      {
        icon: "mdi-school",
        label: "Termine LK",
        value: 2,
      },
      {
        icon: "mdi-clock",
        label: "zukünftige",
        value: 3,
      },
    ],
    items: [],
    headers: [
      { text: "", value: "icons", sortable: false },
      {
        text: "Datum/Zeit",
        value: "dateTime",
        filterable: false,
      },

      { text: "Beschreibung", value: "description", sortable: false },
    ],
  }),
  watch: {
    term() {
      this.getData();
    },
  },
  computed: {
    today() {
      return today();
    },
    itemsFiltered() {
      return this.items.filter(
        (item) =>
          (!item.division ||
            this.division.id === 0 ||
            this.division.id === item.division.id) &&
          (!this.filter ||
            (this.filter === 1 && !item.published) ||
            (this.filter === 2 && item.staffOnly) ||
            (this.filter === 3 &&
              (item.startDate >= this.today || item.endDate >= this.today) &&
              (!this.search ||
                item.cancelText
                  .toLowerCase()
                  .includes(this.search.toLowerCase()) ||
                item.description
                  .toLowerCase()
                  .includes(this.search.toLowerCase())))) &&
          (!this.search ||
            (item.description &&
              item.description
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.cancelText &&
              item.cancelText
                .toLowerCase()
                .includes(this.search.toLowerCase())))
      );
    },
  },
  methods: {
    changed() {
      const oldQueryString = this.queryString;
      this.queryString = "groupType=" + this.active.id;
      if (this.queryString !== oldQueryString) {
        this.getData();
        localStorage.setItem("groupType", this.queryString);
      }
    },
    async publishAll() {
      if (
        await this.$root.confirm({
          message: "Sollen alle Termine veröffentlicht werden?",
          color: "info",
          icon: "mdi-publish",
        })
      ) {
        const itemsToBePublished = this.items.filter((item) => !item.published);
        for (const item of itemsToBePublished) {
          await this.apiPatch({
            resource: "course/event",
            id: item.id,
            key: "published",
            value: true,
          });
          this.$root.showSuccess(
            "Termin " + item.description + " veröffentlicht"
          );
        }
        this.getData();
      }
    },
    showDetails(id) {
      this.$router.push({
        name: "EventsDetails",
        params: { id: id },
      });
    },
    async getData() {
      if (this.term) {
        this.loading = true;
        this.items = await this.apiList({
          resource: "course/event",
          query: `startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
        });
        this.loading = false;
      }
    },
    customSort(items, sortBy, sortDesc) {
      if (sortBy[0] === "dateTime") {
        items.sort((a, b) => {
          if (!sortDesc[0]) {
            return a.startDate + a.startTime < b.startDate + b.startTime
              ? -1
              : a.startDate + a.startTime > b.startDate + b.startTime
              ? 1
              : 0;
          } else {
            return a.startDate + a.startTime < b.startDate + b.startTime
              ? 1
              : a.startDate + a.startTime > b.startDate + b.startTime
              ? -1
              : 0;
          }
        });
      }
      return items;
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "Events") {
      this.getData();
    }
    next();
  },
});
</script>
