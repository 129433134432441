<template>
  <v-data-table
    disable-pagination
    hide-default-footer
    :headers="headers"
    :items="itemsSorted"
    item-key="id"
    disable-sort
    disable-filtering
  >
    <template v-slot:item.student="{ item }">
      <PersonItem :value="item.student" small />
    </template>
    <template v-slot:item.date="{ item }">
      <DateValue :value="item.date" long />
    </template>
    <template v-slot:item.actions="{ item }">
      <AbsenceDeleteButton @updated="$emit('updated')" :absence="item" />
    </template>
  </v-data-table>
</template>
<script>
import { defineComponent } from "vue";
import AbsenceDeleteButton from "./AbsenceDeleteButton.vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";

export default defineComponent({
  components: { AbsenceDeleteButton, DateValue, PersonItem },
  props: { items: [], hideStudent: { type: Boolean, default: false } },
  data() {
    return {
      headers: this.hideStudent
        ? [
            {
              text: "Tag",
              value: "date",
            },
            {
              text: "Lektion",
              value: "startTime",
            },
            {
              text: "Beschreibung",
              value: "description",
            },
            {
              text: "Status",
              value: "status.description",
            },
            {
              text: "LK",
              value: "teacher.code",
            },
            {
              text: "",
              value: "actions",
              align: "right",
            },
          ]
        : [
            {
              text: "",
              value: "student",
            },
            {
              text: "Tag",
              value: "date",
            },
            {
              text: "Lektion",
              value: "startTime",
            },
            {
              text: "Beschreibung",
              value: "description",
            },
            {
              text: "Status",
              value: "status.description",
            },
            {
              text: "LK",
              value: "teacher.code",
            },
            {
              text: "",
              value: "actions",
              align: "right",
            },
          ],
    };
  },
  computed: {
    itemsSorted() {
      return [...this.items].sort((a, b) => {
        const aValue = a.date + a.startTime;
        const bValue = b.date + b.startTime;
        return aValue.localeCompare(bValue);
      });
    },
  },
});
</script>
