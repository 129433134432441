<template>
  <v-autocomplete
    :value="value"
    :disabled="!items || $attrs.disabled"
    :items="items"
    :label="label"
    item-text="startTime"
    item-value="id"
    @change="select"
    return-object
    v-bind="$attrs"
  >
    <template v-slot:selection="data">
      <span v-if="onlyStart"> von&nbsp;</span>
      <span v-if="!onlyEnd">
        {{ data.item.startTime }}
      </span>
      <span v-if="!onlyEnd && !onlyStart">&nbsp;–&nbsp;</span>
      <span v-if="onlyEnd"> bis&nbsp;</span>
      <span v-if="!onlyStart">{{ data.item.endTime }}</span>
    </template>
    <template v-slot:item="data">
      <span v-if="onlyStart"> von&nbsp;</span>
      <span v-if="!onlyEnd">
        {{ data.item.startTime }}
      </span>
      <span v-if="!onlyEnd && !onlyStart">&nbsp;–&nbsp;</span>
      <span v-if="onlyEnd">bis&nbsp;</span>
      <span v-if="!onlyStart">{{ data.item.endTime }}</span>
    </template>
  </v-autocomplete>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "PeriodInput",
  props: {
    value: { type: Object, default: null },
    label: { type: String, default: "Lektion" },
    onlyStart: { type: Boolean, default: false },
    onlyEnd: { type: Boolean, default: false },
    site: { type: Object, default: null },
  },
  data() {
    return {
      items: [],
    };
  },
  watch: {
    site() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      this.items = await this.apiList({
        resource: "common/period",
        query: `site=${this.site ? this.site.id : 1}`,
      });
    },
    select($event) {
      this.$emit("input", $event);
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
