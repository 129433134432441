<template>
  <v-menu max-height="400px" :close-on-content-click="true">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-if="value && value.id"
        :color="color"
        v-bind="attrs"
        v-on="on"
        close
        @click:close="clear"
      >
        <v-avatar left>
          <PortraitImage :value="value" />
        </v-avatar>

        <PersonName :value="value" ext />
      </v-chip>
      <v-chip :color="color" v-bind="attrs" v-on="on" v-else
        ><v-icon small>mdi-account-plus</v-icon></v-chip
      >
    </template>
    <v-card scrollable>
      <v-system-bar dark :color="color">
        {{ title }}
      </v-system-bar>
      <v-list>
        <PersonItemBasic :value="value" clickable />
        <v-divider />
        <v-list-item @click.stop :ripple="false">
          <v-text-field
            label="Suche"
            v-model="search"
            autofocus
            autocomplete="off"
          ></v-text-field>
        </v-list-item>
        <v-lazy
          v-for="item in itemsFiltered"
          :key="item.id"
          :options="{
            threshold: 0.5,
          }"
          min-height="40"
        >
          <PersonItemBasic :value="item" @click="select(item)" clickable />
        </v-lazy>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
import { defineComponent } from "vue";
import { searchPerson, sortPeople } from "common/utils/people.js";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
export default defineComponent({
  components: { PersonItemBasic, PersonName, PortraitImage },
  props: ["color", "value", "group"],
  data() {
    return {
      loading: true,
      items: [],
      search: "",
      menu: true,
      groups: [
        { id: 1, code: "student", description: "Schüler*innen" },
        { id: 2, code: "teacher", description: "Lehrer*innen" },
        { id: 3, code: "person", description: "Personen" },
        { id: 4, code: "parent", description: "Eltern" },
        { id: 5, code: "intern", description: "Praktikant*innen" },
        { id: 6, code: "account", description: "Benutzer*innen" },
      ],
    };
  },
  computed: {
    itemsFiltered() {
      if (!this.search) {
        return this.items;
      }
      return this.items.filter((item) => searchPerson(item, this.search));
    },
    title() {
      if (this.group) {
        const g = this.groups.find((item) => item.code === this.group);
        if (g) {
          return g.description;
        }
      }

      return "Person";
    },
  },
  watch: {
    async value() {
      if (this.value && this.value.id) {
        if (!this.items.some((item) => item.id === this.value.id)) {
          this.items.push(this.value);
        }
      }
    },
    group() {
      this.getData();
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      this.items = sortPeople(
        await this.apiList({
          resource: "person/person",
          query: `group=${this.group}`,
        })
      );
      this.loading = false;
    },
    clear() {
      this.$emit("input", null);
    },
    select(item) {
      this.$emit("input", item);
    },
  },
  async created() {
    this.getData();
  },
});
</script>

<style scoped>
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
