<template>
  <v-container>
    <v-app-bar dark app color="primary" clipped-right>
      <v-app-bar-nav-icon>
        <v-icon>mdi-school</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Kurse</v-toolbar-title>
      <v-spacer />
      <SearchField
        v-model="search"
        hint="Fach (z.B. Deutsch), Fachgruppe (z.B. EF), Klasse, Lehrer*in"
      ></SearchField>
      <template v-slot:extension>
        <TermTabs v-model="term" right />
      </template>
    </v-app-bar>
    <v-row>
      <v-col>
        <v-card v-if="course">
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">
                        {{ course.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ formatDatespan(course.startDate, course.endDate) }},
                        {{ course.studentCount.total }} Schüler:innen ({{
                          course.studentCount.female
                        }}
                        <v-icon small>mdi-gender-female</v-icon>,
                        {{ course.studentCount.male }}
                        <v-icon small>mdi-gender-male</v-icon>
                        <span v-if="course.studentCount.fluid > 0"
                          >,
                          {{ course.studentCount.fluid }}
                          <v-icon small>mdi-gender-transgender</v-icon></span
                        >)
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <PersonItem
                    v-for="teacher in course.teachers"
                    :key="teacher.id"
                    :value="teacher"
                    clickable
                  />
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-tabs icons-and-text>
            <v-tabs-slider color="error"></v-tabs-slider>
            <v-tab :to="{ name: 'CourseList' }"
              >Liste<v-icon>mdi-format-align-left</v-icon></v-tab
            >
            <v-tab
              :to="{
                name: 'CoursePortrait',
              }"
              >Porträts<v-icon>mdi-account-box</v-icon></v-tab
            >
            <v-tab :to="{ name: 'CourseReport' }"
              >Berichte<v-icon>mdi-text-box-multiple</v-icon></v-tab
            >
          </v-tabs>
          <router-view></router-view>
        </v-card>
      </v-col>
      <v-col class="col-auto">
        <v-navigation-drawer app clipped permanent right>
          <v-list nav dense>
            <Course
              v-for="item in items"
              :key="item.id"
              clickable
              nodetails
              @click="selectCourse(item)"
              :value="item"
            />
            <v-list-item v-if="!loading && items.length == 0"
              >keine Suchtreffer</v-list-item
            >
            <v-skeleton-loader
              v-if="loading"
              type="list-item-two-line"
            ></v-skeleton-loader>
          </v-list>
        </v-navigation-drawer>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { formatDatespan } from "common/utils/date.js";
import Course from "@/components/Course";
import PersonItem from "common/components/PersonItem";
import SearchField from "@/components/SearchField";
import TermTabs from "@/components/TermTabs";

export default defineComponent({
  name: "Courses",
  components: { Course, PersonItem, SearchField, TermTabs },
  props: ["id"],
  data() {
    return {
      course: null,
      items: [],
      loading: false,
      term: {},
      search: "",
    };
  },
  watch: {
    search() {
      if (!this.search) {
        this.items = [];
        return;
      }
      if (this.search.length >= 2) {
        this.timedSearch();
      }
    },
    term() {
      if (this.search && this.search.length >= 2) {
        this.doSearch();
      }
    },
  },
  methods: {
    timedSearch() {
      // cancel pending call
      clearTimeout(this._searchTimerId);

      // delay new call 500ms
      this._searchTimerId = setTimeout(() => {
        this.doSearch();
      }, 500);
    },
    async doSearch() {
      this.loading = true;
      const result = await this.apiList({
        resource: "course/course",
        query: `search=${this.search}&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });
      this.items = result.courses;
      localStorage.setItem("courseSearch", this.search);
      if (this.items && this.items.length == 1) {
        this.$router.push({ name: "Course", params: { id: this.items[0].id } });
      }
      this.loading = false;
    },
    async selectCourse(item) {
      this.course = item;
      this.course = await this.apiGet({
        resource: "course/course",
        id: item.id,
      });
    },
    formatDatespan: formatDatespan,
  },
  async created() {
    if (localStorage.getItem("courseSearch")) {
      this.search = localStorage.getItem("courseSearch");
    }
    if (this.id > 0) {
      this.loading = true;
      this.course = await this.apiGet({
        resource: "course/course",
        id: this.id,
      });
      this.loading = false;
    }
  },
});
</script>
