<template>
  <v-tooltip top v-if="display != tooltip">
    <template v-slot:activator="{ on, attrs }">
      <span class="text-caption" v-bind="attrs" v-on="on" v-html="display">
      </span>
    </template>
    <span class="text-caption" v-html="tooltip"></span>
  </v-tooltip>
  <span v-else class="text-caption" v-html="tooltip"></span>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: null,
  },
  computed: {
    display() {
      if (!this.value || this.value.length == 0 || !this.value[0]) {
        return "";
      }
      if (this.value[0].reservedFor) {
        return this.value[0].reservedFor.code;
      }
      return this.value[0].reservedBy.code;
    },
    tooltip() {
      if (!this.value || this.value.length == 0 || !this.value[0]) {
        return "";
      }
      return (
        this.value[0].description +
        " (" +
        this.value[0].startTime +
        " - " +
        this.value[0].endTime +
        ")"
      );
    },
  },
});
</script>
