<template>
  <v-list>
    <v-subheader v-if="this.heading">{{ heading }}</v-subheader>
    <PersonItem v-for="item in sorted" :key="item.id" :value="item" clickable />
  </v-list>
</template>
<script>
import { defineComponent } from "vue";
import PersonItem from "common/components/PersonItem.vue";
import { bus } from "@/services/bus.service.js";
import { sortPeople } from "common/utils/people.js";

export default defineComponent({
  name: "PeopleList",
  components: { PersonItem },
  props: {
    people: Array,
    heading: String,
  },
  data() {
    return { sorted: [] };
  },
  methods: {
    sortPeople() {
      this.sorted = sortPeople(this.people);
    },
  },
  watch: {
    people() {
      this.sortPeople();
    },
  },
  created() {
    this.sortPeople();
    bus.$on("updatePeopleSort", () => {
      this.sortPeople();
    });
  },
});
</script>
