<template>
  <v-btn-toggle tile group v-model="peopleSort" mandatory>
    <v-btn small value="firstName"> Vorname </v-btn>
    <v-btn small value="lastName"> Nachname </v-btn>
  </v-btn-toggle>
</template>
<script>
import { defineComponent } from "vue";
import { getPeopleSort, setPeopleSort } from "common/utils/people.js";
export default defineComponent({
  name: "PeopleSort",
  data() {
    return {
      peopleSort: "",
    };
  },
  watch: {
    peopleSort() {
      setPeopleSort(this.peopleSort);
      this.$emit("update");
    },
  },
  created() {
    this.peopleSort = getPeopleSort();
  },
});
</script>
