<template>
  <div>
    <TableComponent
      :items="itemsFiltered"
      :loading="loading"
      @showDetails="showDetails"
    />

    <router-view @update="fetchData"></router-view>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { searchPerson } from "common/utils/people.js";
import TableComponent from "./TableComponent.vue";

export default defineComponent({
  name: "FinanceInvoices",
  components: {
    TableComponent,
  },
  props: ["search"],
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  computed: {
    itemsFiltered() {
      if (!this.search) {
        return this.items;
      }
      return this.items.filter(
        (el) =>
          el.number.toLowerCase().startsWith(this.search.toLowerCase()) ||
          el.amount == parseFloat(this.search) ||
          searchPerson(el.employee, this.search)
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "finance/invoice",
        query: `status=entered`,
      });
      this.loading = false;
    },
    showDetails(id) {
      this.$router.push({
        name: "FinanceInvoicesEnteredDetail",
        params: { id: id },
      });
    },
  },
  async created() {
    await this.fetchData();
  },
});
</script>
