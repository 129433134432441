<template>
  <v-card :loading="loading">
    <v-system-bar window class="mb-2">
      Notiz<v-spacer />
      <v-btn @click="$emit('close')" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-system-bar>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation :disabled="loading">
        <v-text-field
          v-if="!loading"
          v-model="item.title"
          label="Titel"
          required
          :rules="titleRules"
          autofocus
        ></v-text-field>

        <LookupValueBtnToggle
          resource="register/notetype"
          v-model="item.type"
          label="Art"
          mandatory
          :color="color"
        />

        <v-switch
          class="ml-1"
          v-if="item.type.code == 'exam'"
          v-model="item.plannedExam"
          :label="`im Probenplan eintragen: ${
            item.plannedExam ? 'ja' : 'nein'
          }`"
          inset
        />
        <v-switch
          class="ml-1"
          v-model="item.smartLearn"
          :label="`smartlearn: ${item.smartLearn ? 'ja' : 'nein'}`"
          inset
        />

        <DateInput
          v-if="selectLesson"
          label="Datum"
          v-model="item.date"
          :allowed-dates="allowedDates"
        ></DateInput>
        <PeriodInput v-if="selectLesson" v-model="item.period"></PeriodInput>

        <v-textarea
          v-model="item.description"
          label="Text"
          outlined
        ></v-textarea>

        <v-text-field
          v-model="item.url"
          label="URL (Link)"
          placeholder="https://"
        ></v-text-field>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <v-btn text @click="$emit('close')">abbrechen </v-btn>
      <v-spacer class="mx-8"></v-spacer>
      <v-btn
        text
        color="danger"
        @click="trash"
        :loading="trashing"
        v-if="!isNew"
        >löschen</v-btn
      >
      <v-btn
        text
        color="primary"
        @click="save"
        :loading="saving"
        :disabled="!valid"
        >speichern</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";

import DateInput from "common/components/DateInput.vue";
import PeriodInput from "common/components/PeriodInput.vue";
import LookupValueBtnToggle from "common/components/LookupValueBtnToggle.vue";

import { today } from "common/utils/date.js";
import { noteColor } from "common/utils/icons.js";

export default defineComponent({
  name: "NoteForm",
  components: { DateInput, PeriodInput, LookupValueBtnToggle },
  props: ["id", "defaults"],
  data() {
    return {
      item: {
        id: 0,
        title: "",
        description: "",
        url: "",
        type: { code: "", description: "" },
      },
      loading: false,
      valid: false,
      saving: false,
      trashing: false,
      titleRules: [(v) => !!v || "Titel ist obligatorisch"],
      types: [],
    };
  },
  computed: {
    isNew() {
      return this.id == 0;
    },
    selectLesson() {
      return !this.isNew || !this.item.period;
    },
    color() {
      return noteColor(this.item);
    },
  },
  watch: {
    defaults() {
      this.fetchData();
    },
    id() {
      this.fetchData();
    },
  },
  methods: {
    allowedDates: (val) => val >= today(),

    async fetchData() {
      this.loading = true;
      if (this.id) {
        this.item = await this.apiGet({
          resource: "register/note",
          id: this.id,
        });
      } else {
        this.item = { ...this.item, ...this.defaults };

        const periods = await this.apiList({ resource: "common/period" });
        this.item.period = periods.find(
          (el) => el.startTime == this.defaults.startTime
        );
      }
      this.loading = false;
    },
    async save() {
      this.saving = true;
      await this.apiSave({
        add: this.isNew,
        resource: "register/note",
        data: this.item,
      });
      this.saving = false;
      this.$emit("updated");
      this.$emit("close");
    },
    async trash() {
      if (
        await this.$root.confirm({
          message: "soll diese Notiz gelöscht werden?",
          icon: "mdi-trash-can",
          color: "danger",
        })
      ) {
        this.trashing = true;
        await this.apiDelete({ resource: "register/note", id: this.item.id });
        this.trashing = false;
        this.$emit("updated");
        this.$emit("close");
      }
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
