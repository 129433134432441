<template>
  <div>
    <v-toolbar flat dense class="mb-2">
      <v-row align="center">
        <v-col>
          <DateInput hideDetails v-model="date" />
        </v-col>
        <v-col>
          <v-btn icon @click="addDays(-7)"
            ><v-icon>mdi-chevron-double-left</v-icon></v-btn
          >
          <v-btn icon @click="addDays(-1)"
            ><v-icon>mdi-chevron-left</v-icon></v-btn
          >
          <v-btn icon @click="today()"
            ><v-icon>mdi-calendar-check</v-icon></v-btn
          >
          <v-btn icon @click="addDays(1)"
            ><v-icon>mdi-chevron-right</v-icon></v-btn
          >
          <v-btn icon @click="addDays(7)"
            ><v-icon>mdi-chevron-double-right</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-toolbar>
    <v-card>
      <v-system-bar>Statistik&nbsp;<DateVue :value="date" long /></v-system-bar>

      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :items-per-page="25"
        :search="search"
      >
        <template v-slot:item.person="{ item }">
          <PersonItem clickable :value="item.person" />
        </template>
        <template v-slot:item.error="{ item }">
          <v-icon v-if="item.error" color="error">mdi-alert</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DateVue from "common/components/DateValue.vue";
import DateInput from "common/components/DateInput.vue";
import { today } from "common/utils/date.js";
export default defineComponent({
  name: "LogResourceUsage",
  components: { DateVue, DateInput },
  props: ["search"],
  data() {
    return {
      items: [],
      loading: false,
      date: null,
      headers: [
        { text: "Anwendung", value: "application.code" },
        { text: "Resource", value: "resource.endpoint" },
        { text: "get", value: "getCount" },
        { text: "post", value: "postCount" },
        { text: "put", value: "putCount" },
        { text: "patch", value: "patchCount" },
        { text: "delete", value: "deleteCount" },
      ],
    };
  },
  watch: {
    async date() {
      if (this.date) {
        this.loading = true;
        this.items = await this.apiList({
          resource: "app/resourceusage",
          query: "date=" + this.date,
        });
        this.loading = false;
      } else {
        this.items = [];
      }
    },
  },
  methods: {
    today() {
      this.date = today();
    },
    addDays(days) {
      let date = new Date(this.date);
      date.setDate(date.getDate() + days);
      let month = "" + (date.getMonth() + 1);
      let day = "" + date.getDate();
      let year = date.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      this.date = [year, month, day].join("-");
    },
  },
  created() {
    this.date = today();
  },
});
</script>
