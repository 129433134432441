<template>
  <v-dialog
    max-width="700"
    width="100%"
    :value="true"
    @click:outside="close()"
    @keydown.esc="close()"
    persistent
    scrollable
  >
    <v-card :loading="loading" v-if="locker">
      <v-system-bar color="error" class="mb-2" window>
        Schäftli {{ locker.code }}, {{ subtitle }}
        <v-spacer />
        <v-btn @click="close()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-card-text>
        <v-timeline>
          <v-timeline-item
            v-for="index in countSpace"
            :key="index"
            left
            fill-dot
            color="success"
          >
            <v-card>
              <v-card-text>
                <PersonInput
                  label="Schüler:in hinzufügen"
                  @input="create"
                  group="student"
                  color="success"
                />
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            v-for="item in current"
            left
            fill-dot
            :key="item.id"
            color="primary"
          >
            <v-card>
              <PersonItem
                clickable
                nolink
                nodetails
                :value="item.student"
                @click="
                  $router.push({
                    name: 'LockerStudentDetails',
                    params: { id: item.student.id },
                  })
                "
              />
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="finish(item.id, item.student)"
                  >entfernen</v-btn
                >
              </v-card-actions>
            </v-card>
            <template v-slot:opposite>
              {{ formatDatespan(item.startDate, item.endDate) }}
            </template>
          </v-timeline-item>
          <v-timeline-item fill-dot color="error">
            <template v-slot:icon>
              <v-icon @click="showHistory = !showHistory" dark>{{
                showHistory ? "mdi-dots-vertical" : "mdi-dots-horizontal"
              }}</v-icon>
            </template>
            <template v-slot:opposite>
              <v-btn text small @click="showHistory = !showHistory">
                {{ showHistory ? "ältere ausblenden" : "ältere einblenden" }}
              </v-btn>
            </template>
          </v-timeline-item>
          <template v-if="showHistory">
            <v-timeline-item
              v-for="item in history"
              right
              fill-dot
              :key="item.id"
              color="grey"
            >
              <v-card>
                <PersonItem
                  :value="item.student"
                  clickable
                  nolink
                  nodetails
                  @click="
                    $router.push({
                      name: 'LockerStudentDetails',
                      params: { id: item.student.id },
                    })
                  "
                />
              </v-card>
              <template v-slot:opposite>
                {{ formatDatespan(item.startDate, item.endDate) }}
              </template>
            </v-timeline-item>
          </template>
        </v-timeline>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn @click="prev()" text
          ><v-icon left>mdi-chevron-left</v-icon>vorheriger Eintrag</v-btn
        >
        <v-spacer />
        <v-btn @click="next()" text
          >nächster Eintrag<v-icon right>mdi-chevron-right</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { formatDatespan } from "common/utils/date.js";
import { personName } from "common/utils/people.js";
import PersonItem from "common/components/PersonItem.vue";
import PersonInput from "common/components/PersonInput.vue";
export default defineComponent({
  name: "LockerDetails",
  components: { PersonItem, PersonInput },
  props: ["id"],
  data() {
    return {
      locker: null,
      current: [],
      editMode: false,
      history: [],
      update: false,
      showHistory: false,
      loading: false,
    };
  },
  computed: {
    personName,
    countSpace() {
      if (this.loading) {
        return 0;
      }
      return Math.max(0, this.locker.size - this.current.length);
    },
    subtitle() {
      if (!this.locker) {
        return "";
      }
      let result = "";
      if (this.locker.code.startsWith("P")) {
        result = "Pavillon";
      } else if (this.locker.code.startsWith("0")) {
        result = "Geschoss 00";
      } else if (this.locker.code.startsWith("1")) {
        result = "Geschoss 01";
      } else if (this.locker.code.startsWith("2")) {
        result = "Geschoss 02";
      } else if (this.locker.code.startsWith("3")) {
        result = "Geschoss 03";
      } else if (this.locker.code.startsWith("4")) {
        result = "Geschoss 04";
      }
      return result;
    },
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    async prev() {
      this.$emit("prev");
    },
    async next() {
      this.$emit("next");
    },
    async close() {
      if (this.update) {
        this.$emit("update");
      }
      this.$router.push({
        name: "LockersList",
      });
    },
    async create(student) {
      if (
        student &&
        (await this.$root.confirm({
          message: `Soll ${personName(student)} das Schäftli ${
            this.locker.code
          } zugewiesen werden?`,
          color: "error",
          iconL: "mdi-account-plus",
        }))
      ) {
        await this.apiPost({
          resource: "locker/usage",
          data: {
            locker: this.locker.id,
            student: student.id,
          },
        });
        this.$root.showSuccess(
          ` ${personName(student)} erfolgreich dem Schäftli ${
            this.locker.code
          } zugewiesen`
        );
        this.fetchData();
        this.update = true;
      }
    },
    async finish(id, student) {
      if (
        await this.$root.confirm({
          message: `Soll ${personName(student)} vom Schäftli ${
            this.locker.code
          } ausgetragen werden?`,
          color: "error",
          icon: "mdi-account-remove",
        })
      ) {
        await this.apiPut({
          resource: "locker/usage",
          data: { id: id, finish: true },
        });
        this.$root.showSuccess(
          `${personName(student)} erfolgreich aus Schäftli ${
            this.locker.code
          } ausgetragen`
        );
        this.fetchData();
        this.update = true;
      }
    },
    async fetchData() {
      this.loading = true;
      this.locker = await this.apiGet({
        resource: "locker/locker",
        id: this.id,
      });
      this.editMode = false;
      this.current = this.locker.current.sort((a, b) =>
        a.startDate > b.startDate ? -1 : a.startDate < b.startDate ? 1 : 0
      );
      this.history = this.locker.history.sort((a, b) =>
        a.endDate > b.endDate ? -1 : a.endDate < b.endDate ? 1 : 0
      );
      this.loading = false;
    },
    formatDatespan: formatDatespan,
  },
  async created() {
    this.fetchData();
  },
});
</script>
