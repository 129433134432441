var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"tile":"","loading":_vm.loading}},[_c('v-system-bar',{attrs:{"color":"error","window":""}},[_vm._v("Person "),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-0 ma-0"},[_c('v-card-text',{staticClass:"title pt-8 pb-0 text-center"},[_c('PersonName',{attrs:{"value":_vm.value}})],1),_c('v-card-text',{staticClass:"subtitle pt-0 text-center"},[_vm._v(" "+_vm._s(_vm.personCode(_vm.value))+" ")]),_c('v-card-text',{staticClass:"display text-center"},[_c('v-avatar',{attrs:{"size":_vm.$vuetify.breakpoint.xs ? 50 : 120}},[_c('PortraitImage',{attrs:{"large":!_vm.$vuetify.breakpoint.xs,"value":_vm.value}})],1),_c('br')],1)],1),_c('v-divider'),_c('v-list',{attrs:{"subheader":""}},[_c('v-subheader',[_vm._v("Links")]),_c('v-list-item',{attrs:{"to":{
        name: 'Person',
        params: {
          id: _vm.value.id.toString(),
        },
      }},on:{"click":_vm.close}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Person anzeigen ")])],1)],1),(_vm.value.schoolClass)?_c('v-list-item',{attrs:{"to":{
        name: 'SchoolClass',
        params: {
          id: _vm.value.schoolClass.id.toString(),
        },
      }},on:{"click":_vm.close}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-group")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Klasse anzeigen ")])],1)],1):_vm._e(),_c('v-subheader',[_vm._v("Aktionen")]),(_vm.student && _vm.student.phoneHome)?_c('v-list-item',{attrs:{"href":'tel:' + _vm.student.phoneHome}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-phone")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("anrufen (zu Hause)")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.student.phoneHome)+" ")])],1)],1):_vm._e(),(_vm.student && _vm.student.phoneMobile)?_c('v-list-item',{attrs:{"href":'tel:' + _vm.student.phoneMobile}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cellphone")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("anrufen (Handy)")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.student.phoneMobile)+" ")])],1)],1):_vm._e(),(_vm.student && _vm.student.emailSchool)?_c('v-list-item',{attrs:{"href":_vm.mailto(_vm.student.emailSchool),"target":"_blank"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-email")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("E-Mail schreiben")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.student.emailSchool)+" ")])],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":"","block":""},on:{"click":_vm.close}},[_vm._v("schliessen")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }