export function age(date) {
  return dateDiff(date, today());
}

export function today() {
  return new Date().toJSON().split("T")[0];
}

export function now() {
  return new Date().toLocaleTimeString("de-CH").substring(0, 5);
}

const MILLIS_PER_DAY = 24 * 60 * 60 * 1000;
const DAYS_OF_WEEK = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
const DAYS_OF_WEEK_FULL = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];
const MONTHS = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

function removeLeadingZeros(text) {
  if (text.startsWith("0")) {
    return text.substring(1);
  }
  return text;
}

export function addDays(date, days) {
  return new Date(new Date(date).getTime() + days * 24 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[0];
}

export function checkDatespan(startDate, startTime, endDate, endTime) {
  if (!startDate || !endDate) {
    return false;
  }
  return startDate + startTime > endDate + endTime;
}

export function dateDiff(startDate, endDate) {
  if (startDate === endDate) return 0;
  const s = new Date(startDate);
  const e = new Date(endDate);
  return (e - s) / MILLIS_PER_DAY;
}

export function dateTimeDiff(startDate, startTime, endDate, endTime) {
  const s = new Date(startDate + "T" + startTime);
  const e = new Date(endDate + "T" + endTime);

  return (e - s) / 1000;
}

export function isCurrent(item) {
  return (
    dateTimeDiff(
      item.startDate,
      item.startTime ? item.startTime : "00:00",
      today(),
      now()
    ) > 0 &&
    dateTimeDiff(
      item.endDate ? item.endDate : item.startDate,
      item.endTime ? item.endTime : "23:59",
      today(),
      now()
    ) < 0
  );
}

export function isFuture(item) {
  return (
    dateTimeDiff(
      item.startDate,
      item.startTime ? item.startTime : "00:00",
      today(),
      now()
    ) < 0
  );
}

export function isPast(item) {
  return (
    dateTimeDiff(
      item.endDate ? item.endDate : item.startDate,
      item.endTime ? item.endTime : "23:59",
      today(),
      now()
    ) > 0
  );
}

export function findMonday(date) {
  const d = new Date(addDays(date, -1));
  return addDays(date, -d.getDay());
}

export function findStartOfMonth(date) {
  const d = new Date(date);
  return new Date(d.getFullYear(), d.getMonth(), 1).toISOString().split("T")[0];
}

export function findEndOfMonth(date) {
  const d = new Date(date);
  return new Date(d.getFullYear(), d.getMonth() + 1, 0)
    .toISOString()
    .split("T")[0];
}

export function formatDayOfWeek(date, full) {
  const d = new Date(date);
  if (full) {
    return DAYS_OF_WEEK_FULL[d.getDay()];
  }
  return DAYS_OF_WEEK[d.getDay()];
}

export function formatDate(date, long, full) {
  if (!date || typeof date !== "string") return "";
  if (full) {
    return (
      formatDayOfWeek(date, true) +
      ", " +
      removeLeadingZeros(date.substring(8, 10)) +
      "." +
      removeLeadingZeros(date.substring(5, 7)) +
      "." +
      date.substring(0, 4)
    );
  }
  if (long) {
    return (
      formatDayOfWeek(date) +
      "., " +
      removeLeadingZeros(date.substring(8, 10)) +
      "." +
      removeLeadingZeros(date.substring(5, 7)) +
      "." +
      date.substring(0, 4)
    );
  }
  return (
    date.substring(8, 10) +
    "." +
    date.substring(5, 7) +
    "." +
    date.substring(0, 4)
  );
}

export function formatMonth(date) {
  const d = new Date(date);
  return MONTHS[d.getMonth()];
}

export function formatTime(time, long, seconds) {
  if (!time || typeof time !== "string") return "";
  if (long) {
    return (
      removeLeadingZeros(time.substring(0, 2)) +
      "." +
      time.substring(3, 5) +
      " Uhr"
    );
  }
  if (seconds) {
    return (
      time.substring(0, 2) +
      ":" +
      time.substring(3, 5) +
      ":" +
      time.substring(6, 8)
    );
  }
  return time.substring(0, 2) + ":" + time.substring(3, 5);
}

export function formatDatespan(startDate, endDate, long, full) {
  if (typeof startDate === "string" && typeof endDate === "string") {
    const endY = endDate.substring(0, 4);
    const endM = endDate.substring(5, 7);
    const endD = endDate.substring(8, 10);
    const startM = startDate.substring(5, 7);
    const startD = startDate.substring(8, 10);
    let startDayOfWeek = "";
    let endDayOfWeek = "";
    if (long) {
      startDayOfWeek = formatDayOfWeek(startDate) + "., ";
      endDayOfWeek = formatDayOfWeek(endDate) + "., ";
    }
    if (full) {
      startDayOfWeek = formatDayOfWeek(startDate, true) + ", ";
      endDayOfWeek = formatDayOfWeek(endDate, true) + ", ";
    }

    if (startDate.substring(0, 4) === endY) {
      if (startM === endM) {
        if (startD === endD) {
          return formatDate(startDate, long, full);
        } else {
          return (
            startDayOfWeek +
            removeLeadingZeros(startD) +
            ". bis " +
            endDayOfWeek +
            removeLeadingZeros(endD) +
            "." +
            removeLeadingZeros(endM) +
            "." +
            endY
          );
        }
      } else {
        return (
          startDayOfWeek +
          removeLeadingZeros(startD) +
          "." +
          removeLeadingZeros(startM) +
          ". bis " +
          endDayOfWeek +
          removeLeadingZeros(endD) +
          "." +
          removeLeadingZeros(endM) +
          "." +
          endY
        );
      }
    } else {
      return (
        formatDate(startDate, long, full) +
        " bis " +
        formatDate(endDate, long, full)
      );
    }
  } else if (typeof startDate === "string") {
    return "ab " + formatDate(startDate, long, full);
  } else if (typeof endDate === "string") {
    return "bis " + formatDate(endDate, long, full);
  } else {
    return null;
  }
}

export function formatTimespan(startDate, startTime, endDate, endTime) {
  const days = dateDiff(startDate, endDate) + 1;
  const multiDay = days > 1;
  if (!startTime && !endTime) {
    return multiDay ? days + " Tage" : "ganztags";
  }
  if (!endTime) {
    if (multiDay) return days + " Tage, ab " + formatTime(startTime);
    return "ab " + formatTime(startTime);
  }
  if (!startTime) {
    if (multiDay) return days + " Tage, bis " + formatTime(endTime);
    return "bis " + formatTime(endTime);
  }
  return formatTime(startTime) + " bis " + formatTime(endTime);
}

export function overlaps(a, b) {
  if (
    dateTimeDiff(a.endDate, a.endTime, b.startDate, b.startTime) > 0 ||
    dateTimeDiff(b.endDate, b.endTime, a.startDate, a.startTime) > 0
  )
    return false;

  return true;
}
