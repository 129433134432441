<template>
  <v-dialog scrollable v-model="shown" width="700" persistent>
    <v-card :loading="loading">
      <v-system-bar class="mb-4" window :color="isNew ? 'success' : 'error'">{{
        isNew ? "neuer Eintrag" : "Eintrag editieren"
      }}</v-system-bar>
      <v-card-text>
        <v-text-field
          v-model="item.title"
          label="Titel*"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="item.keywords"
          label="Schlüsselwörter"
        ></v-text-field>

        <v-textarea
          background-color="#f2f2f2"
          filled
          label="Beschreibung"
          v-model="item.description"
        ></v-textarea>
        <v-text-field v-model="item.link" label="Link"></v-text-field>
        <LookupValueInput
          v-model="item.type"
          :items="types"
          label="Typ*"
          :rules="[rules.required]"
        />
        <v-text-field
          v-model="item.sortOrder"
          type="number"
          label="Reihenfolge"
        ></v-text-field>
        <LookupValueInput
          v-model="item.division"
          :items="divisions"
          label="Abteilung"
          clearable
        />
        <LookupValueInput
          v-model="item.visibility"
          :items="visibilities"
          label="Sichtbarkeit*"
          :rules="[rules.required]"
        />

        <v-list>
          <PersonItem
            v-for="(person, index) in item.people"
            :key="'person' + index"
            :value="{ ...person.person, description: person.role.description }"
          >
            <v-btn icon @click="item.people.splice(index, 1)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </PersonItem>
          <v-list-item
            ><v-list-item-content>
              <v-row
                ><v-col
                  ><PersonInput
                    group="employee"
                    label="Person"
                    v-model="newPerson.person"
                /></v-col>
                <v-col>
                  <LookupValueInput
                    v-model="newPerson.role"
                    :items="personRoles"
                    label="Rolle"
                  /> </v-col
              ></v-row>
            </v-list-item-content>
            <v-list-item-action
              ><v-btn
                icon
                @click="addPerson"
                :disabled="
                  !newPerson.role || !newPerson.person || !newPerson.person
                "
                ><v-icon>mdi-plus</v-icon></v-btn
              ></v-list-item-action
            ></v-list-item
          >
        </v-list>
        <v-list>
          <v-list-item
            v-for="(document, index) in item.documents"
            :key="'document' + index"
          >
            <DocumentItemIcon :value="document" />

            <v-list-item-content>
              <DocumentItem :value="document" />
            </v-list-item-content>
            <v-list-item-action
              ><v-btn icon @click="item.documents.splice(index, 1)"
                ><v-icon>mdi-delete</v-icon></v-btn
              ></v-list-item-action
            >
          </v-list-item>
          <v-list-item
            ><v-list-item-content>
              <DocumentInput
                label="Dokument"
                v-model="newDocument"
                :visibilty="item.visibility"
              />
            </v-list-item-content>
            <v-list-item-action
              ><v-btn icon @click="addDocument" :disabled="!newDocument"
                ><v-icon>mdi-plus</v-icon></v-btn
              ></v-list-item-action
            ></v-list-item
          >
        </v-list>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-btn text color="danger" @click="trash">löschen</v-btn>
        <v-spacer />
        <v-btn text @click="close">abbrechen</v-btn>
        <v-btn text color="primary" @click="save">speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import DocumentInput from "@/components/DocumentInput.vue";
import DocumentItem from "@/components/DocumentItem.vue";
import DocumentItemIcon from "@/components/DocumentItemIcon.vue";
import PersonItem from "common/components/PersonItem.vue";
import PersonInput from "common/components/PersonInput.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
export default defineComponent({
  components: {
    DocumentInput,
    DocumentItem,
    DocumentItemIcon,
    LookupValueInput,
    PersonItem,
    PersonInput,
  },
  props: ["id"],
  data() {
    return {
      item: {
        documents: [],
      },
      shown: true,
      loading: false,
      divisions: [],
      types: [],
      visibilities: [],
      personRoles: [],
      newDocument: {},
      newPerson: {},
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  computed: {
    isNew() {
      return this.id == 0;
    },
  },
  methods: {
    addDocument() {
      this.item.documents.push(this.newDocument);
      this.newDocument = {};
    },
    addPerson() {
      this.item.people.push(this.newPerson);
      this.newPerson = {};
    },
    close() {
      if (this.item.id) {
        this.$router.push({
          name: "IndexDetails",
          params: { id: this.item.id },
        });
      } else {
        this.$router.push({ name: "Index" });
      }
    },
    async fetchData() {
      this.loading = true;
      if (this.id) {
        this.item = {};
        this.item = await this.apiGet({
          resource: "document/indexentry",
          id: this.id,
        });
      }
      this.divisions = await this.apiList({ resource: "common/division" });
      this.types = await this.apiList({ resource: "document/indexentrytype" });
      this.personRoles = await this.apiList({
        resource: "document/indexentrypersonrole",
      });
      this.visibilities = await this.apiList({
        resource: "document/visibility",
      });
      this.loading = false;
    },
    async save() {
      if (this.isNew) {
        this.item = await this.apiPost({
          resource: "document/indexentry",
          data: this.item,
        });
        this.$emit("dataChanged");
        this.close();
      } else {
        await this.apiPut({ resource: "document/indexentry", data: this.item });
        this.$emit("dataChanged");
        this.close();
      }
    },
    async trash() {
      await this.apiDelete({
        resource: "document/indexentry",
        id: this.item.id,
      });
      this.$router.push({ name: "Index" });
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
