<template>
  <v-card class="mb-2">
    <v-system-bar>Person</v-system-bar>
    <v-card-text>
      <p>Stellt eine Person mit Avatar und Namen dar</p>
      <v-row>
        <v-col
          ><PersonItem
            v-if="person"
            :hideExt="hideExt"
            :small="small"
            :flags="flags"
            :value="{ ...person, description: description }"
        /></v-col>
        <v-col
          ><v-text-field
            type="number"
            label="personId"
            v-model="id"
          ></v-text-field>
          <v-text-field
            type="text"
            label="description"
            v-model="description"
          ></v-text-field>
          <v-checkbox
            v-model="hideExt"
            :label="`hideExt: ${hideExt.toString()}`"
            persistent-hint
            hint="Item ist clickbar: Standard-to-Link und click-Event. (wird in PersonPicker verwendet)"
          ></v-checkbox>
          <v-checkbox
            v-model="flags"
            :label="`flags: ${flags.toString()}`"
            persistent-hint
            hint="Item soll kein Link haben. (kann trotzdem klickbar sein, um ein Event zu generieren)"
          ></v-checkbox>
          <v-checkbox
            v-model="small"
            :label="`small: ${small.toString()}`"
            persistent-hint
            hint="Item zeigt die drei Punkte nicht an. (ganzes Item ist klickbar)"
          ></v-checkbox>
        </v-col>
      </v-row>

      <code
        >&lt;PersonItem :value="person" :hideExt="{{ hideExt }}" :flags="{{
          flags
        }}" :small="{{ small }}" /&gt;</code
      >
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import PersonItem from "common/components/PersonItem";
export default defineComponent({
  name: "PersonDemo",
  components: { PersonItem },
  data() {
    return {
      id: 162,
      person: null,
      hideExt: false,
      flags: false,
      small: false,
      description: null,
    };
  },
  methods: {
    async getData() {
      this.person = await this.apiGet({
        resource: "person/person",
        id: this.id,
      });
    },
    clicked($event) {
      alert("Click-Event: guck mal in der Konsole...");
      console.log($event);
    },
  },
  watch: {
    id() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
});
</script>
