<template>
  <v-container>
    <v-toolbar flat>
      <LookupValueInput
        :items="types"
        clearable
        v-model="type"
        label="Type"
      ></LookupValueInput>
    </v-toolbar>
    <v-card class="mt-2">
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="filteredItems"
        :sort-by="'code'"
        hideDefaultFooter
        disablePagination
      >
        <template v-slot:item.icon="{ item }">
          <v-icon>{{ subjectIcon(item) }}</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import LookupValueInput from "common/components/LookupValueInput.vue";
import { defineComponent } from "vue";
import { subjectIcon } from "common/utils/icons.js";
export default defineComponent({
  name: "Subjects",
  components: { LookupValueInput },
  props: ["search"],
  data() {
    return {
      loading: false,
      categories: [],
      selectedCategory: null,
      type: null,

      headers: [
        { text: "id", value: "id" },
        { text: "code", value: "code" },
        { text: "evento", value: "eventoCode" },
        { text: "short", value: "shortDescription" },
        { text: "description", value: "description" },
        { text: "type", value: "type.code" },
        { text: "icon", value: "icon" },
      ],
      items: [],
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(
        //search
        (el) => this.type == null || el.type.id == this.type.id
      );
    },
    types() {
      return [
        ...new Map(this.items.map((el) => [el.type["id"], el.type])).values(),
      ];
    },
  },
  methods: {
    subjectIcon,
  },
  async created() {
    this.loading = true;
    this.items = await this.apiList({ resource: "common/subject" });
    this.loading = false;
  },
});
</script>
