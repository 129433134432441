<template>
  <v-dialog
    max-width="700"
    width="100%"
    :value="true"
    @click:outside="close"
    @keydown.esc="close"
    scrollable
  >
    <v-card :loading="loading">
      <v-system-bar window dark class="mb-2">
        Worklog
        <v-spacer />
        <v-btn @click="close" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-card-text>
        <PersonItem :value="worklog.person">{{
          formatDate(worklog.date)
        }}</PersonItem>
        <v-divider class="mb-4"></v-divider>
        <Markdown :value="worklog.content" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" v-if="canEdit" @click="edit" text>
          Bearbeiten
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="close" text> Schliessen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import Markdown from "@/components/Markdown.vue";
import PersonItem from "common/components/PersonItem.vue";

export default defineComponent({
  name: "WorklogDetails",
  components: { Markdown, PersonItem },
  props: ["id"],
  data() {
    return { worklog: {}, loading: false };
  },
  computed: {
    canEdit() {
      return this.$_profilePerson.code === this.worklog.author;
    },
  },
  methods: {
    edit() {
      this.$router.push({
        name: "WorklogEdit",
        params: { id: this.worklog.id },
      });
    },
    close() {
      this.$router.push({
        name: "Worklogs",
      });
    },
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleDateString("de-CH", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      });
    },
  },
  async created() {
    this.loading = true;
    this.worklog = await this.apiGet({
      resource: "worklog/worklog",
      id: this.id,
    });
    this.loading = false;
  },
});
</script>
