<template>
  <div>
    <div v-if="valueFiltered.length > 2">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            :color="lessonStatusColor(valueFiltered[0])"
            class="ma-0 pa-0"
            x-small
            >{{ lessonStatusIcon(valueFiltered[0]) }}</v-icon
          >
        </template>
        <span>{{ valueFiltered[0].description }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            :color="lessonStatusColor(valueFiltered[1])"
            class="ma-0 pa-0"
            x-small
            >{{ lessonStatusIcon(valueFiltered[1]) }}</v-icon
          >
        </template>
        <span>{{ valueFiltered[1].description }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="ma-0 pa-0" x-small
            >mdi-dots-horizontal</v-icon
          >
        </template>
        <span>und weitere</span>
      </v-tooltip>
    </div>
    <div v-else>
      <v-tooltip top v-for="(status, index) in valueFiltered" :key="index">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            :color="lessonStatusColor(status)"
            class="ma-0 pa-0"
            x-small
            >{{ lessonStatusIcon(status) }}</v-icon
          >
        </template>
        <span>{{ status.description }}</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { lessonStatusColor, lessonStatusIcon } from "common/utils/icons.js";
export default defineComponent({
  props: {
    value: [],
  },
  computed: {
    valueFiltered() {
      return this.value.filter((el) => el.code != "asPlanned");
    },
  },
  methods: {
    lessonStatusColor,
    lessonStatusIcon,
  },
});
</script>
