<template>
  <v-card class="mb-2">
    <v-system-bar>PostalAddress</v-system-bar>
    <v-card-text>
      <p>Stellt die Addresse einer Person dar.</p>
      <v-row>
        <v-col><PostalAddress v-if="person" :person="person" /></v-col>
        <v-col
          ><v-text-field
            type="number"
            label="personId"
            outlined
            v-model="id"
          ></v-text-field
        ></v-col>
      </v-row>

      <code>&lt;PostalAddress :value="person" /&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import PostalAddress from "common/components/Person/PostalAddress";
export default defineComponent({
  name: "PostalAddressDemo",
  components: { PostalAddress },
  data() {
    return { id: 28, person: null };
  },
  methods: {
    async getData() {
      this.person = await this.apiGet({
        resource: "person/person",
        id: this.id,
      });
    },
  },
  watch: {
    id() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
});
</script>
