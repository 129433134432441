<template>
  <v-dialog v-model="shown" width="700" persistent>
    <v-card :loading="loading">
      <v-system-bar
        window
        class="mb-4"
        dark
        flat
        :color="isNew ? 'success' : 'error'"
        >{{ title }}
      </v-system-bar>

      <v-card-text>
        <v-text-field
          v-model="item.title"
          label="Titel"
          required
        ></v-text-field>
        <v-textarea
          label="Beschreibung"
          v-model="item.description"
        ></v-textarea>
        <v-dialog v-model="catDialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              readonly
              label="Kategorie"
              v-bind="attrs"
              v-on="on"
              :value="item.category ? item.category.name : 'Kategorie'"
            >
            </v-text-field>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Kategorie wählen
            </v-card-title>

            <v-treeview
              return-object
              selectable
              selection-type="independent"
              :value="[item.category]"
              @input="changeCategory"
              dense
              :items="categories"
              item-children="categories"
            >
              <template v-slot:prepend="{ open }">
                <v-icon>{{ open ? "mdi-folder-open" : "mdi-folder" }}</v-icon>
              </template>
            </v-treeview>
          </v-card>
        </v-dialog>
        <LookupValueInput
          v-model="item.visibility"
          :items="visibilities"
          label="Sichtbarkeit"
        />

        <v-text-field
          v-if="!item.fileName || isNew"
          placeholder="https://"
          label="URL (leer lassen für Datei)"
          v-model="item.url"
          clearable
        >
        </v-text-field>
        <v-text-field
          v-else
          :disabled="!item.available"
          label="Dateiname"
          v-model="item.fileName"
        >
        </v-text-field>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text color="danger" @click="trash" :disabled="isNew"
          >löschen</v-btn
        >
        <v-btn text :disabled="isNew" @click="fetchData(true)"
          >zurücksetzen</v-btn
        >
        <v-spacer />
        <v-btn text @click="close(false)">abbrechen</v-btn>

        <v-btn text color="primary" @click="save">speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
export default defineComponent({
  components: { LookupValueInput },
  props: ["catId", "docId"],
  data() {
    return {
      categories: [],
      item: {
        description: "",
      },
      isFile: true,
      fileName: "",
      uploadFile: [],
      shown: true,
      loading: false,
      divisions: [],
      types: [],
      visibilities: [],
      type: 0,
      catDialog: false,
    };
  },
  watch: {
    docId() {
      this.fetchData();
    },
  },
  computed: {
    isNew() {
      return this.docId == 0;
    },
    title() {
      let result = "";
      result += this.iFile ? "Link" : "Dokument";
      result += " ";
      result += this.isNew ? "erstellen" : "bearbeiten";
      return result;
    },
  },
  methods: {
    changeCategory(newCat) {
      if (!this.item.category) {
        this.item.category = newCat[0];
      } else {
        const newCats = newCat.filter((el) => el.id != this.item.category.id);
        if (newCats.length > 0) {
          this.item.category = newCats[0];
        }
      }
    },
    close(update) {
      this.$router.push({
        name: "Category",
        params: { catId: this.catId, update: update },
      });
    },
    async fetchData(reset) {
      this.loading = true;
      if (this.docId) {
        this.item = {};
        this.item = await this.apiGet({
          resource: "document/document",
          id: this.docId,
        });
      } else {
        this.item.category = await this.apiGet({
          resource: "document/category",
          id: this.catId,
        });
      }
      if (!reset) {
        this.visibilities = await this.apiList({
          resource: "document/visibility",
        });
        const cats_plain = (
          await this.apiList({
            resource: "document/category",
            query: "raw",
          })
        ).sort((a, b) => a.name.localeCompare(b.name));

        let map = {},
          node,
          res = [],
          i;
        for (i = 0; i < cats_plain.length; i += 1) {
          map[cats_plain[i].id] = i;
          cats_plain[i].categories = [];
        }

        for (i = 0; i < cats_plain.length; i += 1) {
          node = cats_plain[i];
          if (node.parent !== 0) {
            cats_plain[map[node.parent]].categories.push(node);
          } else {
            res.push(node);
          }
        }
        this.categories = res;
      }
      this.loading = false;
    },
    fileInput(file) {
      this.fileName = file.name;
    },
    async save() {
      if (this.isNew) {
        this.item = await this.apiPost({
          resource: "document/document",
          data: this.item,
        });
      } else {
        await this.apiPut({ resource: "document/document", data: this.item });
        await this.fetchData(true);
      }
      if (this.item.available) {
        this.close(true);
      } else {
        this.$router.push({
          name: "DocumentUpload",
          params: { docId: this.item.id },
        });
      }
    },
    async trash() {
      await this.apiDelete({
        resource: "document/document",
        id: this.item.id,
      });
      this.close(true);
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
