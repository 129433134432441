<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-btn icon>
        <v-icon>mdi-guy-fawkes-mask</v-icon>
      </v-btn>
      <v-toolbar-title>Imitieren</v-toolbar-title>
    </v-app-bar>
    <v-alert type="warning" light prominent border="left"
      ><span style="color: black"
        >Die Imitierung wird auf dem Server {{ server }} gesetzt!</span
      ></v-alert
    >
    <RowItem
      :title="item.application.description"
      v-for="item in items"
      v-bind:key="item.application.id"
    >
      <PersonPicker
        @input="update(item.application.id, item.person)"
        group="account"
        v-model="item.person"
      />
    </RowItem>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import PersonPicker from "@/components/PersonPicker";
import RowItem from "@/components/RowItem";
export default defineComponent({
  name: "Impersonation",
  components: {
    PersonPicker,
    RowItem,
  },
  data() {
    return {
      server: "",
      items: [],
    };
  },
  async created() {
    this.items = await this.apiList({ resource: "account/impersonation" });
    this.server = this.backendServer()
      .split("//")[1]
      .split(".")[0]
      .split(":")[0];
  },
  methods: {
    async update(applicationId, person) {
      const data = { application: applicationId };
      if (person) data.person = person.id;
      await this.apiPost({ resource: "account/impersonation", data: data });
    },
  },
});
</script>
