<template>
  <div>
    <v-alert
      v-if="version"
      prominent
      dark
      icon="mdi-information-variant"
      border="left"
      color="info"
    >
      Stundenplan {{ code }}, {{ term.fullText }} – Version
      {{ version.number }},
      <DateValue :value="version.day" />
      {{ version.time }}
    </v-alert>
    <v-card>
      <v-container>
        <v-row>
          <v-col></v-col>
          <v-col v-for="day in days" :key="day.id" class="font-weight-bold">
            {{ day.code }}
          </v-col>
        </v-row>
        <v-row
          v-for="(period, index) in periods"
          :key="period.id"
          :class="index % 2 == 0 ? 'grey lighten-4' : ''"
        >
          <v-col>{{ period.startTime }} &ndash; {{ period.endTime }}</v-col>
          <v-col v-for="day in days" :key="day.id">
            <span v-for="(lesson, i) in getLessons(day, period)" :key="i">
              <Lesson :type="type" :value="lesson"></Lesson>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import Lesson from "./components/Lesson.vue";
export default defineComponent({
  name: "TimetableTable",
  components: {
    DateValue,
    Lesson,
  },
  props: ["code", "term"],
  data() {
    return {
      days: [],
      lessons: {},
      periods: [],
      version: false,
      type: "",
    };
  },

  watch: {
    code() {
      this.fetchData();
    },
  },
  async mounted() {
    this.days = await this.apiList({ resource: "common/dayofweek" });
    this.periods = await this.apiList({ resource: "common/period" });
    this.clearTimetable();
    this.fetchData();
  },
  methods: {
    clearTimetable() {
      this.lessons = {};
      this.version = false;
      this.days.forEach((day) => {
        const dayMap = {};
        this.periods.forEach((period) => {
          dayMap[period.id] = [];
        });
        this.lessons[day.id] = dayMap;
      });
    },
    async fetchData() {
      this.clearTimetable();
      const timetable = await this.apiList({
        resource: "course/timetable",
        query: `code=${this.code}`,
      });
      this.type = timetable.type;
      this.version = timetable.version;
      timetable.lessons.forEach((lesson) => {
        this.getLessons(lesson.day, lesson.period).push(lesson);
      });
      this.$forceUpdate();
    },
    getLessons(day, period) {
      const dayMap = this.lessons[day.id];
      if (!dayMap) return [];
      const lessons = dayMap[period.id];
      return lessons ? lessons : [];
    },
  },
});
</script>
