<template>
  <v-container>
    <TermTabs v-model="term" />
    <v-list>
      <Course
        v-for="course in courses"
        v-bind:key="course.id"
        :value="course"
        clickable
        nodetails
        :to="{ name: 'Course', params: { id: course.id } }"
      />
    </v-list>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { saveChildRoute } from "@/router/helper";
import Course from "@/components/Course.vue";
import TermTabs from "@/components/TermTabs";

export default defineComponent({
  name: "SchoolClassCourses",
  components: {
    Course,
    TermTabs,
  },
  props: {
    id: { type: String },
  },
  computed: {
    schoolClassId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      courses: [],
      term: null,
      loading: false,
    };
  },
  methods: {
    async getData() {
      if (this.schoolClassId && this.term) {
        this.loading = true;
        this.courses = await this.apiList({
          resource: "course/course",
          query: `schoolClass=${this.schoolClassId}&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
        });
        this.loading = false;
      }
    },
  },
  watch: {
    async id() {
      await this.getData();
    },
    async term() {
      await this.getData();
    },
  },
  async created() {
    await this.getData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
});
</script>
