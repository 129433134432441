<template>
  <v-container>
    <PeopleSort @update="fetchData" />
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.student="{ item }">
        <PersonItem small hideExt :value="item.student" />
      </template>
      <template v-slot:item.lockers="{ item }">
        <v-chip
          v-for="locker in item.lockers"
          :key="`locker${locker.id}`"
          :to="{ name: 'LockerDetails', params: { id: locker.id } }"
          >{{ locker.code }}</v-chip
        >
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { saveChildRoute } from "@/router/helper";
import { comparePeople } from "common/utils/people.js";
import PeopleSort from "@/components/settings/PeopleSort";
import PersonItem from "common/components/PersonItem";

export default defineComponent({
  name: "SchoolClassLocker",
  components: {
    PersonItem,
    PeopleSort,
  },
  props: {
    id: { type: String },
  },
  computed: {
    schoolClassId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      data: {},
      items: [],
      loading: false,
      headers: [
        { text: "Schüler*in", value: "student" },
        { text: "Kommentar", value: "comment" },
        { text: "aktueller Schrank", value: "lockers" },
      ],
    };
  },
  methods: {
    async fetchData() {
      if (this.schoolClassId) {
        this.loading = true;
        this.items = await this.apiGet({
          resource: "schoolclass/locker",
          id: this.schoolClassId,
        });
        this.sortPeople();
        this.loading = false;
      }
    },
    sortPeople() {
      this.items = this.items.slice().sort((first, second) => {
        return comparePeople(first.student, second.student);
      });
    },
  },
  watch: {
    async id() {
      await this.fetchData();
    },
  },
  async created() {
    await this.fetchData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
});
</script>
