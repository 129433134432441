<template>
  <v-card tile>
    <v-system-bar color="warning" window
      >Ferien <v-spacer></v-spacer
      ><v-btn small icon @click="$emit('close')"
        ><v-icon small>mdi-close</v-icon></v-btn
      ></v-system-bar
    >
    <v-card-text class="title pt-8 pb-0 text-center">
      {{ value.name }}
    </v-card-text>
    <v-card-text class="subtitle pt-0 text-center">
      {{ dateSpan }}
    </v-card-text>

    <v-card-text class="display text-center">
      <v-avatar color="warning">
        <v-icon color="white">{{ icon }}</v-icon>
      </v-avatar>
    </v-card-text>
    <v-card-text class="subtitle pt-0 text-center">
      {{ timespan }}
    </v-card-text>

    <v-divider />
    <v-card-actions>
      <v-btn block text color="primary" @click="$emit('close')">
        Schliessen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import { formatDatespan, formatTimespan } from "common/utils/date.js";
import { holidayIcon } from "common/utils/icons.js";
export default defineComponent({
  name: "HolidaySheet",
  props: {
    value: {},
  },
  data() {
    return {};
  },
  computed: {
    dateSpan() {
      return formatDatespan(this.value.startDate, this.value.endDate, true);
    },
    icon() {
      return holidayIcon(this.value, true);
    },
    timespan() {
      return formatTimespan(
        this.value.startDate,
        this.value.startTime,
        this.value.endDate,
        this.value.endTime
      );
    },
  },
});
</script>

<style scoped>
.current {
  border-left: 3px solid red;
}
.non-current {
  border-left: 3px solid transparent;
}
</style>
