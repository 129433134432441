<template>
  <v-card class="mb-2">
    <v-system-bar>DateInput</v-system-bar>
    <v-card-text>
      <p>Stellt ein Json-Datum in normalen Format dar.</p>
      <v-row>
        <v-col><DateInput v-model="date" label="Datum auswählen" /></v-col>
      </v-row>

      <code>&lt;DateInput v-model="date" label="Datum auswählen" /&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import DateInput from "common/components/DateInput.vue";

export default defineComponent({
  name: "DateInputDemo",
  components: { DateInput },
  data() {
    return {
      date: "",
    };
  },
});
</script>
