<template>
  <div>
    <label class="text-caption" v-if="label"> {{ label }} </label>
    <v-btn-toggle
      :value="value"
      :disabled="!itemsComputed || itemsComputed.length < 1 || $attrs.disabled"
      :item-text="code ? 'code' : 'description'"
      item-value="id"
      @change="select"
      return-object
      dense
      v-bind="$attrs"
      class="d-flex justify-center"
      :color="color"
    >
      <v-btn
        v-for="item in itemsComputed"
        :key="item.id"
        text
        :value="item"
        return-object
        class="flex-grow-1"
        :disabled="$attrs.disabled"
      >
        {{ code ? item.code : item.description }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "LookupValueBtnToggle",
  props: {
    code: { type: Boolean, default: false },
    color: { type: String, default: "primary" },
    value: { type: Object, default: null },
    label: { type: String, default: "" },
    items: { type: Array, default: null },
    resource: { type: String, default: "" },
    query: { type: String, default: "" },
  },
  data() {
    return {
      itemsFromURL: null,
    };
  },
  computed: {
    parentSlots() {
      return Object.keys(this.$slots);
    },
    itemsComputed() {
      return this.items ? this.items : this.itemsFromURL;
    },
  },
  methods: {
    select($event) {
      this.$emit("input", $event);
    },
  },
  async created() {
    if (this.resource) {
      this.itemsFromURL = await this.apiList({
        resource: this.resource,
        query: this.query,
      });
    }
  },
});
</script>
