<template>
  <v-menu ref="menu" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-chip :color="color" v-bind="attrs" v-on="on">
        <span v-if="value">{{ value }}</span>
        <v-icon small v-if="!value">mdi-calendar-clock</v-icon>
      </v-chip>
    </template>
    <v-time-picker
      format="24hr"
      color="primary"
      :header-color="color"
      v-model="currentValue"
      @click:minute="close"
    >
    </v-time-picker>
  </v-menu>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: ["color", "value"],
  data() {
    return {
      currentValue: this.value,
    };
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
    currentValue() {
      this.$emit("input", this.currentValue);
    },
  },
  methods: {
    close() {
      this.$refs.menu.save();
      this.$emit("change", this.currentValue);
    },
  },
});
</script>
