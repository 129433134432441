<template>
  <v-card tile :loading="loading">
    <v-system-bar color="error" window
      >Person <v-spacer></v-spacer
      ><v-btn small icon @click="close"
        ><v-icon small>mdi-close</v-icon></v-btn
      ></v-system-bar
    >
    <v-card-text class="pa-0 ma-0">
      <v-card-text class="title pt-8 pb-0 text-center">
        <PersonName :value="value" />
      </v-card-text>
      <v-card-text class="subtitle pt-0 text-center">
        {{ personCode(value) }}
      </v-card-text>

      <v-card-text class="display text-center">
        <v-avatar :size="$vuetify.breakpoint.xs ? 50 : 120">
          <PortraitImage :large="!$vuetify.breakpoint.xs" :value="value" />
        </v-avatar>
        <br />
      </v-card-text>
    </v-card-text>
    <v-divider />

    <v-list subheader>
      <v-subheader>Links</v-subheader>
      <v-list-item
        @click="close"
        :to="{
          name: 'Person',
          params: {
            id: value.id.toString(),
          },
        }"
      >
        <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Person anzeigen </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="value.schoolClass"
        @click="close"
        :to="{
          name: 'SchoolClass',
          params: {
            id: value.schoolClass.id.toString(),
          },
        }"
      >
        <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Klasse anzeigen </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-subheader>Aktionen</v-subheader>
      <v-list-item
        :href="'tel:' + student.phoneHome"
        v-if="student && student.phoneHome"
      >
        <v-list-item-icon><v-icon>mdi-phone</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>anrufen (zu Hause)</v-list-item-subtitle>
          <v-list-item-title>{{ student.phoneHome }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :href="'tel:' + student.phoneMobile"
        v-if="student && student.phoneMobile"
      >
        <v-list-item-icon><v-icon>mdi-cellphone</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>anrufen (Handy)</v-list-item-subtitle>
          <v-list-item-title>{{ student.phoneMobile }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :href="mailto(student.emailSchool)"
        target="_blank"
        v-if="student && student.emailSchool"
      >
        <v-list-item-icon><v-icon>mdi-email</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>E-Mail schreiben</v-list-item-subtitle>
          <v-list-item-title>{{ student.emailSchool }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider />
    <v-card-actions>
      <v-btn @click="close" color="primary" text block>schliessen</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import { personCode } from "common/utils/people";
import { mailto } from "common/utils/helper.js";
import PersonName from "common/components/PersonName";
import PortraitImage from "common/components/PortraitImage.vue";
export default defineComponent({
  components: {
    PersonName,
    PortraitImage,
  },
  props: {
    flags: { type: Boolean, default: false },
    hideExt: { type: Boolean, default: false },
    value: null,
  },
  data() {
    return {
      sheet: false,
      student: null,
      loading: false,
      chips: [
        {
          text: "BI",
          description: "zweisprachiger Unterricht",
          value: "bilingual",
        },
        {
          text: "KU",
          description: "Kunstfach",
          value: "artsSubject",
        },
        {
          text: "S3",
          description: "Dritte Sprache",
          value: "thirdLanguage",
        },
        {
          text: "SF",
          description: "Schwerpunktfach",
          value: "majorSubject",
        },
        {
          text: "SF+",
          description: "Schwerpunktfach plus",
          value: "majorSubjectPlus",
        },
        {
          text: "EF",
          description: "Ergänzungsfach",
          value: "complementarySubject",
        },
      ],
    };
  },
  watch: {
    async value() {
      this.fetchData();
    },
  },
  computed: {
    showChips() {
      return (
        this.student &&
        this.student.info &&
        this.chips.some((el) => this.student.info[el.value])
      );
    },
    showAbsences() {
      if (!this.value.schoolClass) return false;
      return this.$_classTeacherOf
        .map((el) => el.code)
        .includes(this.value.schoolClass.code);
    },
  },
  methods: {
    mailto,
    personCode,
    close() {
      this.$emit("close");
    },
    async fetchData() {
      if (this.value) {
        this.loading = true;
        this.student = await this.apiGet({
          resource: "person/person",
          id: this.value.id,
        });
        this.loading = false;
      } else {
        this.student = null;
      }
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
