<template>
  <span>
    <v-tooltip
      top
      v-for="(items, icon) in dutyFlagMap"
      :key="'duty' + value.id + icon"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" v-on="on" right>{{ icon }}</v-icon>
      </template>
      <span>{{ items.map((el) => el.description).join(", ") }} </span>
    </v-tooltip>
    <v-tooltip
      top
      v-for="item in value.special"
      :key="'duty' + item.id + item.code"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" v-on="on" right>{{
          studentSpecialIcon(item)
        }}</v-icon>
      </template>
      <span>{{ item.description }} </span>
    </v-tooltip>
  </span>
</template>
<script>
import { defineComponent } from "vue";
import { studentDutyIcon, studentSpecialIcon } from "common/utils/icons.js";
export default defineComponent({
  name: "PersonFlags",
  props: {
    value: { type: Object },
  },
  computed: {
    dutyFlagMap() {
      const mapped = this.value.duties.map((el) => ({
        ...el,
        icon: this.studentDutyIcon(el),
      }));
      return Object.groupBy(mapped, ({ icon }) => icon);
    },
  },
  methods: {
    studentDutyIcon,
    studentSpecialIcon,
  },
});
</script>
