<template>
  <v-autocomplete
    :value="value"
    :disabled="loading"
    :items="items"
    chips
    deletable-chips
    :label="label"
    item-text="description"
    item-value="id"
    @change="select"
    v-bind="$attrs"
    return-object
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="clear"
      >
        {{ data.item.description }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.description }} </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "InventoryDeviceTypePicker",
  props: {
    value: Object,
    label: {
      type: String,
      default: "Art",
    },
    nullValue: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      items: [],
    };
  },
  methods: {
    async getData() {
      this.loading = true;
      this.items = await this.apiList({ resource: "inventory/devicetype" });
      this.loading = false;
    },
    clear() {
      this.$emit("input", this.nullValue);
    },
    select($event) {
      this.$emit("input", $event);
    },
  },
  async created() {
    this.getData();
  },
});
</script>
