<template>
  <v-card>
    <v-system-bar color="intern">
      Letzte Änderungen<v-spacer /> {{ this.itemsFiltered.length }} Einträge
    </v-system-bar>
    <ChangeLogTable
      :items="itemsFiltered"
      :showPerson="true"
      :items-per-page="50"
    />
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import { searchPerson } from "common/utils/people.js";
import ChangeLogTable from "@/views/Automation/components/ChangeLogTable.vue";

export default defineComponent({
  name: "ChangeLog",
  props: ["search"],
  data() {
    return {
      items: [],
    };
  },
  components: {
    ChangeLogTable,
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          !this.search ||
          searchPerson(el.person, this.search) ||
          (el.type &&
            el.type.description
              .toLowerCase()
              .includes(this.search.toLowerCase())) ||
          (el.description &&
            el.description.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
  },
  async created() {
    this.items = await this.apiList({
      resource: "automation/changelog",
      query: "recent",
    });
  },
});
</script>
