<template>
  <div>
    <v-toolbar flat class="mb-4"
      >{{ itemsFiltered.length }} versteckte Portfolios<v-spacer /><v-btn
        color="error"
        text
        @click="makeAllVisible"
        >alle sichtbar machen</v-btn
      ></v-toolbar
    >
    <v-card>
      <v-data-table
        :headers="headers"
        :items="itemsFiltered"
        :items-per-page="-1"
        :loading="loading"
      >
        <template v-slot:item.creation="{ item }">
          <DateValue :value="item.creationDate" />, {{ item.creationTime }}
        </template>
        <template v-slot:item.student="{ item }">
          <PersonItem small :value="item.student" />
        </template>
        <template v-slot:item.course="{ item }">
          <Course :value="item.course" />
        </template>
        <template v-slot:item.creator="{ item }">
          <PersonItem small :value="item.creator" />
        </template>
        <template v-slot:item.hidden="{ item }">
          <v-tooltip top v-if="item.hidden">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="makeVisible(item)"
                icon
                v-bind="attrs"
                v-on="on"
                :loading="item.loading"
                ><v-icon>mdi-eye-off</v-icon></v-btn
              >
            </template>
            <span>Versteckt bis freigeschaltet wird</span>
          </v-tooltip>
        </template>
        <template v-slot:item.archived="{ item }">
          <v-tooltip top v-if="item.archived">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"><v-icon>mdi-archive</v-icon></span>
            </template>
            <span>Archiviert</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Course from "@/components/Course.vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";
export default defineComponent({
  name: "PortfolioList",
  props: ["search"],
  components: { Course, DateValue, PersonItem },
  data: () => ({
    headers: [
      { text: "erstellt", value: "creation" },
      { text: "Typ", value: "type.description", sortable: false },
      { text: "für", value: "student", sortable: false },
      { text: "in", value: "course", sortable: false },
      { text: "von", value: "creator", sortable: false },
      { text: "", value: "archived" },
      { text: "", value: "hidden" },
    ],
    items: [],
    loading: false,
  }),
  computed: {
    itemsFiltered() {
      return this.items.filter((item) => item.hidden);
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const portflios = await this.apiList({
        resource: "portfolio/portfolio",
        query: "active",
      });
      portflios.forEach((element) => {
        element.loading = false;
      });
      this.items = portflios;
      this.loading = false;
    },
    async putVisible(item) {
      item.loading = true;
      await this.apiPatch({
        resource: "portfolio/portfolio",
        id: item.id,
        key: "hidden",
        value: false,
      });
      item.loading = false;
      item.hidden = false;
    },
    async makeVisible(item) {
      if (
        await this.$root.confirm({
          message: `Soll das Portfolio «${item.title}» für ${item.student.firstName} ${item.student.lastName} sichtbar gemacht werden?`,
          color: "error",
          icon: "mdi-eye",
        })
      ) {
        await this.putVisible(item);
      }
    },
    async makeAllVisible() {
      if (
        await this.$root.confirm({
          message:
            "Sollen alle nicht-sichtbaren Portfolios für die SuS sichtbar gemacht werden?",
          color: "error",
          icon: "mdi-eye",
        })
      ) {
        for (const item of this.itemsFiltered) {
          await this.putVisible(item);
        }
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
