<template>
  <v-card class="mb-2">
    <v-system-bar>TimeInput</v-system-bar>
    <v-card-text>
      <p>Stellt eine Json-Zeit in normalen Format dar.</p>
      <v-row>
        <v-col><TimeInput v-model="time" label="Zeit auswählen" /></v-col>
      </v-row>

      <code>&lt;TimeInput v-model="time" label="Zeit auswählen" /&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import TimeInput from "common/components/TimeInput.vue";

export default defineComponent({
  name: "TimeInputDemo",
  components: { TimeInput },
  data() {
    return {
      time: "",
    };
  },
});
</script>
