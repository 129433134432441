<template>
  <v-card tile>
    <v-system-bar color="success" window
      >Termin <v-spacer></v-spacer
      ><v-btn small icon @click="$emit('close')"
        ><v-icon small>mdi-close</v-icon></v-btn
      ></v-system-bar
    >
    <v-card-text class="title pt-8 pb-0 text-center">
      {{ value.description }}
    </v-card-text>

    <v-card-text class="subtitle pt-0 text-center">
      <strong v-if="value.division">{{ value.division.code }}: </strong
      >{{ value.participantsText }}
      <span class="red--text" v-if="value.cancelText"
        >Unterrichtsausfall {{ value.cancelText }}</span
      >
    </v-card-text>

    <v-card-text class="display text-center">
      <v-avatar :color="color">
        <v-icon color="white">{{ icon }}</v-icon>
      </v-avatar>
    </v-card-text>
    <v-alert
      class="mb-0"
      text
      color="danger"
      icon="mdi-alert"
      v-if="value.canceled"
    >
      Abgesagt
    </v-alert>
    <v-alert
      class="mb-0"
      text
      color="error"
      icon="mdi-alert"
      v-if="value.changed"
    >
      <strong>Geändert</strong> – dieser Termin wurde nach Semesterbeginn
      erfasst/abgeändert
    </v-alert>
    <v-list>
      <v-list-item>
        <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title :class="value.canceled ? 'canceled' : ''">{{
            dateSpan
          }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ timespan }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            small
            outlined
            text
            color="info"
            v-if="$root.settings.showIcal"
            @click="apiIcal('event', value.id)"
            class="ml-2"
            ><v-icon left>mdi-calendar-import</v-icon> iCal</v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon
          ><v-icon>mdi-account-multiple</v-icon></v-list-item-icon
        >

        <v-list-item-content>
          <v-list-item-title>Sichtbarkeit</v-list-item-title>
          <v-list-item-subtitle>
            {{ value.staffOnly ? "Lehrkräfte" : "Schüler:innen" }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        @click="
          copyToClipboard(`https://intern.gymkirchenfeld.ch/event/${value.id}`)
        "
      >
        <v-list-item-icon><v-icon>mdi-content-copy</v-icon></v-list-item-icon>
        <v-list-item-content
          >Link zum Termin in Zwischenablage kopieren
        </v-list-item-content></v-list-item
      >
    </v-list>
    <v-divider />
    <v-card-actions>
      <v-btn block text color="primary" @click="$emit('close')">
        Schliessen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import { formatDatespan, formatTimespan } from "common/utils/date.js";
import { eventColor, eventIcon } from "common/utils/icons.js";
import { copyToClipboard } from "common/utils/helper.js";
export default defineComponent({
  name: "EventSheet",
  props: {
    value: {},
  },
  data() {
    return {};
  },
  computed: {
    color() {
      return eventColor(this.value);
    },
    dateSpan() {
      return formatDatespan(this.value.startDate, this.value.endDate, true);
    },
    icon() {
      return eventIcon(this.value, true);
    },
    timespan() {
      return formatTimespan(
        this.value.startDate,
        this.value.startTime,
        this.value.endDate,
        this.value.endTime
      );
    },
  },
  methods: {
    copyToClipboard,
  },
});
</script>

<style scoped>
.current {
  border-left: 3px solid red;
}
.non-current {
  border-left: 3px solid transparent;
}
.canceled {
  text-decoration-line: line-through;
}
</style>
