<template>
  <span v-if="value">
    {{ value }}
    <span v-if="isInternal">(Intern {{ internal }})</span>
    <v-btn :href="'tel:' + value" icon color="primary"
      ><v-icon small>mdi-phone</v-icon></v-btn
    >
    <CopyButton :value="value" />
  </span>
  <span v-else>{{ placeholder }}</span>
</template>
<script>
import { defineComponent } from "vue";
import CopyButton from "common/components/CopyButton.vue";
export default defineComponent({
  name: "PhoneNumber",
  components: { CopyButton },
  props: {
    value: { type: String },
    placeholder: { type: String, default: "—" },
  },
  computed: {
    isInternal() {
      return this.value && this.value.startsWith("031 359 2");
    },
    internal() {
      return this.value ? this.value.substring(9).replace(" ", "") : "";
    },
  },
});
</script>
