<template>
  <div>
    <v-btn
      fab
      absolute
      right
      small
      color="success"
      :to="{ name: 'DocumentEdit', params: { docId: 0, catId: catId } }"
      ><v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.id ? { name: 'Category', params: { catId: item.id } } : ''"
        >
          {{ item.name }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <v-card :loading="loading" class="mb-4">
      <v-card-title
        >{{ item.name }}
        <v-spacer />
        <v-chip>
          {{ item.documentsCount }}
        </v-chip>
      </v-card-title>
      <DocumentList :documents="item.documents" />
    </v-card>
    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DocumentList from "./DocumentList.vue";
export default defineComponent({
  components: { DocumentList },
  props: ["catId"],
  data() {
    return {
      item: {},
      search: "",
      loading: false,
    };
  },
  watch: {
    catId() {
      this.fetchData();
    },
  },
  computed: {
    home() {
      return { name: "Kategorien" };
    },
    breadcrumbs() {
      if (!this.item) return [this.home];
      if (!this.item.ancestors) return [this.home, this.item];
      return [this.home, ...this.item.ancestors, this.item];
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.item = {};
      this.item = await this.apiGet({
        resource: "document/category",
        id: this.catId,
      });
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.update) {
      this.fetchData();
    }
    next();
  },
});
</script>
