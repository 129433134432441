<template>
  <v-bottom-sheet v-model="sheet" inset max-width="500" v-if="value" scrollable>
    <template v-slot:activator="{ on }">
      <PersonChip
        v-if="small"
        v-bind="$attrs"
        v-on="on"
        :value="value"
        :hideExt="hideExt"
        :flags="flags"
      >
        <slot></slot>
      </PersonChip>

      <PersonItemBasic
        v-else
        v-bind="$attrs"
        v-on="on"
        :value="value"
        :hideExt="hideExt"
        :flags="flags"
        ><slot></slot>
      </PersonItemBasic>
    </template>
    <PersonSheet
      :value="value"
      @close="sheet = false"
      v-bind:attrs="$attrs"
      :hideExt="hideExt"
      :flags="flags"
    />
  </v-bottom-sheet>
</template>
<script>
import { defineComponent } from "vue";
import { personCode } from "common/utils/people";
import { mailto } from "common/utils/helper.js";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
import PersonChip from "common/components/PersonChip.vue";
import PersonSheet from "@/components/PersonSheet.vue";
export default defineComponent({
  components: {
    PersonItemBasic,
    PersonChip,
    PersonSheet,
  },
  props: {
    flags: { type: Boolean, default: false },
    hideExt: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    value: null,
  },
  data() {
    return {
      sheet: false,
      loading: false,
    };
  },
  methods: {
    mailto,
    personCode,
    close() {
      this.sheet = false;
    },
  },
});
</script>
