<template>
  <v-container>
    <v-toolbar dense flat>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="getData">aktualisieren</v-btn>
    </v-toolbar>

    <JobsTable
      :items="items.filter((el) => el.frequency.code == 'hourly')"
      title="Stündlich"
      icon="mdi-clock-outline"
      :loading="loading"
      class="mb-4"
      @runTask="runTask"
      ><v-alert type="info" text>
        <v-row align="center">
          <v-col class="grow"
            >Diese Automatisierungen werden jede volle Stunde gestartet. Mit dem
            Knopf rechts können sie jederzeit neu gestartet werden.
          </v-col>
          <v-col class="shrink">
            <v-btn color="primary" outlined small @click="requestRestart"
              >Starten</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </JobsTable>

    <JobsTable
      :items="items.filter((el) => el.frequency.code == 'daily')"
      title="Täglich"
      icon="mdi-calendar-month-outline"
      :loading="loading"
      class="mb-4"
      @runTask="runTask"
      ><v-alert type="info" text>
        Diese Automatisierungen werden nur einmal pro Tag nach Mitternacht
        ausgeführt.</v-alert
      ></JobsTable
    >

    <JobsTable
      :items="items.filter((el) => el.frequency.code == 'manual')"
      title="Manuell"
      icon="mdi-hand-back-left-outline"
      :loading="loading"
      class="mb-4"
      @runTask="runTask"
      ><v-alert type="info" text
        >Diese Automatisierungen werden nur manuell gestartet.</v-alert
      >
    </JobsTable>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import JobsTable from "./JobsTable.vue";

export default defineComponent({
  name: "JobsList",
  components: { JobsTable },
  data: () => ({
    loading: false,
    items: [],
  }),
  methods: {
    async getData() {
      this.loading = true;
      this.items = await this.apiList({ resource: "automation/job" });
      this.loading = false;
    },
    async requestRestart() {
      if (
        await this.$root.confirm({
          message: `Sollen die stündlichen Automatisierungen neu gestartet werden?`,
          color: "warning",
          icon: "mdi-information",
        })
      ) {
        this.apiPost({
          resource: "automation/job",
          data: {
            start: true,
          },
        });
        this.$root.showSuccess("Neustart beantragt!");
        this.getData();
      }
    },
    async runTask(task) {
      if (
        await this.$root.confirm({
          message: `Soll die Automatisierung «${task.name}» manuell gestartet werden?`,
          color: "warning",
          icon: "mdi-information",
        })
      ) {
        await this.apiPatch({
          resource: "automation/job",
          id: task.id,
          key: "start",
          value: true,
        });
        this.$root.showSuccess(
          `Automatisierung «${task.name}» wird bald gestartet!`
        );
        this.getData();
      }
    },
  },
  async created() {
    this.getData();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "JobsList") {
      this.getData();
    }
    next();
  },
});
</script>
