<template>
  <v-container ref="containerPreview">
    <v-responsive :aspect-ratio="16 / 9" v-resize="updateSize">
      <div id="wrap">
        <iframe :style="style" id="frame" :src="previewUrl" />
      </div>
    </v-responsive>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ScreenPreview",
  components: {},
  data() {
    return {
      date: null,
      time: null,
      scale: 0.1,
    };
  },
  props: ["id"],
  methods: {
    updateSize() {
      var computed = getComputedStyle(this.$refs.containerPreview);
      const width =
        this.$refs.containerPreview.offsetWidth -
        parseInt(computed.paddingLeft) -
        parseInt(computed.paddingRight);
      this.scale = width / 1920;
    },
  },

  computed: {
    style() {
      return { transform: "scale(" + this.scale + ")" };
    },
    previewUrl() {
      var base = "https://screen.gymkirchenfeld.ch/";
      if (!this.apiIsProd) {
        base = "https://screen2.gymkirchenfeld.ch/";
      }
      return base;
    },
  },
  updated() {
    this.updateSize();
  },
});
</script>

<style scoped>
#wrap {
  overflow: hidden;
  position: absolute;
}
#frame {
  width: 1920px;
  height: 1080px;
  border: 3px solid black;
}
#frame {
  transform: scale(0.1);
  transform-origin: left top;
}
</style>
