<template>
  <v-container>
    <p>
      Hier sollen die zur Verfügung stehenden globalen Komponenten dokumentiert
      werden. Diese können per Javascript über die root-Instanz von Vue
      aufgerufen werden
    </p>
    <ConfirmDialogDemo v-if="visible('ConfirmDialog')" />
    <NotificationDemo v-if="visible('Notification')" />
    <PromptDialogDemo v-if="visible('PromptDialogDemo')" />
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import ConfirmDialogDemo from "@/components/demos/ConfirmDialogDemo";
import NotificationDemo from "@/components/demos/NotificationDemo";
import PromptDialogDemo from "@/components/demos/PromptDialogDemo";
export default defineComponent({
  name: "DocumentationGlobal",
  components: {
    ConfirmDialogDemo,
    NotificationDemo,
    PromptDialogDemo,
  },
  props: ["search"],
  methods: {
    visible(name) {
      return (
        !this.search || name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
});
</script>
