<template>
  <v-container>
    <p>
      Hier sollen die zur Verfügung stehenden Picker- und Input-Komponenten
      dokumentiert werden
    </p>

    <CourseInputDemo v-if="visible('CourseInput')" />
    <DatePickerDemo v-if="visible('DatePicker')" />
    <DateInputDemo v-if="visible('DateInput')" />
    <TimePickerDemo v-if="visible('TimePicker')" />
    <TimeInputDemo v-if="visible('TimeInput')" />
    <LookupValueInputDemo v-if="visible('LookupValueInput')" />
    <PersonPickerDemo v-if="visible('PersonPicker')" />
    <PersonInputDemo v-if="visible('PersonInput')" />
    <SchoolClassInputDemo v-if="visible('SchoolClassInput')" />
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import CourseInputDemo from "@/components/demos/CourseInputDemo";
import DatePickerDemo from "@/components/demos/DatePickerDemo";
import DateInputDemo from "@/components/demos/DateInputDemo";
import LookupValueInputDemo from "@/components/demos/LookupValueInputDemo";
import PersonInputDemo from "@/components/demos/PersonInputDemo";
import PersonPickerDemo from "@/components/demos/PersonPickerDemo";
import SchoolClassInputDemo from "@/components/demos/SchoolClassInputDemo";
import TimeInputDemo from "@/components/demos/TimeInputDemo";
import TimePickerDemo from "@/components/demos/TimePickerDemo";
export default defineComponent({
  name: "DocumentationForms",
  components: {
    CourseInputDemo,
    DatePickerDemo,
    DateInputDemo,
    LookupValueInputDemo,
    PersonInputDemo,
    PersonPickerDemo,
    SchoolClassInputDemo,
    TimeInputDemo,
    TimePickerDemo,
  },
  props: ["search"],
  methods: {
    visible(name) {
      return (
        !this.search || name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
});
</script>
