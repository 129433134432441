<template>
  <v-dialog max-width="1200px" width="100%" :value="true" persistent scrollable>
    <v-card>
      <v-system-bar window :color="color" class="mb-2">
        Gerät hinzufügen
        <v-spacer />
        <v-btn @click="close" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-card-text v-if="device">
        <v-container>
          <v-row cols="5"
            ><v-col
              ><v-card class="mb-4">
                <v-system-bar>Allgemein</v-system-bar>
                <v-list dense>
                  <v-list-item>
                    <InventoryBrandModelPicker v-model="device.brandModel" />
                  </v-list-item>
                  <v-list-item>
                    <InventoryDeviceTypePicker v-model="device.deviceType" />
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      v-model="device.name"
                      label="Name"
                    ></v-text-field>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-text-field
                      v-model="device.productNumber"
                      label="Produktnummer"
                    ></v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      v-model="device.serialNumber"
                      label="Seriennummer"
                    ></v-text-field>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <RoomPicker v-model="device.location" />
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      v-model="device.serialNumber"
                      label="Seriennummer"
                    ></v-text-field>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card class="mb-4">
                <v-system-bar><v-icon>mdi-wifi</v-icon>WLAN</v-system-bar>
                <v-list dense>
                  <v-list-item>
                    <MacAddressInput v-model="device.macAddressWlan" />
                  </v-list-item>
                  <v-list-item>
                    <IpAddressInput v-model="device.ipv4AddressWlan" />
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card>
                <v-system-bar><v-icon>mdi-ethernet</v-icon>LAN</v-system-bar>

                <v-list dense>
                  <v-list-item>
                    <MacAddressInput v-model="device.macAddressLan" />
                  </v-list-item>
                  <v-list-item>
                    <IpAddressInput v-model="device.ipv4AddressLan" />
                  </v-list-item>
                </v-list> </v-card></v-col
            ><v-col cols="7"
              ><v-card class="mb-4">
                <v-system-bar>Kauf</v-system-bar>

                <v-list dense>
                  <v-list-item>
                    <DateInput v-model="device.invoiceDate" label="Datum" />
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      v-model="device.purchasePrice"
                      label="Kaufpreis (CHF)"
                      type="number"
                    ></v-text-field>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                    <InventoryCompanyPicker
                      v-model="device.merchant"
                      label="Händler"
                      :nullValue="{ id: 0 }"
                    />
                  </v-list-item>
                  <v-list-item>
                    <InventoryCompanyPicker
                      v-model="device.service"
                      label="Service"
                      :nullValue="{ id: 0 }"
                    />
                  </v-list-item>
                </v-list> </v-card
              ><v-card>
                <v-system-bar>Mehr</v-system-bar>
                <v-card-text>
                  <v-textarea
                    rows="5"
                    label="Kommentar"
                    v-model="device.comment"
                  ></v-textarea> </v-card-text></v-card></v-col></v-row
        ></v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close"> Abbrechen</v-btn>
        <v-btn text @click="save" color="primary"> Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import InventoryBrandModelPicker from "@/components/inventory/BrandModelPicker";
import InventoryDeviceTypePicker from "@/components/inventory/DeviceTypePicker";
import InventoryCompanyPicker from "@/components/inventory/CompanyPicker";
import RoomPicker from "@/components/RoomPicker";
import DateInput from "common/components/DateInput.vue";
import MacAddressInput from "@/components/inventory/MacAddressInput";
import IpAddressInput from "@/components/inventory/IpAddressInput";

export default defineComponent({
  name: "InventoryDeviceNew",
  props: ["id"],
  components: {
    DateInput,
    InventoryCompanyPicker,
    InventoryBrandModelPicker,
    InventoryDeviceTypePicker,
    RoomPicker,
    MacAddressInput,
    IpAddressInput,
  },
  data() {
    return {
      device: { id: 0 },
      color: "success",
      datepicker: false,
      loading: false,
      placeholders: {
        ipv4: "xxx.xxx.xxx.xxx",
        mac: "xx:xx:xx:xx:xx:xx",
      },
      rules: {
        ipv4: (value) => {
          if (!value) {
            return true;
          }
          const pattern =
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
          return pattern.test(value) || "keine korrekte IPv4-Adresse!";
        },
        mac: (value) => {
          if (!value) {
            return true;
          }
          const pattern =
            /^((([a-fA-F0-9][a-fA-F0-9]+[-]){5}|([a-fA-F0-9][a-fA-F0-9]+[:]){5})([a-fA-F0-9][a-fA-F0-9])$)|(^([a-fA-F0-9][a-fA-F0-9][a-fA-F0-9][a-fA-F0-9]+[.]){2}([a-fA-F0-9][a-fA-F0-9][a-fA-F0-9][a-fA-F0-9]))$/;
          return pattern.test(value) || "keine korrekte MAC-Adresse!";
        },
      },
    };
  },

  methods: {
    async save() {
      const data = await this.apiPost({
        resource: "inventory/device",
        data: this.device,
      });
      this.$root.showNotification(
        "Device wurde gespeichert!",
        "mdi-content-save",
        "success"
      );
      this.$router.replace({
        name: "InventoryDevice",
        params: { id: data.id },
      });
    },

    back() {
      this.$router.back();
    },
    close() {
      this.$router.push({
        name: "InventoryDevices",
      });
    },
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleDateString("de-CH", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      });
    },
  },
  async created() {
    if (this.id > 0) {
      this.loading = true;
      this.device = await this.apiGet({
        resource: "inventory/device",
        id: this.id,
      });
      this.device.id = 0;
      this.device.name = this.device.name + " copy";
      this.loading = false;
    }
  },
});
</script>
