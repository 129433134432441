export default [
  {
    path: "/objects",
    component: () =>
      import(/* webpackChunkName: "Objects" */ "@/views/Objects/"),
    children: [
      {
        path: "",
        redirect: { name: "ObjectsSubject" },
      },
      {
        name: "ObjectsSubject",
        path: "subject",
        component: () =>
          import(
            /* webpackChunkName: "ObjectsSubject" */ "@/views/Objects/Subject.vue"
          ),
      },
    ],
  },
];
