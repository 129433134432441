<template>
  <v-dialog v-model="shown" width="700" persistent>
    <v-card :loading="loading">
      <v-system-bar
        window
        class="mb-4"
        dark
        :color="item.available ? 'success' : 'error'"
        >{{ item.available ? "Datei ersetzen" : "Datei hochladen" }}
      </v-system-bar>
      <v-card-text>
        <v-file-input v-model="uploadFile" label="Datei auswählen">
        </v-file-input>

        <v-text-field label="Dateiname" v-model="item.fileName">
          <template v-slot:append-outer>
            <v-btn-toggle dense v-model="toggle">
              <v-btn
                :disabled="!uploadFile || !uploadFile.name"
                text
                @click="item.fileName = fileNameOld"
                >alt</v-btn
              >
              <v-btn
                :disabled="!uploadFile || !uploadFile.name"
                text
                @click="item.fileName = uploadFile.name"
                >neu</v-btn
              >
            </v-btn-toggle>
          </template>
        </v-text-field>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text color="danger" @click="trash" :disabled="!item.available"
          >datei löschen</v-btn
        >
        <v-spacer />
        <v-btn text @click="close(false)">abbrechen</v-btn>
        <v-btn
          :disabled="!uploadFile || !uploadFile.name"
          text
          color="primary"
          @click="upload"
          >hochladen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: ["docId"],
  data() {
    return {
      toggle: 0,
      item: {},
      fileNameOld: "",
      fileNameUpload: "",
      uploadFile: {
        name: "",
      },
      shown: true,
      loading: false,
    };
  },
  watch: {
    docId() {
      this.fetchData();
    },
    uploadFile() {
      if (this.toggle == 1) {
        this.item.fileName = this.uploadFile.name;
      }
    },
  },
  methods: {
    close(update) {
      this.$router.push({
        name: "Category",
        params: { id: this.catId, update: update },
      });
    },
    async fetchData() {
      this.loading = true;
      if (this.docId) {
        this.item = {};
        this.item = await this.apiGet({
          resource: "document/document",
          id: this.docId,
        });
        this.fileNameOld = this.item.fileName;
      }
      if (!this.item.available) {
        this.toggle = 1;
      }
      this.loading = false;
    },
    async upload() {
      await this.apiUploadPut({
        resource: "document/file",
        data: this.item,
        file: this.uploadFile,
        fileName: this.item.fileName,
      });
      this.close(true);
    },
    async trash() {
      await this.apiDelete({
        resource: "document/file",
        id: this.item.id,
      });
      this.close(true);
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
