<template>
  <v-card class="mb-2">
    <v-system-bar>SchoolClassInput</v-system-bar>
    <v-card-text>
      <p></p>
      <v-row>
        <v-col
          ><SchoolClassInput
            :label="label"
            :hint="hint"
            :hide-details="hidedetails"
            :dense="dense"
            :single-line="singleline"
            :clearable="clearable"
            :persistent-hint="persistenthint"
        /></v-col>
        <v-col>
          <v-text-field label="label" v-model="label"></v-text-field>
          <v-text-field label="hint" v-model="hint"></v-text-field>
          <v-checkbox
            v-model="clearable"
            :label="`clearable: ${clearable.toString()}`"
          ></v-checkbox>
          <v-checkbox
            v-model="persistenthint"
            :label="`persistent-hint: ${persistenthint.toString()}`"
          ></v-checkbox>
          <v-checkbox
            v-model="hidedetails"
            :label="`hide-details: ${hidedetails.toString()}`"
          ></v-checkbox>
          <v-checkbox
            v-model="singleline"
            :label="`single-line: ${singleline.toString()}`"
          ></v-checkbox>
          <v-checkbox
            v-model="dense"
            :label="`dense: ${dense.toString()}`"
          ></v-checkbox>
          <p>
            <a
              href="https://vuetifyjs.com/en/api/v-autocomplete/"
              target="_blank"
              >weitere props von v-autocomplete</a
            >
          </p>
        </v-col>
      </v-row>
      <code>&lt;SchoolClassInput" /&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import SchoolClassInput from "@/components/SchoolClassInput";
export default defineComponent({
  name: "SchoolClassInputDemo",
  components: { SchoolClassInput },
  data() {
    return {
      hidedetails: false,
      dense: false,
      clearable: false,
      singleline: false,
      persistenthint: false,
      label: "Schulklasse",
      hint: "eine Schulklasse",
      selected: {},
    };
  },
});
</script>
