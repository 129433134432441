<template>
  <v-card :loading="loading">
    <v-system-bar window class="mb-2">
      Reservation hinzüfugen <v-spacer></v-spacer>
      <v-btn @click="$emit('close')" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-system-bar>
    <v-list subheader>
      <v-list-item>
        <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            ><DateValue v-model="item.date" long></DateValue
          ></v-list-item-title>
          <v-list-item-subtitle
            >{{ item.period.startTime }}–{{
              item.period.endTime
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-subheader>verfügbare Zimmer</v-subheader>
      <v-list-item-group v-model="item.room" color="primary" mandatory>
        <v-list-item
          v-for="room in rooms"
          :key="room.id"
          :disabled="room.occupied"
          :value="room.id"
        >
          <v-list-item-icon
            ><v-icon>mdi-map-marker-plus</v-icon></v-list-item-icon
          >
          <v-list-item-content>
            <v-list-item-title>
              {{ room.description }}
            </v-list-item-title>
            <v-list-item-subtitle>{{
              room.site.description
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-icon
          ><v-icon>mdi-comment-outline</v-icon></v-list-item-icon
        >
        <v-text-field
          v-model="item.description"
          label="Kommentar"
        ></v-text-field>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    <v-card-actions>
      <v-btn text @click="$emit('close')">abbrechen </v-btn>
      <v-spacer class="mx-8"></v-spacer>

      <v-btn
        text
        color="primary"
        @click="save"
        :loading="saving"
        :disabled="!item.room || !item.date || !item.period"
        >speichern</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";

import { overlaps } from "common/utils/date.js";
import DateValue from "common/components/DateValue.vue";

export default defineComponent({
  name: "ReservationForm",
  components: { DateValue },
  props: ["id", "defaults"],
  data() {
    return {
      item: {
        description: "",
        date: "",
        startTime: "",
        endTime: "",
        room: {},
        period: {},
      },
      loading: false,
      saving: false,
      rooms: [],
      types: [],
    };
  },
  computed: {
    selectLesson() {
      return !this.isNew || !this.item.period;
    },
  },

  watch: {
    defaults() {
      this.fetchData();
    },
  },

  methods: {
    async fetchData() {
      this.loading = true;
      const allRooms = await this.apiList({ resource: "common/room" });
      this.rooms = allRooms
        .filter((el) => el.reservable)
        .map((el) => ({ ...el, occupied: true }));

      const periods = await this.apiList({ resource: "common/period" });
      this.item.period = periods.find(
        (el) => el.startTime == this.defaults.startTime
      );

      this.item.date = this.defaults.date;
      this.item.description = this.defaults.course.code;
      this.roomsAvailable = [];
      for await (const room of this.rooms) {
        var occupied = false;
        const lessons = await this.apiList({
          resource: "register/lesson",
          query: `startDate=${this.item.date}&endDate=${this.item.date}&startTime=${this.item.period.startTime}&endTime=${this.item.period.endTime}&room=${room.id}`,
        });
        const reservations = await this.apiList({
          resource: "register/reservation",
          query: `startDate=${this.item.date}&endDate=${this.item.date}&startTime=${this.item.period.startTime}&endTime=${this.item.period.endTime}&room=${room.id}`,
        });
        for await (const lesson of lessons) {
          const periodItem = {
            startDate: this.item.date,
            endDate: this.item.date,
            ...this.item.period,
          };
          const lessonItem = {
            startDate: this.item.date,
            endDate: this.item.date,
            ...lesson,
          };
          if (overlaps(lessonItem, periodItem)) {
            occupied = true;
            break;
          }
        }
        for await (const reservation of reservations) {
          const periodItem = {
            startDate: this.item.date,
            endDate: this.item.date,
            ...this.item.period,
          };
          if (overlaps(reservation, periodItem)) {
            occupied = true;
            break;
          }
        }
        room.occupied = occupied;
      }

      this.loading = false;
    },
    async save() {
      this.saving = true;
      await this.apiSave({
        add: true,
        resource: "register/reservation",
        data: {
          room: this.item.room,
          startDate: this.item.date,
          endDate: this.item.date,
          startTime: this.item.period.startTime,
          endTime: this.item.period.endTime,
          description: this.item.description,
          reservedFor: this.$_profilePerson.id,
        },
      });
      this.saving = false;
      this.item.room = {};
      this.$emit("updated");
      this.$emit("close");
    },
  },
  created() {
    this.item = {
      room: {},
      date: this.defaults.date,
      description: this.defaults.course.code,
      period: {},
    };
    this.fetchData();
  },
});
</script>
