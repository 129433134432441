var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"max-width":"700","width":"100%","value":true,"persistent":"","scrollable":""},on:{"click:outside":function($event){return _vm.close()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.close()}}},[(_vm.locker)?_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-system-bar',{staticClass:"mb-2",attrs:{"color":"error","window":""}},[_vm._v(" Schäftli "+_vm._s(_vm.locker.code)+", "+_vm._s(_vm.subtitle)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-timeline',[_vm._l((_vm.countSpace),function(index){return _c('v-timeline-item',{key:index,attrs:{"left":"","fill-dot":"","color":"success"}},[_c('v-card',[_c('v-card-text',[_c('PersonInput',{attrs:{"label":"Schüler:in hinzufügen","group":"student","color":"success"},on:{"input":_vm.create}})],1)],1)],1)}),_vm._l((_vm.current),function(item){return _c('v-timeline-item',{key:item.id,attrs:{"left":"","fill-dot":"","color":"primary"},scopedSlots:_vm._u([{key:"opposite",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatDatespan(item.startDate, item.endDate))+" ")]},proxy:true}],null,true)},[_c('v-card',[_c('PersonItem',{attrs:{"clickable":"","nolink":"","nodetails":"","value":item.student},on:{"click":function($event){return _vm.$router.push({
                  name: 'LockerStudentDetails',
                  params: { id: item.student.id },
                })}}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.finish(item.id, item.student)}}},[_vm._v("entfernen")])],1)],1)],1)}),_c('v-timeline-item',{attrs:{"fill-dot":"","color":"error"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.showHistory = !_vm.showHistory}}},[_vm._v(_vm._s(_vm.showHistory ? "mdi-dots-vertical" : "mdi-dots-horizontal"))])]},proxy:true},{key:"opposite",fn:function(){return [_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){_vm.showHistory = !_vm.showHistory}}},[_vm._v(" "+_vm._s(_vm.showHistory ? "ältere ausblenden" : "ältere einblenden")+" ")])]},proxy:true}],null,false,502350267)}),(_vm.showHistory)?_vm._l((_vm.history),function(item){return _c('v-timeline-item',{key:item.id,attrs:{"right":"","fill-dot":"","color":"grey"},scopedSlots:_vm._u([{key:"opposite",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatDatespan(item.startDate, item.endDate))+" ")]},proxy:true}],null,true)},[_c('v-card',[_c('PersonItem',{attrs:{"value":item.student,"clickable":"","nolink":"","nodetails":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'LockerStudentDetails',
                    params: { id: item.student.id },
                  })}}})],1)],1)}):_vm._e()],2)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.prev()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v("vorheriger Eintrag")],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.next()}}},[_vm._v("nächster Eintrag"),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }