<template>
  <v-data-table
    v-model="selectedItems"
    :show-select="false"
    item-key="id"
    :loading="loading"
    :headers="headers"
    :items="items"
    sort-by="number"
    @click:row="(item) => $emit('showDetails', item.id)"
    :item-class="() => 'clickable'"
  >
    <template v-slot:item.employee="{ item }">
      <PersonItem small :value="item.employee" />
    </template>
    <template v-slot:item.date="{ item }">
      <DateValue :value="item.date" />
    </template>
    <template v-slot:item.amount="{ item }">
      <CurrencyValue :value="item.amount" />
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn icon @click.stop="$emit('showEdit', item.id)"
        ><v-icon>mdi-pen</v-icon></v-btn
      >
    </template>
  </v-data-table>
</template>
<script>
import { defineComponent } from "vue";
import CurrencyValue from "@/components/CurrencyValue.vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";

export default defineComponent({
  name: "FinanceInvoices",
  components: {
    CurrencyValue,
    DateValue,
    PersonItem,
  },
  props: {
    items: [],
    search: null,

    loading: {
      type: Boolean,
      default: false,
    },
    showEditBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      state: {},
      selectedItems: [],
      headers: [
        { text: "Nummer", value: "number" },
        { text: "Datum", value: "date" },
        { text: "Mitarbeiter:in", value: "employee" },
        { text: "Anlass", value: "event" },
        { text: "Beschreibung", value: "description" },
        { text: "Betrag", value: "amount", align: "end" },
      ],
    };
  },
  created() {
    if (this.showEditBtn) {
      this.headers.push({ text: "", value: "actions", width: 0 });
    }
  },
});
</script>
