<template>
  <div>
    <v-row class="align-center justify-center mx-2">
      <LookupValueBtnToggle
        code
        resource="common/site"
        v-model="filterSite"
        mandatory
        :disabled="loading"
      ></LookupValueBtnToggle>
      <v-spacer></v-spacer>
      <DateInput v-model="focus" class="mx-2" :disabled="loading" />

      <v-btn outlined @click="prev()" class="mx-2" :disabled="loading">
        <v-icon left>mdi-chevron-left</v-icon> vorherige Woche
      </v-btn>
      <v-btn outlined @click="next()" class="mx-2" :disabled="loading">
        nächste Woche<v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
    <v-card v-if="loading" class="mx-auto mt-8" width="400"
      ><v-progress-linear color="info" height="24" :value="progressValue">{{
        progressText
      }}</v-progress-linear></v-card
    >

    <v-simple-table v-else dense fixed-header height="90vh">
      <template v-for="dayItem in days">
        <thead :key="'thead-' + dayItem.day">
          <tr>
            <th></th>
            <th></th>
            <th
              v-for="room in roomsFiltered"
              :key="'th-' + room.id"
              class="text-center"
            >
              {{ room.code }}
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody :key="'tbody-' + dayItem.day" class="border">
          <tr
            v-for="(periodItem, index) in dayItem.periods"
            :key="dayItem.day + 'period-' + index"
          >
            <th class="text-right">
              {{ index == 0 ? formatDayOfWeek(dayItem.day) : "" }}
              {{ index == 1 ? formatDate(dayItem.day) : "" }}
            </th>
            <th class="text-right">{{ periodItem.period }}</th>
            <td
              v-for="roomItem in periodItem.rooms"
              :key="'td-' + roomItem.room"
              class="border px-2"
            >
              <div class="d-flex align-center justify-space-between ma-0 pa-0">
                <ReservationShort
                  :value="roomItem.items.map((el) => el.reservation)"
                />
                <SubjectShort :value="roomItem.items.map((el) => el.subject)" />
              </div>
            </td>
            <th class="border">{{ periodItem.period }}</th>
            <th>
              {{ index == 0 ? formatDayOfWeek(dayItem.day) : "" }}
              {{ index == 1 ? formatDate(dayItem.day) : "" }}
            </th>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import DateInput from "common/components/DateInput.vue";
import LookupValueBtnToggle from "common/components/LookupValueBtnToggle.vue";
import ReservationShort from "./components/ReservationShort.vue";
import SubjectShort from "./components/SubjectShort.vue";

import {
  addDays,
  findMonday,
  formatDayOfWeek,
  formatDate,
  today,
} from "common/utils/date.js";

export default defineComponent({
  name: "WeekOverviewRooms",
  components: {
    DateInput,
    LookupValueBtnToggle,
    ReservationShort,
    SubjectShort,
  },
  props: ["search"],
  data() {
    return {
      abortController: null,
      days: [],
      progressCount: 0,
      focus: null,
      filterSite: { id: 0 },
      loading: false,
      rooms: [],
      periods: [],
      ready: false,
    };
  },
  watch: {
    focus() {
      this.update();
    },
    filterSite() {
      localStorage.setItem("weekoverviewSite", JSON.stringify(this.filterSite));
      this.update();
    },
  },
  computed: {
    progressValue() {
      if (this.roomsFiltered.length == 0) {
        return 0;
      }
      return (100 * this.progressCount) / this.roomsFiltered.length;
    },
    progressText() {
      if (this.roomsFiltered.length == 0) {
        return "laden...";
      }
      return `${this.progressCount} von ${this.roomsFiltered.length} Zimmern geladen...`;
    },
    roomsFiltered() {
      if (!this.filterSite) {
        return [];
      }
      return this.rooms.filter((el) => el.site.id == this.filterSite.id);
    },
  },
  methods: {
    addDays,
    formatDayOfWeek,
    formatDate,
    addLesson(lesson, room) {
      const dayItem = this.days.find((el) => el.day == lesson.date);
      if (!dayItem) return;

      const periodItem = dayItem.periods.find(
        (el) => el.period == lesson.period.startTime
      );

      if (!periodItem) return;

      let roomItem = periodItem.rooms.find((el) => el.room == room.code);
      if (!roomItem) {
        return;
      }

      const item = {
        subject: lesson.course.subject.code,
        //teachers: lesson.course.teachers.map((el) => el.code).join(", "),
        //status: lesson.status,
      };
      roomItem.items.push(item);
    },
    addReservation(reservation) {
      const dayItem = this.days.find((el) => el.day == reservation.startDate);
      if (!dayItem) return;

      for (let i = 0; i < dayItem.periods.length; i++) {
        const period = dayItem.periods[i];
        const nextPeriod =
          i < dayItem.periods.length - 1
            ? dayItem.periods[i + 1]
            : { period: "24:00" };
        if (
          (reservation.startTime <= period.period &&
            reservation.endTime > period.period) ||
          (reservation.startTime > period.period &&
            reservation.endTime < nextPeriod.period)
        ) {
          let roomItem = period.rooms.find(
            (el) => el.room == reservation.room.code
          );

          if (!roomItem) {
            return;
          }

          const item = {
            reservation: reservation,
            //teachers: lesson.course.teachers.map((el) => el.code).join(", "),
            //status: lesson.status,
          };
          roomItem.items.push(item);
        }
      }
    },
    async fetchData() {
      this.rooms = await this.apiList({
        resource: "common/room",
        signal: this.abortController.signal,
      });
      this.periods = await this.apiList({
        resource: "common/period",
        query: `site=${this.filterSite.id}`,
        signal: this.abortController.signal,
      });
    },
    async fetchReservations() {
      const reservations = await this.apiList({
        resource: "register/reservation",
        query: `startDate=${this.days[0].day}&endDate=${this.days[5].day}`,
      });
      for (const reservation of reservations) {
        this.addReservation(reservation);
      }
    },
    async fetchLessons() {
      for await (const room of this.roomsFiltered) {
        this.progressCount += 1;
        const lessons =
          (await this.apiList({
            // fetch lessons
            resource: "register/lesson",
            query: `startDate=${this.days[0].day}&endDate=${this.days[5].day}&room=${room.id}`,
            signal: this.abortController.signal,
          })) || [];
        for (const lesson of lessons) {
          this.addLesson(lesson, room);
        }
      }
    },
    async fetchLessonsAsync() {
      const promises = this.roomsFiltered.map(async (room) => {
        const lessons =
          (await this.apiList({
            resource: "register/lesson",
            query: `startDate=${this.days[0].day}&endDate=${this.days[5].day}&room=${room.id}`,
            signal: this.abortController.signal,
          })) || [];
        this.progressCount += 1;
        for (const lesson of lessons) {
          this.addLesson(lesson, room);
        }
      });

      await Promise.all(promises);
    },
    async generateDays() {
      this.days = [];
      const monday = findMonday(this.focus);

      for (let i = 0; i < 6; i++) {
        const lessons = [];
        for await (const period of this.periods) {
          const roomItems = [];
          for (const room of this.roomsFiltered) {
            roomItems.push({ room: room.code, items: [] });
          }
          lessons.push({
            period: period.startTime,
            rooms: roomItems,
          });
        }

        this.days.push({ day: addDays(monday, i), periods: lessons });
      }
    },
    prev() {
      this.focus = this.addDays(this.focus, -7);
    },
    next() {
      this.focus = this.addDays(this.focus, 7);
    },
    async update() {
      if (this.ready && !this.loading) {
        this.abortController = new AbortController();
        this.progressCount = 0;
        this.loading = true;
        await this.fetchData();
        await this.generateDays();
        await this.fetchReservations();
        await this.fetchLessonsAsync();
        this.abortController = null;
        this.loading = false;
      }
    },
  },
  async created() {
    this.focus = today();
    if (localStorage.getItem("weekoverviewSite")) {
      this.filterSite = JSON.parse(localStorage.getItem("weekoverviewSite"));
    }
    this.ready = true;
    this.update();
  },
  destroyed() {
    if (this.abortController) {
      this.abortController.abort();
    }
  },
});
</script>
<style>
td.border,
th.border {
  border-left: 1px solid grey;
}
</style>
