<template>
  <v-dialog
    max-width="1200px"
    width="100%"
    :value="true"
    @click:outside="close"
    scrollable
    @keydown.esc="close"
  >
    <v-card :loading="loading">
      <v-system-bar window dark>
        {{ device.name }}
        <v-spacer />
        <v-spacer />
        <v-btn @click="close" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>
      <v-footer>
        <v-btn :disabled="loading" @click="previous" icon
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        ><v-spacer /><v-btn :disabled="loading" icon @click="next"
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </v-footer>
      <v-card-text v-if="device">
        <v-container>
          <v-row
            ><v-col cols="6"
              ><v-card class="pa-2 mb-2">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-title>Modell</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ brandModel }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Art</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ deviceType }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Name</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ device.name }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-title>Produktnummer</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ device.productNumber }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Seriennummer</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ device.serialNumber }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-title>Standort</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ location }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Leihbar</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ device.loanable ? "ja" : "nein" }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card class="pa-2 mb-2">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon> mdi-wifi </v-icon></v-list-item-icon
                    >
                    <v-list-item-title>MAC</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ device.macAddressWlan }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon></v-list-item-icon>
                    <v-list-item-title>IP</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ device.ipv4AddressWlan }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon> mdi-ethernet </v-icon></v-list-item-icon
                    >
                    <v-list-item-title>MAC</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ device.macAddressLan }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon></v-list-item-icon>
                    <v-list-item-title>IP</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ device.ipv4AddressLan }}
                    </v-list-item-subtitle>
                  </v-list-item></v-list
                >
              </v-card></v-col
            ><v-col cols="6"
              ><v-card class="pa-2 mb-2"
                ><v-card-subtitle>Kauf</v-card-subtitle>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-title>Datum</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ formatDate(device.invoiceDate) }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Kaufpreis (CHF)</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ purchasePrice }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-title>Händler</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ device.merchant ? device.merchant.companyName : "–" }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Service</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ device.service ? device.service.companyName : "–" }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list> </v-card
              ><v-card class="pa-2 mb-2" v-if="device.comment"
                ><v-card-subtitle>Kommentar</v-card-subtitle
                ><v-card-text>{{ device.comment }}</v-card-text></v-card
              ><v-card class="pa-2"
                ><v-card-subtitle>Historie/Activity</v-card-subtitle>
                <v-timeline dense>
                  <v-timeline-item small color="success" fill-dot>
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <strong> {{ formatDate(device.createdOn) }}</strong>
                      </v-col>
                      <v-col>
                        <strong>erzeugt</strong>
                        <div class="caption">{{ device.createdBy }}</div>
                      </v-col>
                    </v-row></v-timeline-item
                  >

                  <v-timeline-item
                    v-for="item in activity"
                    :key="item.id"
                    :icon="
                      'mdi-' +
                      item.activityTypeIcon.replace('suitcase', 'bag-suitcase')
                    "
                    fill-dot
                  >
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <strong> {{ item.startDate }}</strong>
                      </v-col>
                      <v-col>
                        <strong>{{ item.activityType }}</strong>
                        <div class="caption">
                          {{ getText(item) }}
                        </div>
                      </v-col>
                    </v-row></v-timeline-item
                  >

                  <v-timeline-item small color="info" fill-dot>
                    <v-row class="pt-1">
                      <v-col cols="3">
                        <strong> {{ device.lastChangedOn }}</strong>
                      </v-col>
                      <v-col>
                        <strong>geändert</strong>
                        <div class="caption">{{ device.lastChangedBy }}</div>
                      </v-col>
                    </v-row></v-timeline-item
                  >
                </v-timeline>
              </v-card></v-col
            >
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="success"
          :to="{ name: 'InventoryDeviceNew', props: { id: id } }"
          text
        >
          duplizieren
        </v-btn>
        <v-btn color="info" @click="service" text> service </v-btn>
        <v-btn color="primary" @click="edit" text> bearbeiten </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="close" text> schliessen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { bus } from "@/services/bus.service";
import { formatDate, formatTime } from "common/utils/date.js";

export default defineComponent({
  name: "InventoryDevice",
  props: ["id"],
  data() {
    return {
      device: {},
      activity: [],
      loadingDevice: false,
      loadingActivity: false,
    };
  },
  computed: {
    brandModel() {
      return this.device.brandModel ? this.device.brandModel.description : "–";
    },
    deviceType() {
      return this.device.deviceType ? this.device.deviceType.description : "–";
    },
    location() {
      return this.device.location ? this.device.location.code : "–";
    },
    purchasePrice() {
      return this.device.purchasePrice
        ? this.device.purchasePrice.toFixed(2)
        : "–";
    },
    loading() {
      return this.loadingDevice || this.loadingActivity;
    },
  },
  methods: {
    async getActivity() {
      this.loadingActivity = true;
      this.activity = await this.apiList({
        resource: "inventory/activity",
        query: `device=${this.id}`,
      });
      //this.activity = data.result;
      this.loadingActivity = false;
    },
    async getDevice() {
      this.loadingDevice = true;
      this.device = await this.apiGet({
        resource: "inventory/device",
        id: this.id,
      });
      this.loadingDevice = false;
    },
    getText(activity) {
      if (activity.person) {
        return (
          activity.person.firstName +
          " " +
          activity.person.lastName +
          " (" +
          activity.person.code +
          ")"
        );
      }
      if (activity.company) {
        return activity.company.companyName + ", " + activity.company.town;
      }
    },
    back() {
      this.$router.back();
    },
    edit() {
      this.$router.push({
        name: "InventoryDeviceEdit",
        params: { id: this.device.id },
      });
    },
    close() {
      this.$router.push({
        name: "InventoryDevices",
      });
    },
    formatDate,
    formatTime,
    async service() {
      if (
        await this.$root.confirm({
          message: "Muss das Gerät repariert werden?",
          color: "info",
          icon: "mdi-tools",
        })
      ) {
        await this.apiPost({
          resource: "inventory/activity",
          data: {
            service: true,
            device: this.device,
          },
        });
        this.$router.push({
          name: "InventoryDeviceServices",
        });
      }
    },
    next() {
      bus.$emit("nextDevice");
    },
    previous() {
      bus.$emit("previousDevice");
    },
  },
  watch: {
    id() {
      if (this.device && this.id !== this.device.id) {
        this.getDevice();
        this.getActivity();
      }
    },
  },
  async created() {
    this.getDevice();
    this.getActivity();
  },
});
</script>
