<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    hide-no-data
    :items="items"
    @change="select"
    label="Wochentag"
    item-text="description"
    item-value="id"
    return-object
  >
  </v-autocomplete>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "DayOfWeekInput",
  props: ["value"],
  data() {
    return {
      items: [
        { id: 1, code: "Mo", description: "Montag" },
        { id: 2, code: "Di", description: "Dienstag" },
        { id: 3, code: "Mi", description: "Mittwoch" },
        { id: 4, code: "Do", description: "Donnerstag" },
        { id: 5, code: "Fr", description: "Freitag" },
      ],
    };
  },
  methods: {
    remove() {
      this.$emit("input", null);
    },
    select($event) {
      this.$emit("input", $event);
    },
  },
});
</script>
