<template>
  <v-chip outlined v-bind="$attrs" v-on="{ ...$listeners }" v-if="value">
    <v-avatar left>
      <PortraitImage :value="value" />
    </v-avatar>
    <div v-if="$vuetify.breakpoint.mobile && value.code">
      {{ value.code }}
    </div>
    <PersonName
      v-else
      :value="value"
      :ext="!hideExt && !$vuetify.breakpoint.mobile"
      :flags="flags"
    />

    <slot />
  </v-chip>
</template>
<script>
import { defineComponent } from "vue";
import PersonName from "common/components/PersonName";
import PortraitImage from "common/components/PortraitImage.vue";
export default defineComponent({
  name: "PersonChip",
  components: {
    PersonName,
    PortraitImage,
  },
  props: {
    value: null,
    flags: { type: Boolean, default: false },
    hideExt: { type: Boolean, default: false },
  },
});
</script>
