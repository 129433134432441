<template>
  <v-container fluid>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn href="https://admin.gymkirchenfeld.ch/wiki" target="_blank" text>
        <span class="mr-2">wiki</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn href="https://intern.gymkirchenfeld.ch" target="_blank" text>
        <span class="mr-2">Intern</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-row class="flex-nowrap justify-start align-start overflow-auto">
      <v-col
        cols="auto"
        v-if="info && info.teacherExit && info.teacherExit.length > 0"
      >
        <TeacherExit :items="info.teacherExit" />
      </v-col>
      <v-col
        cols="auto"
        v-if="info && info.absenceBalance && info.absenceBalance.length > 0"
      >
        <AbsenceBalance :items="info.absenceBalance"
      /></v-col>
      <v-col
        cols="auto"
        v-if="info && info.birthday && info.birthday.length > 0"
      >
        <Birthday :items="info.birthday" />
      </v-col>
      <v-col cols="auto"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import AbsenceBalance from "@/components/modules/AbsenceBalance";
import Birthday from "@/components/modules/Birthday";
import TeacherExit from "@/components/modules/TeacherExit";
export default defineComponent({
  name: "HelloWorld",
  components: {
    AbsenceBalance,
    Birthday,
    TeacherExit,
  },
  data() {
    return {
      info: {},
    };
  },
  async created() {
    this.info = await this.apiList({ resource: "app/overview" });
  },
});
</script>
