import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import routesCommon from "./routes";
import routesCourses from "./routes.courses";
import routesDocuments from "./routes.documents";
import routesDocumentation from "./routes.documentation";
import routesFinances from "./routes.finances";
import routesInventory from "./routes.inventory";
import routesLessonChanges from "./routes.lessonChanges";
import routesLockers from "./routes.lockers";
import routesLog from "./routes.log";
import routesObjects from "./routes.objects";
import routesOptional from "./routes.optional";
import routesPeople from "./routes.people";
import routesPortfolios from "./routes.portfolios";
import routesSchoolClasses from "./routes.schoolclasses";
import routesSignage from "./routes.signage";
import routesTeacher from "./routes.teacher";
import routesThesis from "./routes.thesis";
import routesWorklogs from "./routes.worklogs";

const routes = [
  ...routesCommon,
  ...routesCourses,
  ...routesDocumentation,
  ...routesDocuments,
  ...routesFinances,
  ...routesInventory,
  ...routesLessonChanges,
  ...routesLockers,
  ...routesLog,
  ...routesObjects,
  ...routesOptional,
  ...routesPeople,
  ...routesPortfolios,
  ...routesSchoolClasses,
  ...routesSignage,
  ...routesTeacher,
  ...routesThesis,
  ...routesWorklogs,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
