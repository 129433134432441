<template>
  <div>
    <v-toolbar flat class="mb-4">
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
      ></v-progress-linear>
      <DateInput
        clearable
        dense
        hide-details
        v-model="filter.date"
        label="Stichtag"
      />
      <v-text-field
        class="ml-2"
        v-model="filter.schoolClass"
        dense
        hide-details
        label="Klasse/Stufe"
      />
      <v-spacer />
      {{ Object.keys(itemsFiltered).length }} SuS
      <v-spacer />
      <ReportButton
        color="primary"
        resource="report/portfoliooverview"
        class="mt-2"
        title="alle"
        :parameters="{
          type: 'students',
          id: Object.keys(itemsFiltered),
        }"
      />
    </v-toolbar>

    <v-card>
      <template v-for="(value, key) in itemsFiltered">
        <v-row :key="key">
          <v-col>
            <PersonItem :value="value[0].student" />
          </v-col>
          <v-col>
            <v-chip
              v-for="item in value"
              :key="item.id"
              class="mx-1"
              :to="{ name: 'PortfolioDetail', params: { id: item.id } }"
              >{{ item.title }}</v-chip
            >
          </v-col>
          <v-col>
            <ReportButton
              color="primary"
              depressed
              resource="report/portfoliooverview"
              class="mt-2"
              :parameters="{
                type: 'student',
                id: value[0].student.id,
              }"
            />
          </v-col>
        </v-row>
        <v-divider :key="'d' + key" />
      </template>
    </v-card>
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { searchPerson } from "common/utils/people.js";
import DateInput from "common/components/DateInput.vue";
import PersonItem from "common/components/PersonItem.vue";
import ReportButton from "@/components/ReportButton.vue";

import { groupBy } from "lodash";
export default defineComponent({
  name: "PortfolioList",
  props: ["search"],
  components: { DateInput, PersonItem, ReportButton },
  data: () => ({
    filter: {
      date: null,
      schoolClass: "22",
    },
    items: [],
    loading: false,
  }),
  computed: {
    itemsFiltered() {
      if (this.items.length == 0) return [];
      const portfolios = this.items.filter(
        (item) =>
          (!this.filter.date || this.filter.date <= item.creationDate) &&
          (!this.filter.schoolClass ||
            item.student.schoolClass.code.includes(this.filter.schoolClass)) &&
          (!this.search || searchPerson(item.student, this.search))
      );
      return groupBy(portfolios, "student.id");
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "portfolio/portfolio",
        query: "active",
      });
      this.loading = false;
    },
  },
  created() {
    this.getData();
  },
});
</script>
