<template>
  <v-dialog
    max-width="1024"
    width="100%"
    :value="true"
    scrollable
    persistent
    @keydown.esc="close"
  >
    <v-card :loading="loading">
      <v-system-bar window :color="titleColor" dark class="mb-2">
        {{ titleText }}

        <v-spacer />
        <v-btn @click="close" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-card-text class="pt-2">
        <v-row>
          <v-col cols="5">
            <v-card>
              <v-list-item>
                <PersonInput
                  clearable
                  group="intern"
                  label="Praktikant*in"
                  v-model="stage.intern"
                />
              </v-list-item>
              <v-list-item>
                <DateInput
                  @input="updateCourses"
                  label="von"
                  v-model="stage.startDate"
                />
              </v-list-item>
              <v-list-item>
                <DateInput
                  @input="updateCourses"
                  label="bis"
                  v-model="stage.endDate"
                />
              </v-list-item>
              <v-list-item>
                <PersonInput
                  clearable
                  @input="updateCourses"
                  group="teacher"
                  label="bei Lehrer*in"
                  v-model="stage.teacher"
                />
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="7">
            <v-card>
              <v-data-table
                :headers="headers"
                :items="courses"
                :items-per-page="15"
                :sort-by="'code'"
                @click:row="(item) => toggleStage(item)"
              >
                <template v-slot:item.startDate="{ item }">
                  <DateValue v-model="item.startDate" />
                </template>
                <template v-slot:item.endDate="{ item }">
                  <DateValue v-model="item.endDate" />
                </template>
                <template v-slot:item.stage="{ item }">
                  <v-simple-checkbox
                    @click="toggleStage(item)"
                    disabled
                    :value="item.stage"
                  />
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn v-if="!create" text @click="del" color="danger">Löschen</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="close"> Abbrechen</v-btn>
        <v-btn text @click="save" color="primary"> Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import DateInput from "common/components/DateInput.vue";
import PersonInput from "common/components/PersonInput.vue";
export default defineComponent({
  name: "StagesDetails",
  components: {
    DateValue,
    DateInput,
    PersonInput,
  },
  props: ["id"],
  data() {
    return {
      create: false,
      courses: [],
      headers: [
        { text: "Kurs", value: "code" },
        { text: "von", value: "startDate" },
        { text: "bis", value: "endDate" },
        { text: "Praktikum", value: "stage" },
      ],
      loading: false,
      stage: {
        intern: null,
        startDate: null,
        endDate: null,
        teacher: null,
        courses: [],
      },
    };
  },
  computed: {
    titleColor() {
      return this.create ? "success" : "error";
    },
    titleText() {
      return this.create ? "Praktikum erstellen" : "Praktikum ändern";
    },
  },
  methods: {
    close() {
      this.$router.back();
    },
    async del() {
      if (
        await this.$root.confirm({
          message: "Soll dieses Praktikum wirklich gelöscht werden?",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({ resource: "teacher/stage", id: this.stage.id });
        this.$router.back();
      }
    },
    async save() {
      const courses = this.courses;
      const stageCourses = [];
      for (let i = 0; i < courses.length; ++i) {
        if (courses[i].stage) stageCourses.push(courses[i].id);
      }

      this.stage.courses = stageCourses;
      if (this.stage.id) {
        await this.apiPut({ resource: "teacher/stage", data: this.stage });
      } else {
        await this.apiPost({ resource: "teacher/stage", data: this.stage });
      }
      this.$router.back();
    },
    toggleStage(course) {
      course.stage = !course.stage;
    },
    async updateCourses() {
      if (!(this.stage.teacher && this.stage.startDate && this.stage.endDate)) {
        this.courses = [];
        return;
      }

      const courses = await this.apiList({
        resource: "course/course",
        query: `person=${this.stage.teacher.id}&startDate=${this.stage.startDate}&endDate=${this.stage.endDate}`,
      });

      for (let i = 0; i < courses.length; ++i) {
        courses[i].stage = this.stage.courses.indexOf(courses[i].id) > -1;
      }

      this.courses = courses;
    },
  },
  async created() {
    this.create = this.id === "new";
    if (!this.create) {
      this.loading = true;
      this.stage = await this.apiGet({
        resource: "teacher/stage",
        id: this.id,
      });
      this.loading = false;
      this.updateCourses();
    }
  },
});
</script>
