<template>
  <v-dialog
    max-width="1200px"
    width="100%"
    :value="true"
    @click:outside="close"
    scrollable
    @keydown.esc="close"
  >
    <v-card>
      <v-system-bar window dark class="mb-2">
        Firma
        <v-spacer />

        <v-btn @click="close" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <v-system-bar
                  ><v-icon>mdi-office-building</v-icon> Firma</v-system-bar
                >
                <v-list-item>
                  <v-list-item-subtitle>Name</v-list-item-subtitle>
                  <v-list-item-title>{{
                    company.companyName
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>Homepage</v-list-item-subtitle>
                  <v-list-item-title>{{ company.homepage }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>E-Mail</v-list-item-subtitle>
                  <v-list-item-title>{{
                    company.emailCompany
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>Telefon</v-list-item-subtitle>
                  <v-list-item-title>{{
                    company.phoneCompany
                  }}</v-list-item-title>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-system-bar
                  ><v-icon>mdi-account-tie</v-icon> Kontakt</v-system-bar
                >
                <v-list-item>
                  <v-list-item-subtitle>Name 1</v-list-item-subtitle>
                  <v-list-item-title>{{
                    company.contactName1
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>Name 2</v-list-item-subtitle>
                  <v-list-item-title>{{
                    company.contactName2
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>E-Mail</v-list-item-subtitle>
                  <v-list-item-title>{{
                    company.emailContact
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>Telefon</v-list-item-subtitle>
                  <v-list-item-title>{{
                    company.phoneContact
                  }}</v-list-item-title>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><v-card>
                <v-system-bar
                  ><v-icon>mdi-comment</v-icon> Kommentar</v-system-bar
                >
                <v-card-text>{{ company.comment }}</v-card-text>
              </v-card></v-col
            >
            <v-col>
              <v-card>
                <v-system-bar><v-icon>mdi-map</v-icon> Anschrift</v-system-bar>
                <v-list-item>
                  <v-list-item-subtitle>Zeile 1</v-list-item-subtitle>
                  <v-list-item-title>{{ company.address1 }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>Zeile 2</v-list-item-subtitle>
                  <v-list-item-title>{{ company.address2 }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>PLZ</v-list-item-subtitle>
                  <v-list-item-title>{{ company.postcode }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>Ort</v-list-item-subtitle>
                  <v-list-item-title>{{ company.town }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle>Land</v-list-item-subtitle>
                  <v-list-item-title>{{ company.country }}</v-list-item-title>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" @click="edit" text> bearbeiten </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="close" text> schliessen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "InventoryCompany",
  props: ["id"],
  data: () => ({
    loading: false,
    search: "",
    company: {},
  }),
  methods: {
    back() {
      this.$router.back();
    },
    close() {
      this.$router.push({
        name: "InventoryCompanies",
      });
    },
    edit() {
      this.$router.push({
        name: "InventoryCompanyEdit",
        params: { id: this.id },
      });
    },
    async getData() {
      this.loading = true;
      this.company = await this.apiGet({
        resource: "inventory/company",
        id: this.id,
      });
      this.loading = false;
    },
  },
  async created() {
    this.getData();
  },
});
</script>
