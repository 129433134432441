export default [
  {
    path: "/lessonChanges",
    component: () =>
      import(/* webpackChunkName: "LessonChanges" */ "@/views/LessonChanges/"),
    name: "LessonChanges",
    children: [
      {
        path: "schoolClass/:schoolClassId",
        name: "LessonChangesSchoolclass",
        component: () =>
          import(
            /* webpackChunkName: "LessonChangesSchoolclass" */ "@/views/LessonChanges/SchoolClass"
          ),
      },
      {
        path: "teacher/:teacherId",
        name: "LessonChangesTeacher",
        component: () =>
          import(
            /* webpackChunkName: "LessonChangesTeacher" */ "@/views/LessonChanges/Teacher"
          ),
      },
    ],
  },
];
