<template>
  <v-card :loading="loading" class="mt-4">
    <v-row>
      <v-col>
        <v-list subheader>
          <v-subheader>Start</v-subheader>
          <v-list-item>
            <DateInput v-model="settings.startDate" />
          </v-list-item>
          <v-list-item>
            <TimeInput v-model="settings.startTime" />
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list subheader>
          <v-subheader>Ende Schüler*innen</v-subheader>
          <v-list-item>
            <DateInput v-model="settings.studentEndDate" />
          </v-list-item>
          <v-list-item>
            <TimeInput v-model="settings.studentEndTime" />
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list subheader>
          <v-subheader>Ende Lehrkräfte</v-subheader>
          <v-list-item>
            <DateInput v-model="settings.teacherEndDate" />
          </v-list-item>
          <v-list-item>
            <TimeInput v-model="settings.teacherEndTime" />
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-divider />
    <v-card-actions>
      <v-btn text @click="fetchData">zurücksetzen</v-btn>
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="save"
        :loading="saving"
        :disabled="saving"
        >Speichern</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import DateInput from "common/components/DateInput.vue";
import TimeInput from "common/components/TimeInput.vue";
export default defineComponent({
  name: "OptionalAvailability",
  components: { DateInput, TimeInput },
  props: ["search"],
  data() {
    return {
      loading: false,
      saving: false,
      settings: {},
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.settings = await this.apiList({ resource: "optional/settings" });
      this.loading = false;
    },
    async save() {
      this.saving = true;
      await this.apiPost({
        resource: "optional/settings",
        data: this.settings,
      });
      this.saving = false;
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
