<template>
  <div class="d-flex flex-column clickable">
    <span v-if="value.name">{{ value.name }}</span>
    <span v-if="value.title">{{ value.title }}</span>
    <span
      class="caption text--secondary mt-n1"
      style="white-space: normal; line-height: 1"
      v-if="value.description"
      v-html="value.description"
    >
    </span>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: ["value"],
});
</script>
