<template>
  <v-container>
    <PersonProfile outlined :person="person" />
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import { saveChildRoute } from "@/router/helper";
import PersonProfile from "common/components/Person/PersonProfile.vue";

export default defineComponent({
  name: "Profile",
  components: { PersonProfile },
  props: {
    id: { type: String },
  },
  computed: {
    personId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      person: null,
      locker: { current: [] },
      loading: false,
    };
  },
  methods: {
    async fetchData() {
      if (this.personId) {
        this.person = await this.apiGet({
          resource: "person/person",
          id: this.personId,
        });
      }
    },
  },
  watch: {
    async id() {
      await this.fetchData();
    },
  },
  async created() {
    await this.fetchData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
});
</script>
