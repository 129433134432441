<template>
  <div>
    <v-toolbar flat dense class="mb-2">
      <v-autocomplete
        v-model="resource"
        :items="resources"
        hide-no-data
        hide-details
        label="Resource wählen"
        item-text="endpoint"
        clearable
      ></v-autocomplete>
    </v-toolbar>
    <v-card>
      <v-system-bar v-if="resource">Rescource «{{ resource }}»</v-system-bar>
      <v-system-bar v-else>Resource wählen</v-system-bar>
      <LogDataTable :items="items" :search="search" :loading="loading" />
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import LogDataTable from "./components/LogDataTable";
export default defineComponent({
  name: "LogResource",
  components: {
    LogDataTable,
  },
  props: ["search"],
  data() {
    return {
      items: [],
      loading: false,
      resource: "",
      resources: [],
    };
  },
  watch: {
    async resource() {
      if (this.resource) {
        this.loading = true;
        this.items = await this.apiList({
          resource: "app/log",
          query: "url=" + this.resource,
        });
        this.loading = false;
      } else {
        this.items = [];
      }
    },
  },
  async created() {
    this.resources = await this.apiList({ resource: "app/resource" });
  },
});
</script>
