<template>
  <div>
    <v-card class="mb-4">
      <v-system-bar>aktueller Zustand</v-system-bar>
      <v-container>
        <v-row>
          <v-col>
            <v-switch
              inset
              v-model="settings.holiday"
              color="error"
              readonly
              messages="während den Ferien ist der Screen aus"
              :label="settings.holiday ? 'Ferien' : 'keine Ferien'"
            ></v-switch>
            <v-switch
              inset
              color="error"
              v-model="settings.weekend"
              readonly
              messages="am Wochenende ist der Screen aus"
              :label="settings.weekend ? 'Wochenende' : 'kein Wochenende'"
            ></v-switch>
          </v-col>
          <v-col>
            <v-switch
              inset
              color="success"
              v-model="settings.scheduled"
              readonly
              messages="der Screen läuft von 7 bis 21 Uhr"
              :label="settings.scheduled ? 'Zeitplan ein' : 'Zeitplan aus'"
            ></v-switch>
            <v-switch
              inset
              color="success"
              v-model="settings.override"
              readonly
              messages="der Screen kann manuell übersteuert werden"
              :label="
                settings.override ? 'Übersteuerung' : 'keine Übersteuerung'
              "
            ></v-switch>
          </v-col>
          <v-col>
            <v-switch
              inset
              color="primary"
              v-model="settings.power"
              readonly
              messages="aktueller Zustand des Screens"
              :label="settings.power ? 'Power ein' : 'Power aus'"
            ></v-switch>

            <v-btn @click="getData" color="primary" text outlined class="mt-4"
              ><v-icon left>mdi-refresh</v-icon> aktualisieren</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card>
      <v-system-bar>Übersteuerung </v-system-bar>

      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="powerOverrides"
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer
      >
        <template v-slot:item.name="{ item }">
          Übersteuerung {{ item.id }}
        </template>
        <template v-slot:item.active="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon v-if="item.active" color="primary"
                  >mdi-power-plug</v-icon
                >
                <v-icon v-else>mdi-power-plug-off</v-icon>
              </span>
            </template>
            <span v-if="item.active"
              >Diese Übersteuerung ist momentan aktiv</span
            >
            <span v-else>Diese Übersteuerung ist momentan nicht aktiv</span>
          </v-tooltip>
        </template>
        <template v-slot:item.valid="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon v-if="item.valid" color="primary"
                  >mdi-calendar-check</v-icon
                >
                <v-icon v-else>mdi-calendar-question</v-icon>
              </span>
            </template>
            <span v-if="item.valid">Die Zeitspanne ist gültig</span>
            <span v-else
              >Etwas mit der Zeitspanne stimmt nicht (fehlende Angaben oder Ein-
              nach Ausschalten)</span
            >
          </v-tooltip>
        </template>
        <template v-slot:item.start="{ item }">
          <DatePicker
            @change="save(item)"
            v-model="item.startDate"
            color="success"
          />
          <TimePicker
            @change="save(item)"
            v-model="item.startTime"
            color="success"
          />
        </template>
        <template v-slot:item.end="{ item }">
          <DatePicker
            @change="save(item)"
            v-model="item.endDate"
            color="error"
          />
          <TimePicker
            @change="save(item)"
            v-model="item.endTime"
            color="error"
          />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="reset(item)" v-bind="attrs" v-on="on"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
            </template>
            <span>Übersteuerung zurücksetzen (Zeitspanne löschen)</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import DatePicker from "@/components/DatePicker";
import TimePicker from "@/components/TimePicker";
export default defineComponent({
  name: "ScreenSettings",
  components: { DatePicker, TimePicker },
  data() {
    return {
      loading: false,
      headers: [
        { text: "", value: "name" },
        { text: "aktiv", value: "active" },
        { text: "gültig", value: "valid" },
        { text: "Einschalten", value: "start" },
        { text: "Ausschalten", value: "end" },
        { text: "", value: "actions" },
      ],
      search: "",
      settings: {},
      powerOverrides: [],
    };
  },
  methods: {
    async save(override) {
      await this.apiPut({ resource: "screen/poweroverride", data: override });
      this.getData();
    },
    async reset(override) {
      if (
        await this.$root.confirm({
          message: "Soll diese Übersteuerung zurückgesetzt werden?",
          color: "primary",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiPut({
          resource: "screen/poweroverride",
          data: {
            id: override.id,
            startDate: "",
            endDate: "",
            startTime: "",
            endTime: "",
          },
        });
        this.getData();
      }
    },
    async getData() {
      this.loading = true;
      this.settings = await this.apiList({ resource: "screen/display" });
      this.powerOverrides = await this.apiList({
        resource: "screen/poweroverride",
      });
      this.powerOverrides.sort((a, b) => a.id - b.id);
      this.loading = false;
    },
  },
  created() {
    this.getData();
  },
});
</script>
