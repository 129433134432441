<template>
  <v-dialog
    max-width="800px"
    width="100%"
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
  >
    <v-card :loading="loading">
      <v-system-bar window dark
        ><span>Transaktionen Druckerkonti</span><v-divider />
        <v-btn icon @click="dialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      >

      <v-toolbar color="info" flat dark>
        <PersonItem :value="overview.student" />

        <v-chip large light><CurrencyValue :value="overview.balance" /></v-chip>
        <v-dialog v-model="newDialog" width="500" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              small
              class="ml-2"
              fab
              color="success"
              v-bind="attrs"
              v-on="on"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>

          <v-card>
            <v-system-bar window>Transaktion hinzufügen</v-system-bar>
            <v-card-text class="mt-4 pb-2">
              <DateInput label="Datum" v-model="newItem.date" />
              <v-text-field
                autofocus
                label="Beschreibung"
                v-model="newItem.description"
              />
              <v-text-field
                @keydown.enter="saveNew"
                type="number"
                label="Betrag"
                v-model="newItem.amount"
              />
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn text @click="newDialog = false">abbrechen</v-btn
              ><v-btn
                text
                color="primary"
                :disabled="!saveable"
                :loading="saving"
                @click="saveNew"
                >speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-card-text class="pa-0 ma-0">
        <v-data-table
          :headers="headers"
          :items="items"
          disable-sort
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.date="{ item }">
            <DateValue :value="item.date" />
          </template>
          <template v-slot:item.amount="{ item }">
            <CurrencyValue :value="item.amount" />
          </template>
          <template v-slot:item.balance="{ item }">
            <CurrencyValue :value="item.balance" />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="trash(item.id)" :disabled="!item.manual"
              ><v-icon>mdi-trash-can</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer /><v-btn text @click="dialog = false"
          >schliessen</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import CurrencyValue from "@/components/CurrencyValue";
import DateValue from "common/components/DateValue.vue";
import DateInput from "common/components/DateInput.vue";
import PersonItem from "common/components/PersonItem";

import { today } from "common/utils/date.js";

export default defineComponent({
  components: { DateValue, DateInput, CurrencyValue, PersonItem },
  props: ["id"],
  data: () => ({
    dialog: true,
    newDialog: false,
    newItem: {
      date: today(),
      description: "",
      amount: "",
    },
    overview: { student: {}, balance: 0 },
    items: [],
    loading: false,
    updated: false,
    saving: false,
    headers: [
      { text: "Datum", value: "date" },
      { text: "Beschreibung", value: "description" },
      { text: "Betrag", value: "amount", align: "end" },
      { text: "Kontostand", value: "balance", align: "end" },
      { text: "", value: "actions" },
    ],
  }),
  computed: {
    saveable() {
      return (
        this.newItem.amount && this.newItem.date && this.newItem.description
      );
    },
  },
  watch: {
    newDialog() {
      this.newItem = {
        date: today(),
        description: "",
        amount: "",
      };
    },
    dialog() {
      if (!this.dialog) {
        if (this.updated) {
          this.$emit("dataUpdated");
        }
        this.$router.push({
          name: "FinancePrints",
          query: this.$route.query,
        });
      }
    },
    id() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.overview = await this.apiGet({
        resource: "finance/printoverview",
        id: this.id,
      });
      this.items = await this.apiList({
        resource: "finance/printtransaction",
        query: `student=${this.id}`,
      });
      this.loading = false;
    },

    async saveNew() {
      const data = {
        ...this.newItem,
        person: this.overview.student.id,
      };
      this.saving = true;
      await this.apiPost({
        resource: "finance/printtransaction",
        data: data,
        onSuccess: () => {
          this.$root.showSuccess("Transaktion wurde übernommen");
          this.fetchData();
          this.updated = true;
          this.newDialog = false;
        },
      });
      this.saving = false;
    },
    async trash(id) {
      if (
        await this.$root.confirm({
          message: "Soll diese Transaktion wirklich gelöscht werden?",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({
          resource: "finance/printtransaction",
          id: id,
        });
        this.updated = true;
        this.fetchData();
      }
    },
  },

  async created() {
    this.fetchData();
  },
});
</script>
