<template>
  <v-bottom-sheet v-model="dialog" width="500">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-chip
            small
            v-on="{ ...tooltip, ...dialog }"
            v-bind="attrs"
            outlined
          >
            <Icon left small>mdi-map-marker</Icon>
            {{ value.code }}
          </v-chip>
        </template>
        {{ value.description }}
      </v-tooltip>
    </template>
    <RoomSheet :value="value" @close="dialog = false" />
  </v-bottom-sheet>
</template>
<script>
import { defineComponent } from "vue";
import RoomSheet from "common/components/RoomSheet.vue";
export default defineComponent({
  name: "RoomChip",
  components: { RoomSheet },
  props: {
    value: {
      type: Object,
      default: () => ({
        id: 0,
        title: "",
        description: "",
        url: "",
      }),
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {},
});
</script>
