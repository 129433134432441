<template>
  <v-dialog
    max-width="768px"
    width="100%"
    :value="true"
    scrollable
    persistent
    :fullscreen="fullscreen"
    @keydown.esc="close"
  >
    <v-card :loading="loading">
      <v-system-bar window
        >Raumreservation hinzufügen<v-spacer />
        <v-btn icon v-if="fullscreen" @click="fullscreen = false"
          ><v-icon>mdi-window-restore</v-icon></v-btn
        >
        <v-btn icon v-else @click="fullscreen = true"
          ><v-icon>mdi-window-maximize</v-icon></v-btn
        >
        <v-btn icon @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      >

      <v-list>
        <v-list-item>
          <RoomInputMulti v-model="selectedRooms" />
        </v-list-item>

        <v-list-item three-line>
          <PersonInput
            label="reserviert für"
            group="employee"
            v-model="item.reservedFor"
          />
        </v-list-item>
        <v-list-item>
          <v-row dense>
            <v-col>
              <v-switch inset v-model="showDayOfWeek">
                <template v-slot:label>Jede Woche an diesem Tag:</template>
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on"
                        >mdi-help-circle-outline</v-icon
                      >
                    </template>
                    <span
                      >Die Dispensation gilt für alle ausgewählten Wochentage im
                      Zeitraum «von Tag» bis «bis Tag» in den gewählten
                      Lektionen!</span
                    >
                  </v-tooltip>
                </template>
              </v-switch>
            </v-col>
            <v-col
              ><DayOfWeekInput :disabled="!showDayOfWeek" v-model="dayOfWeek" />
            </v-col>
          </v-row>
        </v-list-item>

        <v-list-item>
          <v-row>
            <v-col>
              <DateInput label="Startdatum" v-model="item.startDate" />
            </v-col>
            <v-col>
              <TimeInput label="Startzeit" v-model="item.startTime" />
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="item.startTime"
                :items="periods"
                label="Lektionsbeginn"
                item-text="startTime"
                item-value="startTime"
                :loading="loadingPeriods"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-list-item>
        <v-list-item>
          <v-row>
            <v-col>
              <DateInput label="Enddatum" v-model="item.endDate" />
            </v-col>
            <v-col>
              <TimeInput label="Endzeit" v-model="item.endTime" />
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="item.endTime"
                :items="periods"
                label="Lektionsende"
                item-text="endTime"
                item-value="endTime"
                :loading="loadingPeriods"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-list-item>

        <v-list-item>
          <v-row>
            <v-col cols="4">
              <v-text-field label="Beschreibung" v-model="item.description" />
            </v-col>
            <v-col cols="8">
              <v-text-field label="Kommentar" v-model="item.comment" />
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
      <v-card-text class="ma-0 pa-0">
        <v-alert v-if="saveable" type="info" text tile prominent icon="mdi-eye">
          <v-list light>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> {{ item.description }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.comment }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-chip-group column>
              <v-chip v-for="(item, index) in items" :key="'preview' + index">
                <v-tooltip bottom v-if="errors[index]">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="red" left
                      >mdi-close-circle-outline</v-icon
                    >
                  </template>
                  <span>{{ errors[index] }}</span>
                </v-tooltip>

                <v-icon v-if="success[index]" color="green" left
                  >mdi-check-circle-outline</v-icon
                >
                <span class="mr-2" color="info">{{ item.room.code }}:</span
                ><DateVue :value="item.startDate" long />,
                {{ item.startTime }} – <DateVue :value="item.endDate" long />,
                {{ item.endTime }}
              </v-chip>
            </v-chip-group>
          </v-list>
        </v-alert>
        <v-alert v-else type="error" text tile prominent icon="mdi-eye-off">
          Vorschau nicht verfügbar
        </v-alert>

        <v-alert
          v-if="errorMessage"
          border="left"
          outlined
          tile
          prominent
          type="error"
          >{{ errorMessage }}</v-alert
        >
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close"> abbrechen</v-btn>
        <v-btn
          text
          @click="save"
          :disabled="!saveable"
          :loading="saving"
          color="primary"
          >Speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
import DateInput from "common/components/DateInput.vue";
import DateVue from "common/components/DateValue.vue";
import DayOfWeekInput from "@/components/DayOfWeekInput.vue";
import PersonInput from "common/components/PersonInput.vue";
import RoomInputMulti from "@/components/RoomInputMulti";
import TimeInput from "common/components/TimeInput.vue";

import { addDays } from "common/utils/date.js";
import { formatDatespan, formatTimespan } from "common/utils/date.js";

export default defineComponent({
  name: "EventsDetails",
  components: {
    DateInput,
    DateVue,
    DayOfWeekInput,
    PersonInput,
    RoomInputMulti,
    TimeInput,
  },
  props: ["date", "time", "room"],
  data() {
    return {
      dayOfWeek: null,
      errors: [],
      success: [],
      loading: false,
      loadingPeriods: false,
      hasError: false,
      fullscreen: false,
      deleting: false,
      periods: [],
      saving: false,
      showDayOfWeek: false,
      item: {
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        reservedFor: null,
        description: "",
        comment: "",
      },
      selectedRooms: [],
    };
  },
  computed: {
    errorMessage() {
      return this.errors.filter((el) => el).join(", ");
    },
    items() {
      if (!this.saveable) {
        return;
      }
      const result = [];
      if (!this.dayOfWeek) {
        for (const room of this.selectedRooms) {
          result.push({
            startDate: this.item.startDate,
            endDate: this.item.endDate,
            startTime: this.item.startTime,
            endTime: this.item.endTime,
            comment: this.item.comment,
            description: this.item.description,
            reservedFor: this.item.reservedFor,
            room: room,
          });
        }
      } else {
        let day = new Date(this.item.startDate);

        while (day.getDay() !== this.dayOfWeek.id) {
          day = new Date(addDays(day, 1));
        }
        const endDate = new Date(this.item.endDate);
        while (day <= endDate) {
          for (const room of this.selectedRooms) {
            result.push({
              startDate: day.toISOString().split("T")[0],
              endDate: day.toISOString().split("T")[0],
              startTime: this.item.startTime,
              endTime: this.item.endTime,
              comment: this.item.comment,
              description: this.item.description,
              reservedFor: this.item.reservedFor,
              room: room,
            });
          }
          day = new Date(addDays(day, 7));
        }
      }
      return result;
    },
    saveable() {
      return (
        this.item.startDate &&
        this.item.endDate &&
        this.item.startTime &&
        this.item.endTime &&
        this.selectedRooms.length > 0 &&
        this.item.reservedFor
      );
    },
  },
  watch: {
    selectedRooms: {
      async handler() {
        if (this.selectedRooms.length > 0) {
          this.fetchPeriods();
        }
      },
      deep: true,
    },
  },
  methods: {
    formatDatespan,
    formatTimespan,
    close() {
      this.$router.push({ name: "RoomReservation", query: this.$route.query });
    },
    async fetchPeriods() {
      this.loadingPeriods = true;
      this.periods = (
        await this.apiList({
          resource: "common/period",
          query: `room=${this.selectedRooms[0].id}`,
        })
      ).filter((el) => el.startTime != null);
      this.loadingPeriods = false;
    },
    async save() {
      this.saving = true;
      this.errors = [];
      this.success = [];
      this.hasError = false;

      for await (const item of this.items) {
        await this.apiPost({
          resource: "register/reservation",
          data: item,
          onError: (msg) => {
            this.errors.push(msg);
            this.success.push(false);
            this.hasError = true;
          },
          onSuccess: () => {
            this.errors.push(false);
            this.success.push(true);
          },
        });
      }
      setTimeout(() => {
        this.saving = false;
        if (!this.hasError) {
          const roomCodes = [
            ...this.selectedRooms.map((el) => el.code),
            ...this.$route.query.rooms,
          ];

          this.$router.push({
            name: "RoomReservation",
            query: { rooms: roomCodes },
          });
          this.$emit("dataChanged");
        }
      }, 1500);
    },
  },

  async created() {
    this.loading = true;

    this.item.startDate = this.date;
    this.item.endDate = this.date;
    this.item.startTime = this.time;
    this.item.endTime = this.time
      ? (parseInt(this.time.split(":")[0]) + 1).toString().padStart(2, "0") +
        ":" +
        this.time.split(":")[1]
      : null;

    if (this.room) {
      this.selectedRooms.push(this.room);
    }

    this.loading = false;
  },
});
</script>
