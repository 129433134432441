<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-chip outlined small v-bind="attrs" v-on="on">
        <Icon small left>mdi-account-remove</Icon>
        <PersonName :value="value.student" />
      </v-chip>
    </template>
    <span>{{ type }}: {{ value.description }}</span>
  </v-tooltip>
</template>
<script>
import { defineComponent } from "vue";
import PersonName from "common/components/PersonName.vue";
export default defineComponent({
  name: "DispensationChip",
  components: { PersonName },
  props: {
    value: {},
  },
  computed: {
    type() {
      return this.value.physicalEducation
        ? "Sportdispensation"
        : "Dispensation";
    },
  },
});
</script>
