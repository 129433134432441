<template>
  <v-dialog
    max-width="1200px"
    width="100%"
    :value="true"
    persistent
    scrollable
    @keydown.ctrl.83.prevent.stop="save"
    @keydown.meta.83.prevent.stop="save"
  >
    <v-card v-if="device">
      <v-card-title class="pa-0">
        <v-toolbar :color="color" class="mb-2">
          <v-toolbar-title>{{ device.name }} bearbeiten</v-toolbar-title>
          <v-spacer />
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-row cols="5"
          ><v-col
            ><v-card class="pa-2 mb-2">
              <v-list dense>
                <v-list-item>
                  <InventoryBrandModelPicker v-model="device.brandModel" />
                </v-list-item>
                <v-list-item>
                  <InventoryDeviceTypePicker v-model="device.deviceType" />
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="device.name"
                    label="Name"
                  ></v-text-field>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item>
                  <v-text-field
                    v-model="device.productNumber"
                    label="Produktnummer"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="device.serialNumber"
                    label="Seriennummer"
                  ></v-text-field>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item>
                  <RoomPicker v-model="device.location" />
                </v-list-item>
                <v-list-item>
                  <v-checkbox
                    v-model="device.loanable"
                    label="Leihbar"
                  ></v-checkbox>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card class="pa-2 mb-2">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-wifi </v-icon></v-list-item-icon
                  >
                  <MacAddressInput v-model="device.macAddressWlan" />
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon></v-list-item-icon>
                  <IpAddressInput v-model="device.ipv4AddressWlan" />
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-ethernet </v-icon></v-list-item-icon
                  >
                  <MacAddressInput v-model="device.macAddressLan" />
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon></v-list-item-icon>
                  <IpAddressInput v-model="device.ipv4AddressLan" />
                </v-list-item>
              </v-list> </v-card></v-col
          ><v-col cols="7"
            ><v-card class="pa-2 mb-2"
              ><v-card-subtitle>Kauf</v-card-subtitle>
              <v-list dense>
                <v-list-item>
                  <DateInput v-model="device.invoiceDate" label="Datum" />
                </v-list-item>
                <v-list-item>
                  <PriceInput
                    v-model="device.purchasePrice"
                    label="Kaufpreis"
                  />
                </v-list-item>
              </v-list>
              <v-list dense>
                <v-divider />
                <v-list-item>
                  <InventoryCompanyPicker
                    v-model="device.merchant"
                    label="Händler"
                    :nullValue="{ id: 0 }"
                  />
                </v-list-item>
                <v-list-item>
                  <InventoryCompanyPicker
                    v-model="device.service"
                    label="Service"
                    :nullValue="{ id: 0 }"
                  />
                </v-list-item>
              </v-list> </v-card
            ><v-card class="pa-2 mb-2"
              ><v-card-text>
                <v-textarea
                  rows="5"
                  label="Kommentar"
                  v-model="device.comment"
                ></v-textarea> </v-card-text></v-card></v-col
        ></v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn v-if="!create" text @click="del" color="error"
          ><v-icon left>mdi-delete</v-icon> Löschen</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn text @click="save" color="success"
          ><v-icon left>mdi-content-save</v-icon> Speichern</v-btn
        >
        <v-btn text @click="close"
          ><v-icon left>mdi-close</v-icon> Abbrechen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import InventoryBrandModelPicker from "@/components/inventory/BrandModelPicker";
import InventoryDeviceTypePicker from "@/components/inventory/DeviceTypePicker";
import InventoryCompanyPicker from "@/components/inventory/CompanyPicker";
import RoomPicker from "@/components/RoomPicker";
import MacAddressInput from "@/components/inventory/MacAddressInput";
import IpAddressInput from "@/components/inventory/IpAddressInput";

import DateInput from "common/components/DateInput.vue";
import PriceInput from "@/components/PriceInput";

export default defineComponent({
  name: "InventoryDeviceEdit",
  props: ["id"],
  components: {
    InventoryCompanyPicker,
    InventoryBrandModelPicker,
    InventoryDeviceTypePicker,
    RoomPicker,
    MacAddressInput,
    IpAddressInput,
    DateInput,
    PriceInput,
  },
  data() {
    return {
      device: null,
      color: "error",
      loading: false,
    };
  },
  computed: {
    create() {
      return this.device.id === 0;
    },
  },
  methods: {
    async save() {
      await this.apiPut({
        resource: "inventory/device",
        data: this.device,
      });

      this.$root.showNotification(
        "Device wurde gespeichert!",
        "mdi-content-save",
        "successs"
      );
      this.$router.replace({
        name: "InventoryDevice",
        params: { id: this.device.id, forceReload: true },
      });
    },
    async del() {
      if (
        await this.$root.confirm({
          message: "Soll der Device wirklich gelöscht werden?",
          color: "error",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({
          resource: "inventory/device",
          id: this.device.id,
        });
        this.$root.showNotification(
          "Device wurde gelöscht!",
          "mdi-trash-can",
          "success"
        );
        this.$router.replace({
          name: "InventoryDevices",
          params: { forceReload: true },
        });
      }
    },

    back() {
      this.$router.back();
    },
    close() {
      this.$router.push({
        name: "InventoryDevices",
      });
    },
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleDateString("de-CH", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      });
    },
  },
  async created() {
    this.loading = true;
    this.device = await this.apiGet({
      resource: "inventory/device",
      id: this.id,
    });
    this.loading = false;
  },
});
</script>
