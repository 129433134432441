<template>
  <div>
    <v-card :loading="loading" class="mb-4" v-if="id"
      ><v-card-title>{{ item.title }} </v-card-title>
      <v-card-text>
        <Markdown :value="item.description" />
      </v-card-text>
      <v-divider />
      <v-list subheader>
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-key</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Schlüsselwörter</v-list-item-subtitle>
            <v-list-item-title
              ><StaticText :value="item.keywords"
            /></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset />
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-link</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Link</v-list-item-subtitle>
            <v-list-item-title
              ><StaticText :value="item.link"
            /></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset />

        <v-list-item>
          <v-list-item-avatar
            v-if="item && item.type"
            :color="color(item.type.id)"
            size="32"
            class="white--text mr-6"
          >
            {{ item.sortOrder > -1 ? item.sortOrder : "" }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>Typ und Reihenfolge</v-list-item-subtitle>
            <v-list-item-title
              ><LookupValue :value="item.type"
            /></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset />
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-school</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Abteilung</v-list-item-subtitle>
            <v-list-item-title
              ><LookupValue :value="item.division"
            /></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset />
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-eye</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Sichtbarkeit</v-list-item-subtitle>
            <v-list-item-title
              ><LookupValue :value="item.visibility"
            /></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset />
        <v-subheader inset>Personen</v-subheader>
        <template v-if="item.people && item.people.length">
          <PersonItem
            v-for="item in item.people"
            :key="'person' + item.person.id + '_' + item.role.id"
            :value="{ ...item.person, description: item.role.description }"
          ></PersonItem>
        </template>
        <v-list-item v-else><v-list-item-icon></v-list-item-icon>—</v-list-item>
        <v-divider inset />
        <v-subheader inset>Dokumente</v-subheader>
        <template v-if="item.documents && item.documents.length">
          <v-list-item
            v-for="document in item.documents"
            :key="'document' + document.id"
          >
            <DocumentItemIcon :value="document" />

            <v-list-item-content>
              <DocumentItem :value="document" />
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item v-else><v-list-item-icon></v-list-item-icon>—</v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-btn
          text
          color="primary"
          :to="{ name: 'IndexEdit', params: { id: item.id } }"
          >bearbeiten</v-btn
        ></v-card-actions
      >
    </v-card>
    <router-view @dataChanged="dataChanged"></router-view>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DocumentItem from "@/components/DocumentItem";
import DocumentItemIcon from "@/components/DocumentItemIcon";
import PersonItem from "common/components/PersonItem.vue";
import Markdown from "@/components/Markdown.vue";
import LookupValue from "common/components/LookupValue.vue";
import StaticText from "common/components/StaticText.vue";
export default defineComponent({
  components: {
    DocumentItem,
    DocumentItemIcon,
    Markdown,
    LookupValue,
    PersonItem,
    StaticText,
  },
  props: ["id"],
  data() {
    return { item: {}, loading: false };
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  computed: {},
  methods: {
    color(id) {
      switch (id) {
        case 1:
          return "error";
        case 2:
          return "info";
        case 3:
          return "success";
        case 4:
          return "warning";
        default:
          return "grey";
      }
    },
    dataChanged() {
      this.fetchData();
      this.$emit("dataChanged");
    },
    async fetchData() {
      if (this.id) {
        this.loading = true;
        this.item = {};
        this.item = await this.apiGet({
          resource: "document/indexentry",
          id: this.id,
        });

        this.loading = false;
      }
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
