<template>
  <v-card class="mb-2">
    <v-system-bar>Notification</v-system-bar>
    <v-card-text>
      <v-row>
        <v-col
          ><v-btn @click="show" outlined text :color="color">show custom</v-btn
          ><br />
          <v-btn @click="error" outlined text color="error">show error</v-btn
          ><br />
          <v-btn @click="info" outlined text color="info">show info</v-btn
          ><br />
          <v-btn @click="success" outlined text color="success"
            >show success</v-btn
          ><br
        /></v-col>
        <v-col>
          <v-text-field
            type="text"
            label="message"
            outlined
            v-model="message"
          ></v-text-field>
          <v-text-field
            type="text"
            label="icon"
            outlined
            v-model="icon"
          ></v-text-field>
          <LookupValueInput
            outlined
            label="Farbe"
            v-model="selectedColor"
            :items="colors"
            clearable
          />
        </v-col>
      </v-row>

      <v-alert dark dense>
        <pre>
this.$root.showNotification(('{{ message }}', '{{ icon }}', '{{
            color
          }}'));</pre
        >
      </v-alert>
      <v-alert dark dense>
        <pre>this.$root.showError(('{{ message }}'));</pre>
      </v-alert>
      <v-alert dark dense>
        <pre>this.$root.showInfo(('{{ message }}'));</pre>
      </v-alert>
      <v-alert dark dense>
        <pre>this.$root.showSuccess(('{{ message }}'));</pre>
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
export default defineComponent({
  name: "NotificationDemo",
  components: { LookupValueInput },
  data() {
    return {
      message: "Schufle trumpf!",
      icon: "mdi-cards-spade",
      selectedColor: {},
      color: "primary",
      colors: [
        { id: 1, code: "primary", description: "Gymer-Blau" },
        { id: 2, code: "info", description: "Unterricht" },
        { id: 3, code: "success", description: "Organisation" },
        { id: 4, code: "error", description: "Menschen" },
        { id: 5, code: "warning", description: "myGymer" },
      ],
    };
  },
  watch: {
    selectedColor() {
      if (this.selectedColor) {
        this.color = this.selectedColor.code;
      } else {
        this.color = "";
      }
    },
  },

  methods: {
    show() {
      this.$root.showNotification(this.message, this.icon, this.color);
    },
    error() {
      this.$root.showError(this.message);
    },
    info() {
      this.$root.showInfo(this.message);
    },
    success() {
      this.$root.showSuccess(this.message);
    },
  },
  created() {
    this.selectedColor = this.colors[0];
  },
});
</script>
