<template>
  <v-card tile style="max-height: 100vh" class="overflow-x-auto">
    <v-system-bar color="info" window
      >Lektion <v-spacer></v-spacer
      ><v-btn small icon @click="$emit('close')"
        ><v-icon small>mdi-close</v-icon></v-btn
      ></v-system-bar
    >
    <div>
      <v-card-text class="title pt-8 pb-0 text-center">
        {{ value.course.title }}
      </v-card-text>

      <v-card-text class="subtitle pt-0 text-center">
        <DateValue :value="day.date" long></DateValue>,
        {{ durationText }}
      </v-card-text>

      <v-card-text class="display text-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              :color="color"
              :to="{
                name: 'CourseRegister',
                params: { course: value.course, id: value.course.id },
              }"
            >
              <v-icon color="white">{{ icon }}</v-icon>
            </v-btn>
          </template>
          <span>zum Kursbuch</span>
        </v-tooltip>
      </v-card-text>

      <v-alert
        class="mb-0"
        text
        :color="lessonStatusColor(value.status)"
        v-if="value.status && value.status.code != 'asPlanned'"
      >
        <strong>{{ value.status.description }}</strong
        ><span v-if="value.comments">: {{ value.comments }}</span>
      </v-alert>

      <v-alert
        color="danger"
        class="mb-0"
        text
        v-if="value.absence"
        icon="mdi-bed"
      >
        Sie waren in dieser Lektion abwesend!
      </v-alert>

      <v-list subheader>
        <template v-if="value.notes && (value.notes.length > 0 || canAddNote)">
          <v-subheader
            >Notizen<v-spacer></v-spacer>
            <NoteAddButton
              v-if="canAddNote"
              color="primary"
              :defaults="{
                course: value.course,
                date: day.date,
                period: value.period,
                startTime: value.startTime,
                endTime: value.endTime,
                type: { code: 'homework', description: 'Aufgaben', id: 2 },
              }"
              @updated="$emit('updated')"
              x-small
              fab
            />
          </v-subheader>
          <NoteItem
            v-for="note in value.notes"
            :value="note"
            :key="'note' + note.id"
            @updated="$emit('updated')"
          />
        </template>

        <template v-if="showAbsences && $_hasRole('teacher')">
          <v-subheader
            >Absenzen<v-spacer></v-spacer>
            <v-btn
              v-if="canAddAbsences"
              fab
              x-small
              color="white"
              :to="{
                name: 'AttendanceCheck',
                params: {
                  course: value.course.id,
                  date: day.date,
                  period: value.periodId,
                },
              }"
            >
              <AttendanceCheckStatus :value="value"></AttendanceCheckStatus>
            </v-btn>
            <AttendanceCheckStatus
              v-else
              :value="value"
            ></AttendanceCheckStatus>
          </v-subheader>
          <v-chip-group
            class="ml-4"
            column
            v-if="showAbsences && value.absences"
          >
            <AbsenceChip
              v-for="absence in value.absences"
              :value="absence"
              :key="'absence' + absence.id"
            />
          </v-chip-group>
        </template>
        <template v-if="showAbsences && value.dispensations">
          <v-subheader>Dispensationen</v-subheader>
          <v-chip-group class="ml-4" column>
            <DispensationChip
              v-for="dispensation in value.dispensations"
              :value="dispensation"
              :key="'dispensation' + dispensation.id"
            />
          </v-chip-group>
        </template>
        <template v-if="showAbsences && value.eventAbsences">
          <v-subheader>Teilnahme an Termin</v-subheader>
          <v-chip-group class="ml-4" column>
            <EventAbsenceChip
              v-for="(eventAbsence, index) in value.eventAbsences"
              :value="eventAbsence"
              :key="'eventAbsence' + index"
            />
          </v-chip-group>
        </template>
        <template v-if="showAbsences && value.justifications">
          <v-subheader>Entschuldigungen</v-subheader>
          <v-chip-group class="ml-4" column>
            <JustificationChip
              v-for="(justifications, index) in value.justifications"
              :value="justifications"
              :key="'justifications' + index"
            />
          </v-chip-group>
        </template>
        <template
          v-if="
            value.rooms &&
            (value.rooms.length > 0 ||
              value.reservations.length > 0 ||
              canAddReservation)
          "
        >
          <v-subheader
            >Zimmer
            <v-spacer></v-spacer>
            <ReservationAddButton
              v-if="canAddReservation"
              color="primary"
              :defaults="{
                course: value.course,
                date: day.date,
                startTime: value.startTime,
                endTime: value.endTime,
              }"
              @updated="$emit('updated')"
              x-small
              fab
            />
          </v-subheader>
          <RoomItems :value="value.rooms" />
          <ReservationItems
            :value="value.reservations"
            @updated="$emit('updated')"
          />
        </template>
        <template v-if="value.course.teachers">
          <v-subheader>Lehrkräfte</v-subheader>
          <PersonItem
            v-for="teacher in value.course.teachers"
            :value="teacher"
            :key="'teacher' + teacher.id"
          />
        </template>
      </v-list>

      <v-divider />
      <v-card-actions>
        <v-btn block text color="primary" @click="$emit('close')">
          Schliessen
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import AbsenceChip from "common/components/Register/AbsenceChip.vue";
import AttendanceCheckStatus from "common/components/Register/AttendanceCheckStatus.vue";
import DateValue from "common/components/DateValue.vue";
import DispensationChip from "common/components/Register/DispensationChip.vue";
import EventAbsenceChip from "common/components/Register/EventAbsenceChip.vue";
import JustificationChip from "common/components/Register/JustificationChip.vue";
import NoteAddButton from "common/components/NoteAddButton.vue";
import NoteItem from "common/components/NoteItem.vue";
import PersonItem from "common/components/PersonItem.vue";
import RoomItems from "common/components/RoomItems.vue";
import ReservationItems from "common/components/ReservationItems.vue";
import ReservationAddButton from "common/components/ReservationAddButton.vue";

import {
  lessonStatusColor,
  lessonStatusIcon,
  subjectIcon,
} from "common/utils/icons.js";
import { formatTime } from "common/utils/date.js";

export default defineComponent({
  name: "LessonSheet",
  components: {
    AttendanceCheckStatus,
    AbsenceChip,
    DateValue,
    DispensationChip,
    EventAbsenceChip,
    JustificationChip,
    NoteAddButton,
    NoteItem,
    PersonItem,
    RoomItems,
    ReservationAddButton,
    ReservationItems,
  },
  props: {
    showAbsences: { type: Boolean, default: false },
    value: null,
    day: null,
    register: {},
  },
  data() {
    return {};
  },
  computed: {
    canAddAbsences() {
      return (
        this.value &&
        !this.value.future &&
        this.value.course &&
        this.value.course.teachers.some((el) => this.$_isPerson(el)) &&
        this.value.status &&
        this.value.status.code != "canceledByEvent" &&
        this.value.status.code != "canceled"
      );
    },
    canAddNote() {
      return (
        this.value &&
        this.value.future &&
        this.value.course &&
        this.value.course.teachers.some((el) => this.$_isPerson(el))
      );
    },
    canAddReservation() {
      return (
        this.value &&
        this.value.future &&
        this.value.course &&
        this.value.course.teachers.some((el) => this.$_isPerson(el))
      );
    },
    classes() {
      if (
        this.value.status &&
        (this.value.status.code == "canceledByEvent" ||
          this.value.status.code == "canceled")
      ) {
        return "canceled";
      }
      return "";
    },
    color() {
      if (this.value.past) {
        return "grey";
      }
      return this.value.change ? "danger" : "info";
    },
    icon() {
      if (!this.value || !this.value.course) {
        return "";
      }
      return subjectIcon(this.value.course.subject, true);
    },
    durationText() {
      return this.value
        ? formatTime(this.value.startTime) +
            " – " +
            formatTime(this.value.endTime)
        : "";
    },
  },
  methods: {
    lessonStatusColor,
    lessonStatusIcon,
  },
});
</script>
