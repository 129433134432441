<template>
  <v-card flat tile :loading="loading">
    <v-container>
      <v-row>
        <v-col>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Übersicht</v-list-item-title>
                <v-list-item-subtitle
                  >über {{ portfolios.length }} Portfolios
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <ReportButton
                  icon
                  resource="report/portfoliooverview"
                  :parameters="{
                    type: 'student',
                    id: id,
                    orientation: 'portrait',
                  }"
                />
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Portfolios</v-list-item-title>
                <v-list-item-subtitle
                  >{{ portfolios.length }} Seiten
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <ReportButton
                  icon
                  resource="report/portfolio"
                  :parameters="{
                    type: 'student',
                    id: id,
                    orientation: 'portrait',
                  }"
                />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col>
          <v-list>
            <v-list-item
              v-for="portfolio in portfolios"
              v-bind:key="portfolio.id"
            >
              <v-list-item-content>
                <v-list-item-title>{{
                  portfolio.type.description
                }}</v-list-item-title>
                <v-list-item-subtitle
                  ><DateValue :value="portfolio.creationDate" />,
                  {{ portfolio.creationTime }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <ReportButton
                  icon
                  resource="report/portfolio"
                  :parameters="{
                    type: 'portfolio',
                    id: portfolio.id,
                    orientation: 'portrait',
                  }"
                />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import { saveChildRoute } from "@/router/helper";
import DateValue from "common/components/DateValue.vue";
import ReportButton from "@/components/ReportButton";

export default defineComponent({
  name: "PersonPortfolios",
  components: { DateValue, ReportButton },
  props: {
    id: String,
    value: {},
  },
  computed: {
    personId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      portfolios: [],
      loading: false,
      role: "",
    };
  },
  methods: {
    async getData() {
      if (this.personId) {
        this.loading = true;
        this.portfolios = await this.apiList({
          resource: "portfolio/portfolio",
          query: `person=${this.personId}`,
        });
        this.loading = false;
      }
    },
  },
  watch: {
    async id() {
      await this.getData();
    },
  },
  async created() {
    await this.getData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
});
</script>
