<template>
  <v-menu :close-on-content-click="true">
    <template v-slot:activator="{ on, attrs }">
      <v-chip :color="color" v-bind="attrs" v-on="on" @click:close="clear">
        <span v-if="value">{{ formated }}</span>
        <v-icon small v-if="!value">mdi-calendar-plus</v-icon>
      </v-chip>
    </template>
    <v-date-picker
      color="primary"
      :header-color="color"
      v-model="currentValue"
      :first-day-of-week="1"
      show-week
      :locale-first-day-of-year="4"
    >
      <v-btn-toggle borderless>
        <v-btn small icon @click.stop="addDays(-7)"
          ><v-icon>mdi-chevron-double-left</v-icon></v-btn
        >
        <v-btn small icon @click.stop="addDays(-1)"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-btn small text @click="ok">OK</v-btn>
        <v-btn small icon @click.stop="addDays(1)">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-btn small icon @click.stop="addDays(7)"
        ><v-icon>mdi-chevron-double-right</v-icon></v-btn
      >
      <v-spacer /><v-btn text @click="today()">heute</v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { defineComponent } from "vue";
import { formatDate } from "common/utils/date.js";
export default defineComponent({
  props: ["color", "value"],
  data() {
    return {
      currentValue: this.value,
    };
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
    currentValue() {
      this.$emit("input", this.currentValue);
      this.$emit("change", this.currentValue);
    },
  },
  methods: {
    today() {
      let today = new Date(),
        month = "" + (today.getMonth() + 1),
        day = "" + today.getDate(),
        year = today.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      this.currentValue = [year, month, day].join("-");
    },
    ok() {},
    clear() {
      this.value = null;
    },
    addDays(days) {
      let date = new Date(this.currentValue);
      date.setDate(date.getDate() + days);
      let month = "" + (date.getMonth() + 1);
      let day = "" + date.getDate();
      let year = date.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      this.currentValue = [year, month, day].join("-");
    },
  },
  computed: {
    formated() {
      return formatDate(this.value, true);
    },
  },
});
</script>
