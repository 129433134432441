<template>
  <v-card tile>
    <v-system-bar color="success" window>
      Zimmerreservation
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('close')">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-system-bar>
    <v-card-text class="title pt-8 pb-0 text-center">
      {{ value.room.description }}
    </v-card-text>
    <v-card-text class="display text-center">
      <v-avatar color="success">
        <v-icon color="white">mdi-map-marker</v-icon>
      </v-avatar>
    </v-card-text>
    <v-list>
      <v-list-item three-line :href="value.room.site.mapUrl" target="_blank">
        <v-list-item-icon><v-icon>mdi-map</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            value.room.site.description
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            value.room.site.address1
          }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="value.room.site.address2">{{
            value.room.site.address2
          }}</v-list-item-subtitle>
          <v-list-item-subtitle
            >{{ value.room.site.postcode }}
            {{ value.room.site.town }}</v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-icon><v-icon>mdi-link</v-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>reserviert von</v-list-item-subtitle>
          <v-list-item-title>
            <PersonChip :value="value.creator" />
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-subtitle>reserviert für</v-list-item-subtitle>
          <v-list-item-title>
            <PersonChip :value="value.reservedFor" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content></v-list-item-content>
        <v-list-item-action-text>
          <DateValue :value="value.creationTimeDate"></DateValue>,
          {{ formatTime(value.creationTimeTime) }}
        </v-list-item-action-text>
      </v-list-item>
    </v-list>

    <v-divider />
    <v-card-actions v-if="canDelete">
      <v-btn text color="danger" @click="deleteReservation"> Löschen </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="$emit('close')"> Schliessen </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-btn block text color="primary" @click="$emit('close')">
        Schliessen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import PersonChip from "common/components/PersonChip.vue";
import { formatTime } from "common/utils/date.js";
export default defineComponent({
  name: "RoomSheet",
  components: { DateValue, PersonChip },
  props: {
    value: null,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    canDelete() {
      return this.value.reservedFor.id == this.$_profilePerson.id;
    },
  },
  methods: {
    formatTime,

    async deleteReservation() {
      if (
        await this.$root.confirm({
          message: `Soll diese Reservation gelöscht werden?`,
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({
          resource: "register/reservation",
          id: this.value.id,
        });
        this.$emit("updated");
      }
    },
  },
});
</script>
