<template>
  <div>
    <v-card class="mb-4">
      <v-system-bar>aktuelle Screens</v-system-bar>
      <v-tabs>
        <v-tab
          exact-path
          v-for="screen in screensActive"
          :key="'tab' + screen.id"
          :to="{ name: 'ScreenScreen', params: { id: screen.id } }"
        >
          <v-icon v-if="screen.important" left color="red"
            >mdi-calendar-alert</v-icon
          >
          <v-icon
            v-else-if="screen.startDate || screen.endDate"
            left
            :color="screen.current ? 'primary' : 'grey'"
            >mdi-calendar-clock</v-icon
          >
          <v-icon left v-else :color="screen.current ? 'primary' : 'grey'"
            >mdi-calendar</v-icon
          >
          {{ screen.title }}</v-tab
        >
      </v-tabs>
    </v-card>

    <v-card>
      <v-system-bar>Screen</v-system-bar>
      <router-view @updated="fetchData"></router-view>
    </v-card>

    <v-navigation-drawer app clipped permanent right>
      <v-list nav dense>
        <v-list-item
          v-for="screen in screensFiltered"
          :key="screen.id"
          :to="{ name: 'ScreenScreen', params: { id: screen.id } }"
        >
          <v-list-item-content
            ><v-list-item-title>{{ screen.title }}</v-list-item-title
            ><v-list-item-subtitle>
              <DateValue :value="screen.startDate" />
              {{ screen.startTime }} </v-list-item-subtitle
            ><v-list-item-subtitle>
              <DateValue :value="screen.endDate" />
              {{ screen.endTime }}</v-list-item-subtitle
            ></v-list-item-content
          >
          <v-list-item-action>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  icon
                  @click.prevent="toggleImportant(screen)"
                >
                  <v-icon :color="screen.important ? 'red' : 'grey'"
                    >mdi-exclamation-thick</v-icon
                  >
                </v-btn>
              </template>
              <span>Der Screen wir auf jeden Fall angezeigt</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  icon
                  @click.prevent="toggleActive(screen)"
                >
                  <v-icon>{{
                    screen.active ? "mdi-star" : "mdi-star-outline"
                  }}</v-icon>
                </v-btn>
              </template>
              <span>Den Screen aktivieren/deaktivieren</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
export default defineComponent({
  name: "ScreenScreens",
  components: { DateValue },
  props: ["search"],
  data() {
    return {
      loading: false,
      screens: [],
    };
  },
  computed: {
    screensActive() {
      return (
        this.screens &&
        this.screens.filter(
          (item) =>
            item.active || item.important || item.id == this.$route.params.id
        )
      );
    },
    screensFiltered() {
      return (
        this.screens &&
        this.screens.filter(
          (item) =>
            !this.search ||
            item.title.toLowerCase().includes(this.search.toLowerCase())
        )
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.screens = (await this.apiList({ resource: "screen/screen" })).sort(
        (a, b) => b.lastchanged.localeCompare(a.lastchanged)
      );
      this.loading = false;
    },
    async toggleActive(screen) {
      await this.apiPatch({
        resource: "screen/screen",
        id: screen.id,
        key: "active",
        value: !screen.active,
      });
      this.fetchData();
    },
    async toggleImportant(screen) {
      await this.apiPatch({
        resource: "screen/screen",
        id: screen.id,
        key: "important",
        value: !screen.important,
      });
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
