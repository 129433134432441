<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    :loading="loading"
    hide-no-data
    :search-input.sync="search"
    :items="items"
    item-text="code"
    item-value="id"
    @change="select"
    return-object
    >{{ value ? value.code : "" }}
    <template v-slot:selection="data">
      {{ data.item.code }} ({{
        data.item.teachers.map((item) => item.code).join(", ")
      }}),&nbsp;
      <small class="grey--text">
        {{ formatDatespan(data.item.startDate, data.item.endDate) }}
      </small>
    </template>
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title
          >{{ data.item.code }} ({{
            data.item.teachers.map((item) => item.code).join(", ")
          }})
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ formatDatespan(data.item.startDate, data.item.endDate) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import { defineComponent } from "vue";
import { formatDatespan } from "common/utils/date.js";
export default defineComponent({
  name: "CourseInput",
  props: ["value", "person", "schoolClass", "startDate", "endDate"],
  data() {
    return {
      loading: false,
      search: "",
      loadedItems: [],
    };
  },
  computed: {
    items() {
      return this.value ? [this.value, ...this.loadedItems] : this.loadedItems;
    },
  },
  watch: {
    person() {
      this.fetchData();
    },
    schoolClass() {
      this.fetchData();
    },
    value() {
      if (!this.value) {
        this.search = "";
      }
    },
    search(val) {
      if (this.person || this.schoolClass) {
        return;
      }
      if (val) {
        this.fetchData();
      }
    },
  },
  methods: {
    formatDatespan: formatDatespan,
    async fetchData() {
      this.loading = true;
      const result = await this.apiList({
        resource: "course/course",
        query: `search=${this.search}${
          this.person ? "&person=" + this.person : ""
        }${
          this.schoolClass ? "&schoolClass=" + this.schoolClass : ""
        }&startDate=${this.startDate}&endDate=${this.endDate}`,
      });
      this.loadedItems = result.courses ? result.courses : result;
      this.loading = false;
    },
    remove() {
      this.$emit("input", null);
    },
    select($event) {
      this.$emit("input", $event);
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
