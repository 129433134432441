<template>
  <v-data-table
    :headers="headers"
    :items="itemsMapped"
    v-bind="$attrs"
    sortBy="timestamp"
    sortDesc
  >
    <template v-slot:item.type.code="{ item }">
      <v-icon left>{{ changeLogIcon(item.type) }}</v-icon>
      {{ item.type.description }}
    </template>
    <template v-slot:item.person="{ item }">
      <PersonItem small :value="item.person" />
    </template>
    <template v-slot:item.creator="{ item }">
      <v-chip outlined v-if="item.creator">{{ item.creator.code }}</v-chip>
      <v-tooltip top v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-cogs</v-icon>
        </template>
        <span>automatische Änderung</span>
      </v-tooltip>
    </template>
    <template v-slot:item.timestamp="{ item }">
      <DateValue :value="item.date" /> {{ item.time.substr(0, 8) }}
    </template>
  </v-data-table>
</template>
<script>
import { defineComponent } from "vue";
import { comparePeople } from "common/utils/people.js";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";
import { changeLogIcon } from "common/utils/icons";

export default defineComponent({
  name: "ChangeLogTable",
  props: {
    items: [],
    showPerson: Boolean,
  },
  components: {
    DateValue,
    PersonItem,
  },
  computed: {
    itemsMapped() {
      return this.items.map((el) => ({ ...el, timestamp: el.date + el.time }));
    },
  },
  data() {
    return {
      headers: this.showPerson
        ? [
            { text: "Person", value: "person", sort: comparePeople },
            {
              text: "Date",
              value: "timestamp",
            },

            { text: "Art", value: "type.code" },
            { text: "Beschreibung", value: "description" },
            { text: "Auslöser", value: "creator" },
          ]
        : [
            {
              text: "Zeit",
              value: "timestamp",
            },

            { text: "Art", value: "type.code" },
            { text: "Beschreibung", value: "description" },
            { text: "Auslöser", value: "creator" },
          ],
    };
  },
  methods: {
    changeLogIcon,
  },
});
</script>
