<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-cash-multiple</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Rechnungen</v-toolbar-title>

      <v-spacer />
      <SearchField v-model="search"></SearchField>
      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab :to="{ name: 'FinanceInvoicesSubmitted' }">
            eingereicht
          </v-tab>
          <v-tab :to="{ name: 'FinanceInvoicesSubmittedSap' }"
            >an SAP übermittelt
          </v-tab>
          <v-tab :to="{ name: 'FinanceInvoicesEntered' }"> verbucht </v-tab>
          <v-tab :to="{ name: 'FinanceInvoicesRejected' }"> abgelehnt </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-card><router-view :search="search"></router-view></v-card>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import SearchField from "@/components/SearchField.vue";

export default defineComponent({
  name: "FinanceInvoices",
  components: {
    SearchField,
  },
  data() {
    return {
      search: null,
    };
  },
});
</script>
