<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    :disabled="loading"
    :loading="loading"
    :filter="filterItems"
    :items="items"
    @change="select"
    return-object
  >
    <template v-slot:selection="data">
      <DocumentItemIcon :value="data.item" /><DocumentItem :value="data.item" />
    </template>
    <template v-slot:item="data">
      <DocumentItemIcon :value="data.item" />
      <v-list-item-content>
        <DocumentItem :value="data.item" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import { defineComponent } from "vue";
import DocumentItem from "@/components/DocumentItem";
import DocumentItemIcon from "@/components/DocumentItemIcon";
export default defineComponent({
  name: "DocumentInput",
  props: ["value", "visibilty"],
  components: { DocumentItem, DocumentItemIcon },
  data() {
    return {
      loading: true,
      documents: [],
    };
  },
  computed: {
    items() {
      return this.documents.filter(
        (el) => !this.visibilty || el.visibility.id >= this.visibilty.id
      );
    },
  },
  watch: {
    async value() {
      if (this.value && this.value.id) {
        if (!this.items.some((person) => person.id === this.value.id)) {
          this.items.push(this.value);
        }
      }
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      this.documents = await this.apiList({ resource: "document/document" });
      this.loading = false;
    },
    filterItems: (item, queryText) =>
      item.title.toLowerCase().includes(queryText.toLowerCase()) ||
      item.id == queryText,
    remove() {
      this.$emit("input", null);
    },
    select($event) {
      this.$emit("input", $event);
    },
  },
  async created() {
    this.getData();
  },
});
</script>
