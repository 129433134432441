<template>
  <v-tabs v-bind="$attrs" hide-slider>
    <v-tab @click="prev"><v-icon> mdi-chevron-left </v-icon></v-tab>
    <v-menu
      :close-on-content-click="true"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-tab style="width: 200px" v-bind="attrs" v-on="on">{{ title }}</v-tab>
      </template>

      <v-list style="max-height: 80vh" class="overflow-y-auto">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="select(index)"
        >
          <v-list-item-title class="text-center">{{
            item.shortText
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-tab @click="next"><v-icon> mdi-chevron-right </v-icon></v-tab>
  </v-tabs>
</template>
<script>
import { defineComponent } from "vue";
import { today } from "common/utils/date.js";

export default defineComponent({
  name: "TermTabs",
  props: {
    value: null,
  },
  data() {
    return {
      items: [],
      selectedIndex: -1,
    };
  },
  computed: {
    title() {
      if (this.selectedIndex == -1) return "";
      return this.items[this.selectedIndex].shortText;
    },
  },
  methods: {
    next() {
      if (this.selectedIndex < this.items.length - 1) ++this.selectedIndex;
      this.changed();
    },
    prev() {
      if (this.selectedIndex > 0) --this.selectedIndex;
      this.changed();
    },
    changed() {
      const selectedTerm = this.items[this.selectedIndex];
      localStorage.setItem("selectedTerm", selectedTerm.id);
      this.$emit("input", selectedTerm);
    },
    select(index) {
      this.selectedIndex = index;
      this.changed();
    },
  },
  async mounted() {
    const items = await this.apiList({ resource: "common/term" });
    const now = today();
    this.items = items;
    const selectedId = Number.parseInt(localStorage.getItem("selectedTerm"));
    let current = -1;
    for (let i = 0; i < items.length; ++i) {
      if (items[i].id === selectedId) {
        this.selectedIndex = i;
        break;
      }
      if (items[i].startDate <= now && now <= items[i].endDate) {
        current = i;
      }
    }
    if (this.selectedIndex === -1) this.selectedIndex = current;
    this.changed();
  },
});
</script>
