<template>
  <v-card v-if="isVisible" v-bind="$attrs">
    <v-system-bar>Erziehungsberechtigte</v-system-bar>
    <v-list v-for="(item, index) in items" :key="index">
      <v-divider v-if="index > 0"></v-divider>
      <ListItem title="Rolle">
        <StaticText :value="item.role" />
      </ListItem>
      <ListItem title="Name">
        {{ item.firstName }} {{ item.lastName }}
        <v-btn
          v-if="showLink"
          icon
          :to="{ name: 'PersonContact', params: { id: item.id.toString() } }"
          ><v-icon>mdi-link</v-icon></v-btn
        >
      </ListItem>
      <ListItem v-if="item.town" title="Adresse">
        <PostalAddress :person="item" />
      </ListItem>
      <ListItem title="Beruf">
        <StaticText :value="item.profession" />
      </ListItem>
      <ListItem v-if="item.phoneHome" title="Telefon privat">
        <PhoneNumber :value="item.phoneHome" />
      </ListItem>
      <ListItem v-if="item.phoneMobile" title="Mobiltelefon">
        <PhoneNumber :value="item.phoneMobile" />
      </ListItem>
    </v-list>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import ListItem from "@/components/ListItem.vue";
import PhoneNumber from "common/components/Person/PhoneNumber.vue";
import PostalAddress from "common/components/Person/PostalAddress.vue";
import StaticText from "common/components/StaticText.vue";

export default defineComponent({
  name: "ContactLegalGuardians",
  components: { ListItem, PhoneNumber, PostalAddress, StaticText },
  props: {
    person: { type: Object, default: null },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    areLegalGuardiansVisible() {
      if (!(this.person && this.person.id)) return false;
      if (this.person.type.code !== "student") return false;
      if (this.$_isPerson(this.person)) return true;
      if (this.$_hasRole("schoolAdmin")) return true;
      if (!this.person.schoolClass) return false;
      const schoolClasses = this.$_classTeacherOf;
      for (let i = 0; i < schoolClasses.length; ++i) {
        if (schoolClasses[i].id === this.person.schoolClass.id) {
          return true;
        }
      }
      return false;
    },
    hasPerson() {
      return this.person && this.person.id;
    },
    isVisible() {
      return this.hasPerson && this.items.length > 0;
    },
    showLink() {
      return this.$_isAdminApp;
    },
    showVisibilityInfo() {
      return this.$_isPerson(this.person);
    },
  },
  watch: {
    person() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.items = [];
      if (this.areLegalGuardiansVisible) {
        this.items = await this.apiGet({
          resource: "person/legalguardian",
          id: this.person.id,
        });
      }
    },
  },
});
</script>
