<template>
  <v-container>
    <div class="text-right"><PeopleSort @update="fetchData()" /></div>
    <PeoplePanel :value="items" />
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { saveChildRoute } from "@/router/helper.js";
import PeoplePanel from "common/components/PeoplePanel.vue";
import PeopleSort from "@/components/settings/PeopleSort.vue";
import { sortPeople } from "common/utils/people.js";

export default defineComponent({
  name: "CoursePortrait",
  components: { PeoplePanel, PeopleSort },
  props: ["id"],
  data() {
    return {
      course: {},
      items: [],
      loading: false,
    };
  },
  methods: {
    async fetchData() {
      if (this.id > 0) {
        this.loading = true;
        this.course = await this.apiGet({
          resource: "course/course",
          id: this.id,
        });
        this.items = sortPeople(this.course.students);
        this.loading = false;
      }
    },
  },
  watch: {
    async id() {
      await this.fetchData();
    },
  },
  async created() {
    await this.fetchData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
});
</script>
