<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    :disabled="loading"
    :loading="loading"
    :filter="filterItems"
    :items="items"
    item-text="code"
    item-value="id"
    @input="select"
    return-object
  >
    <template v-slot:selection="data">
      {{ data.item.code }}
    </template>
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.code }}</v-list-item-title>
        <v-list-item-subtitle>{{ subtitle(data.item) }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "LockerInput",
  props: ["value"],
  data() {
    return {
      loading: true,
      items: [],
    };
  },
  methods: {
    subtitle(locker) {
      if (!locker) {
        return "";
      }
      let result = "";
      if (locker.code.startsWith("P")) {
        result = "Pavillon";
      } else if (locker.code.startsWith("0")) {
        result = "Geschoss 00";
      } else if (locker.code.startsWith("1")) {
        result = "Geschoss 01";
      } else if (locker.code.startsWith("2")) {
        result = "Geschoss 02";
      } else if (locker.code.startsWith("3")) {
        result = "Geschoss 03";
      } else if (locker.code.startsWith("4")) {
        result = "Geschoss 04";
      }
      result += ", ";
      result += locker.students.length;
      result += "/";
      result += locker.size;
      return result;
    },
    filterItems: (item, queryText) => item.code.startsWith(queryText),
    async fetchData() {
      this.loading = true;
      const data = await this.apiList({
        resource: "locker/locker",
      });
      this.items = data.sort((a, b) => a.code.localeCompare(b.code));
      this.loading = false;
    },
    remove() {
      this.$emit("input", null);
    },
    select($event) {
      this.$emit("input", $event);
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
