<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="itemsTransformed"
      :search="search"
      sortBy="datetime"
      sortDesc
      :item-class="(item) => (item.error ? 'clickable' : '')"
      @click:row="(item) => showError(item)"
    >
      <template v-slot:item.person="{ item }">
        <PersonItem small :value="item.person" />
      </template>
      <template v-slot:item.datetime="{ item }">
        <DateValue :value="item.date" />, {{ formatTime(item.time) }}
      </template>
      <template v-slot:item.error="{ item }">
        <v-icon v-if="item.error" color="error">mdi-alert</v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" scrollable max-width="1200px" width="100%">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark class="mb-2">
            <v-toolbar-title> Error</v-toolbar-title>
          </v-toolbar>
        </v-card-title>

        <v-card-text>
          <v-row align="center">
            <v-col>
              <PersonItem v-if="item" :value="item.person" />
            </v-col>
            <v-col>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Datum</v-list-item-subtitle>
                  <v-list-item-title>
                    <DateValue :value="item.date" /> {{ formatTime(item.time) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>URL</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ item.url }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Methode</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ item.method }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Status</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ item.status }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <pre v-html="item.error"></pre>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";

import { formatTime } from "common/utils/date.js";

export default defineComponent({
  name: "LogDataTable",
  components: { DateValue, PersonItem },
  props: ["items", "loading", "search"],
  data() {
    return {
      dialog: false,
      item: {},
      headers: [
        { text: "", value: "error" },
        { text: "Datum", value: "datetime" },
        { text: "Anwendung", value: "application.description" },
        { text: "Person", value: "person" },
        { text: "Endpunkt", value: "resource.endpoint" },
        { text: "Methode", value: "method" },
        { text: "Status", value: "status" },
      ],
    };
  },
  computed: {
    itemsTransformed() {
      return this.items.map((el) => ({
        ...el,
        datetime: { date: el.date, time: el.time },
      }));
    },
  },
  methods: {
    formatTime: formatTime,
    showError(item) {
      if (item.error) {
        this.item = item;
        this.dialog = true;
      }
    },
  },
});
</script>
