<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-cogs</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Einstellungen</v-toolbar-title>
      <v-spacer />
      <v-text-field
        class="mr-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        clearable
        disabled
        id="search"
      ></v-text-field>
    </v-app-bar>
    <v-card max-width="500" class="mx-auto mb-4"
      ><v-system-bar>Sortierung von Personen</v-system-bar>
      <v-card-text>
        <p>
          Wie sollen Aufzählungen von Personen sortiert werden?<br />Zur
          Verfügung stehen «Sortierung nach <strong>Vornamen</strong>» und
          «Sortierung nach <strong>Nachnamen</strong>».
        </p>
        <p>
          Dies hat auch Einfluss auf die Darstellung der Namen. Entweder
          <i>Nachname, Vorname</i> oder <i>Vorname, Nachname</i>
        </p></v-card-text
      >

      <v-card-actions><v-spacer /><PeopleSort /></v-card-actions>
    </v-card>
    <v-card max-width="500" class="mx-auto mb-4"
      ><v-system-bar>Wie sollen mailto-Links behandelt werden?</v-system-bar>
      <v-card-text>
        <p>Soll mailto verwendet werden oder Outlook for Web?</p>
      </v-card-text>

      <v-card-actions><v-spacer /><EmailClient /></v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import EmailClient from "@/components/settings/EmailClient";
import PeopleSort from "@/components/settings/PeopleSort";

export default defineComponent({
  name: "Settings",
  components: { EmailClient, PeopleSort },
  data() {
    return {
      search: "",
    };
  },
});
</script>
