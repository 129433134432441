<template>
  <div>
    <v-tooltip top v-if="values && values.length > 5">
      <template v-slot:activator="{ on, attrs }">
        <v-chip outlined small v-bind="attrs" v-on="on" color="grey">
          <v-icon small left>mdi-calendar</v-icon>
          {{ values.length }} Schüler:innen
        </v-chip>
      </template>
      <span>Teilnahme an Terminen</span>
    </v-tooltip>
    <template v-else top>
      <EventAbsenceChip
        v-for="(eventAbsence, index) in values"
        :value="eventAbsence"
        :key="'eventAbsence' + index"
      />
    </template>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import EventAbsenceChip from "common/components/Register/EventAbsenceChip.vue";

export default defineComponent({
  name: "EventAbsenceChips",
  components: { EventAbsenceChip },
  props: {
    values: {},
  },
});
</script>
