<template>
  <v-card :disabled="this.items.length === 0" width="340">
    <v-system-bar color="warning">
      Geburtstagskinder<v-spacer /> {{ this.items.length }} nächstens
    </v-system-bar>

    <template v-for="item in groups">
      <v-system-bar :key="item.date">
        <DateValue long :value="item.date" />
      </v-system-bar>
      <PersonItem
        :value="entry"
        clickable
        v-for="entry in item.entries"
        :key="entry.id"
        ><v-chip small>{{ entry.year }}</v-chip></PersonItem
      >
    </template>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import { addDays, today } from "common/utils/date";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";

export default defineComponent({
  name: "Birthday",
  props: ["items"],
  data() {
    return { groups: [] };
  },
  components: { DateValue, PersonItem },
  created() {
    const todayy = today();
    const tomorrow = addDays(todayy, 1);
    const day_after_tomorrow = addDays(todayy, 2);
    this.groups = [
      {
        date: todayy,
        entries: [],
      },
      {
        date: tomorrow,
        entries: [],
      },
      {
        date: day_after_tomorrow,
        entries: [],
      },
    ];
    this.items.forEach((item) => {
      const part = item.birthday.substring(5);
      const year = item.birthday.substring(0, 4);

      item.year = year;
      let group = this.groups.find((i) => i.date.indexOf(part) !== -1);
      if (group) {
        group.entries.push(item);
      } else {
        this.groups.push({ date: part, entries: [item] });
      }
    });
    this.groups = this.groups.sort((a, b) =>
      a.date > b.date ? 1 : b.date > a.date ? -1 : 0
    );
  },
});
</script>
