<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-btn icon>
        <v-icon>mdi-clock-edit</v-icon>
      </v-btn>

      <v-toolbar-title>Sonderstundenplan</v-toolbar-title>

      <v-spacer />
      <PersonInput
        group="teacher"
        label="LK"
        hideDetails
        v-model="teacher"
        class="mr-2"
      />
      <SchoolClassInput label="Klasse" hideDetails v-model="schoolClass" />
    </v-app-bar>

    <router-view :teacher="teacher" :schoolClass="schoolClass"></router-view>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import PersonInput from "common/components/PersonInput.vue";
import SchoolClassInput from "@/components/SchoolClassInput.vue";
export default defineComponent({
  name: "LessonChanges",
  components: { PersonInput, SchoolClassInput },
  data: () => ({
    schoolClass: { id: 0, code: "" },
    teacher: { id: 0, code: "" },
  }),
  watch: {
    "teacher.id": function (newVal) {
      if (!newVal || this.$route.params.id == newVal) {
        return;
      }
      this.schoolClass = { id: 0, code: "" };
      this.$router.push({
        name: "LessonChangesTeacher",
        params: { teacherId: newVal },
      });

      localStorage.setItem(
        "lessonChangesTeacher",
        JSON.stringify(this.teacher)
      );
      localStorage.removeItem("lessonChangesSchoolClass");
    },
    "schoolClass.id": function (newVal) {
      if (!newVal || this.$route.params.id == newVal) {
        return;
      }
      this.teacher = { id: 0, code: "" };
      this.$router.push({
        name: "LessonChangesSchoolclass",
        params: { schoolClassId: newVal },
      });

      localStorage.setItem(
        "lessonChangesSchoolClass",
        JSON.stringify(this.schoolClass)
      );
      localStorage.removeItem("lessonChangesTeacher");
    },
  },
  async created() {
    if (this.$route.params.teacherId) {
      this.teacher = await this.apiGet({
        resource: "person/person",
        id: this.$route.params.teacherId,
      });
    } else if (this.$route.params.schoolClassId) {
      this.schoolClass = await this.apiGet({
        resource: "schoolclass/schoolclass",
        id: this.$route.params.schoolClassId,
      });
    } else if (localStorage.getItem("lessonChangesSchoolClass")) {
      this.schoolClass = JSON.parse(
        localStorage.getItem("lessonChangesSchoolClass")
      );
    } else if (localStorage.getItem("lessonChangesTeacher")) {
      this.teacher = JSON.parse(localStorage.getItem("lessonChangesTeacher"));
    }
  },
});
</script>
