<template>
  <v-dialog :value="true" persistent scrollable>
    <v-card>
      <v-system-bar window color="error" class="mb-2">
        Firma
        <v-spacer />

        <v-btn @click="close" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <v-system-bar
                  ><v-icon>mdi-office-building</v-icon> Firma</v-system-bar
                >
                <v-list-item>
                  <v-text-field
                    v-model="company.companyName"
                    label="Name"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.homepage"
                    label="Homepage"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.emailCompany"
                    label="E-Mail"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.phoneCompany"
                    label="Telefon"
                  ></v-text-field>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-system-bar
                  ><v-icon>mdi-account-tie</v-icon> Kontakt</v-system-bar
                >
                <v-list-item>
                  <v-text-field
                    v-model="company.contactName1"
                    label="Name 1"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.contactName2"
                    label="Name 2"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.emailContact"
                    label="E-Mail"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.phoneContact"
                    label="Telefon"
                  ></v-text-field>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><v-card>
                <v-system-bar
                  ><v-icon>mdi-comment</v-icon> Kommentar</v-system-bar
                >
                <v-list-item>
                  <v-textarea
                    label="Kommentar"
                    v-model="company.comment"
                  ></v-textarea>
                </v-list-item> </v-card
            ></v-col>
            <v-col>
              <v-card>
                <v-system-bar><v-icon>mdi-map</v-icon> Anschrift</v-system-bar>
                <v-list-item>
                  <v-text-field
                    v-model="company.address1"
                    label="Zeile 1"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.address2"
                    label="Zeile 2"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.postcode"
                    label="PLZ"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.town"
                    label="Ort"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.country"
                    label="Land"
                  ></v-text-field>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="close">Abbrechen</v-btn>
        <v-btn text @click="save" color="primary">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "InventoryCompanyEdit",
  props: ["id"],
  data: () => ({
    loading: false,
    saving: false,
    search: "",
    company: {},
  }),
  methods: {
    close() {
      this.$router.push({
        name: "InventoryCompanies",
      });
    },
    async getData() {
      if (this.id) {
        this.loading = true;
        this.company = await this.apiGet({
          resource: "inventory/company",
          id: this.id,
        });
        this.loading = false;
      }
    },
    async save() {
      this.saving = true;
      await this.apiSave({
        add: this.id == 0,
        resource: "inventory/company",
        data: this.company,
      });
      this.saving = false;
      this.$root.showSuccess("Firma erfolgreich gespeichert!");
      this.$router.back();
    },
  },
  async created() {
    this.getData();
  },
});
</script>
