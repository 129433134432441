<template>
  <div>
    <v-avatar v-if="avatar" :color="fileColor(value)">
      <v-icon v-if="value.title" color="white">{{ fileIcon(value) }}</v-icon>
      <v-icon v-else>{{ open ? "mdi-folder-open" : "mdi-folder" }}</v-icon>
    </v-avatar>
    <div v-else>
      <v-icon v-if="value.title" :color="fileColor(value)">{{
        fileIcon(value)
      }}</v-icon>
      <v-icon v-else>{{ open ? "mdi-folder-open" : "mdi-folder" }}</v-icon>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: null,
    open: { type: Boolean, default: false },
    avatar: { type: Boolean, default: false },
  },
  //props: ['value', 'open', 'avatar'],
  methods: {
    fileIcon(item) {
      if (item.url != null) {
        return "mdi-link";
      }
      if (item.fileExtension == null) return "mdi-file-outline";
      if (item.fileExtension.startsWith("do")) {
        return "mdi-file-word-outline";
      }
      if (item.fileExtension.startsWith("xl")) {
        return "mdi-file-excel-outline";
      }
      if (item.fileExtension.startsWith("pp") || item.fileExtension == "thmx") {
        return "mdi-file-powerpoint-outline";
      }
      switch (item.fileExtension) {
        case "zip":
          return "mdi-zip-box-outline";
        case "pdf":
          return "mdi-file-document-outline";
        case "gif":
        case "jpg":
        case "jpeg":
        case "png":
        case "tif":
        case "svg":
          return "mdi-file-image-outline";

        default:
          return "mdi-file-outline";
      }
    },
    fileColor(item) {
      if (item.url != null) {
        return "primary";
      }
      if (item.fileExtension == null) return "";
      if (item.fileExtension.startsWith("do")) {
        return "#035AC1";
      }
      if (item.fileExtension.startsWith("xl")) {
        return "#107B41";
      }
      if (item.fileExtension.startsWith("pp") || item.fileExtension == "thmx") {
        return "#D4512D";
      }
      switch (item.fileExtension) {
        case "zip":
          return "yellow";
        case "pdf":
          return "#f40f02";
        case "gif":
        case "jpg":
        case "jpeg":
        case "png":
        case "tif":
        case "svg":
          return "info";

        default:
          return "grey";
      }
    },
  },
});
</script>
