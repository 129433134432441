<template>
  <v-list-item
    v-bind="$attrs"
    class="px-4"
    :to="nodetails ? to : null"
    @[ev]="click"
    :value="value"
  >
    <v-list-item-content>
      <v-row no-gutters
        ><v-col>
          <v-list-item-title>{{ value ? value.title : "–" }}</v-list-item-title>
        </v-col>
        <v-col v-if="!hideExt">
          <v-list-item-subtitle class="text-right">
            {{
              value ? value.teachers.map((item) => item.code).join(", ") : ""
            }}
          </v-list-item-subtitle>
        </v-col></v-row
      >
      <v-list-item-subtitle>{{
        value ? formatDatespan(value.startDate, value.endDate) : ""
      }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="!nodetails && clickable">
      <v-btn :to="to" @click.stop icon>
        <v-icon> mdi-dots-horizontal </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { defineComponent } from "vue";
import { formatDatespan } from "common/utils/date.js";

export default defineComponent({
  name: "Course",
  props: {
    value: null,
    clickable: Boolean,
    nolink: Boolean,
    nodetails: Boolean,
    hideExt: Boolean,
  },
  computed: {
    ev() {
      return this.clickable && this.nodetails ? "click" : null;
    },
    to() {
      if (this.nolink || this.nodetail) {
        return null;
      }
      return this.clickable ? "/courses/" + this.value.id : null;
    },
  },
  methods: {
    click() {
      this.$emit("click", this.value);
    },
    formatDatespan: formatDatespan,
  },
});
</script>
