<template>
  <v-dialog max-width="700" width="100%" :value="true" scrollable>
    <v-card :loading="loading">
      <v-system-bar window :color="color">
        <v-btn icon>
          <v-icon>mdi-calendar-text</v-icon>
        </v-btn>
        {{ title }}
        <v-spacer />
        <v-btn @click="close" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-card-text>
        <RowItem align="center" title="Typ">
          <v-autocomplete
            v-model="portfolio.type"
            :items="types"
            item-text="description"
            item-value="id"
            return-object
            :disabled="!adding"
            @change="loadType"
          ></v-autocomplete>
        </RowItem>
        <RowItem align="center" title="Titel">
          <v-text-field v-model="portfolio.title" />
        </RowItem>
        <RowItem align="center" title="erstellt von">
          <PersonInput group="employee" clearable v-model="portfolio.creator" />
        </RowItem>
        <RowItem align="center" title="erstellt für">
          <PersonInput
            v-if="adding"
            search
            clearable
            v-model="portfolio.student"
          />
          <PersonItem v-else :value="portfolio.student" />
        </RowItem>
        <RowItem align="center" title="erstellt am" v-if="!adding">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <DateValue :value="portfolio.creationDate" />,
                {{ portfolio.creationTime }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </RowItem>
        <RowItem align="center" title="Comment" v-if="hasComment">
          <v-text-field v-if="adding" v-model="portfolio.comment" />
        </RowItem>
        <RowItem title="Text">
          <v-textarea
            outlined
            v-model="portfolio.text"
            :hint="
              adding
                ? '{{comment}}, {{student}}, {{division}}, {{mayear}} und {{male|female}} werden auf dem Server automatisch ersetzt'
                : ''
            "
            persistent-hint
          />
        </RowItem>
        <RowItem title="versteckt">
          <v-simple-checkbox v-model="portfolio.hidden" />
        </RowItem>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" v-if="!adding" text @click="del">Löschen</v-btn>
        <v-spacer />
        <v-btn text @click="close">schliessen</v-btn>
        <v-btn color="success" text @click="save" :disabled="!saveable"
          >Speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";
import PersonInput from "common/components/PersonInput.vue";
import RowItem from "@/components/RowItem.vue";

export default defineComponent({
  name: "PortfolioEdit",
  props: ["id"],
  components: { DateValue, PersonItem, PersonInput, RowItem },
  data() {
    return {
      portfolio: { title: "", text: "", comment: "", type: {} },
      loading: false,
      color: "error",
      title: "Portfolio bearbeiten",
      type: {},
      types: [],
    };
  },
  computed: {
    adding() {
      return this.id == 0;
    },
    hasComment() {
      return (
        this.adding &&
        this.type &&
        this.type.text &&
        this.type.text.includes("{{comment}}")
      );
    },
    saveable() {
      return (
        this.portfolio.type &&
        this.portfolio.student &&
        this.portfolio.creator &&
        (!this.hasComment || this.portfolio.comment)
      );
    },
  },
  methods: {
    close() {
      this.$router.push({
        name: "PortfolioList",
      });
    },
    async del() {
      if (
        await this.$root.confirm({
          message: "Soll das Portfolio wirklich gelöscht werden?",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({
          resource: "portfolio/portfolio",
          id: this.portfolio.id,
        });
        this.$root.showSuccess("Portfolio wurde gelöscht!");
        this.$router.replace({
          name: "PortfolioList",
        });
      }
    },
    async loadType() {
      if (this.adding) {
        this.loading = true;
        this.type = await this.apiGet({
          resource: "portfolio/type",
          id: this.portfolio.type.id,
        });
        this.portfolio.title = this.type.title;
        this.portfolio.text = this.type.text;
        this.portfolio.creator = this.type.creator;
        this.portfolio.hidden = this.type.hidden;
        this.loading = false;
      }
    },
    async save() {
      if (this.saveable) {
        let result = null;
        if (this.portfolio.id > 0) {
          result = await this.apiPut({
            resource: "portfolio/portfolio",
            data: this.portfolio,
          });
        } else {
          result = await this.apiPost({
            resource: "portfolio/portfolio",
            data: this.portfolio,
          });
        }
        const id = result ? result.id : this.portfolio.id;
        this.$root.showSuccess("Portfolio wurde gespeichert!");
        this.$router.replace({
          name: "PortfolioDetail",
          params: { id: id },
        });
      }
    },
  },
  async created() {
    this.loading = true;
    this.types = await this.apiList({ resource: "portfolio/type" });
    if (this.id > 0) {
      this.portfolio = await this.apiGet({
        resource: "portfolio/portfolio",
        id: this.id,
      });
    } else {
      this.title = "Neues Portfolio";
      this.color = "success";
    }
    this.loading = false;
  },
});
</script>
