<template>
  <div>
    <v-toolbar flat dense class="mb-2">
      <v-avatar class="mr-2"><PortraitImage :value="person" /></v-avatar>
      <PersonInput
        single-line
        hide-details
        label="Person"
        group="person"
        v-model="person"
        clearable
      />
    </v-toolbar>
    <v-card>
      <v-system-bar v-if="person"
        >Person «<PersonName :value="person"></PersonName>»</v-system-bar
      >
      <v-system-bar v-else>Bitte Person auswählen</v-system-bar>
      <LogDataTable :items="items" :search="search" :loading="loading" />
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { saveChildRoute } from "@/router/helper";
import LogDataTable from "./components/LogDataTable.vue";
import PersonInput from "common/components/PersonInput.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
export default defineComponent({
  name: "LogPerson",
  components: { LogDataTable, PortraitImage, PersonName, PersonInput },
  props: ["search"],
  data() {
    return {
      items: [],
      loading: false,
      person: {},
    };
  },
  watch: {
    async person() {
      if (this.person) {
        this.$router.push({
          name: this.$route.name,
          params: { id: this.person.id },
        });
        this.$emit("update:personId", this.person.id);
        this.loading = true;
        this.items = await this.apiList({
          resource: "app/log",
          query: "person=" + this.person.id,
        });
        this.loading = false;
      } else {
        this.items = [];
        this.$emit("update:personId", 0);
        this.$router.push({
          name: this.$route.name,
          params: { id: 0 },
        });
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
  async created() {
    if (this.$route.params.id > 0) {
      this.person = await this.apiGet({
        resource: "person/person",
        id: this.$route.params.id,
      });
      this.$emit("update:personId", this.person.id);
    }
  },
});
</script>
