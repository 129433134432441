<template>
  <div class="d-flex justify-center flex-wrap">
    <v-bottom-sheet
      inset
      max-width="500"
      scrollable
      v-for="item in value"
      :key="`card${item.id}`"
    >
      <template v-slot:activator="{ on }">
        <v-card outlined width="200" v-bind="$attrs" v-on="on">
          <PortraitImage large :value="item" />
          <v-card-text class="text-center">
            <strong><PersonName :value="item" flags /></strong>
            <div v-if="item.description">{{ item.description }}</div>
          </v-card-text>
        </v-card>
      </template>
      <PersonSheet :value="item" v-bind:attrs="$attrs" />
    </v-bottom-sheet>
  </div>
</template>
<script>
import { defineComponent } from "vue";

import PersonName from "common/components/PersonName";
import PersonSheet from "@/components/PersonSheet.vue";
import PortraitImage from "common/components/PortraitImage.vue";
export default defineComponent({
  name: "PeoplePanel",
  components: { PersonName, PersonSheet, PortraitImage },
  props: {
    value: [],
  },
});
</script>
<style scoped>
.d-flex {
  gap: 1rem;
}
</style>
