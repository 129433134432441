<template>
  <address>
    {{ person.address1 }}<br />
    <span v-if="person.address2">{{ person.address2 }}<br /></span>
    {{ person.postcode }}&nbsp;{{ person.town }}
    <CopyButton :value="value" />
  </address>
</template>
<script>
import { defineComponent } from "vue";
import CopyButton from "common/components/CopyButton.vue";
export default defineComponent({
  name: "PostalAddress",
  components: { CopyButton },
  props: ["person"],
  computed: {
    value() {
      let result = this.person.address1 + "\r\n";
      if (this.person.address2) {
        result = result + this.person.address2 + "\r\n";
      }
      result = result + this.person.postcode + " " + this.person.town;
      return result;
    },
  },
});
</script>
