<template>
  <v-card class="mb-2">
    <v-system-bar>DivisionTabs</v-system-bar>
    <v-card-text>
      <p>Tab-Auswahl der Abteilungen</p>
      <v-row>
        <v-col><DivisionTabs :all="all" /></v-col>
        <v-col>
          <v-checkbox
            v-model="all"
            :label="`all: ${all.toString()}`"
            persistent-hint
            hint="Tab für alle anzeigen"
          ></v-checkbox>
        </v-col>
      </v-row>

      <code>&lt;DivisionTabs :all="{{ all }}" /&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import DivisionTabs from "@/components/DivisionTabs";
export default defineComponent({
  name: "DivisionTabsDemo",
  components: { DivisionTabs },
  data() {
    return {
      all: false,
    };
  },
});
</script>
