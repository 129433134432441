<template>
  <v-container>
    <div class="text-right"><PeopleSort @update="fetchData()" /></div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:item.student="{ item }">
        <PersonItem small flags hideExt :value="item" />
      </template>
      <template v-slot:item.bilingual="{ item }">
        <LookupValue short :value="item.bilingual" />
      </template>
      <template v-slot:item.artsSubject="{ item }">
        <LookupValue short :value="item.artsSubject" />
      </template>
      <template v-slot:item.thirdLanguage="{ item }">
        <LookupValue short :value="item.thirdLanguage" />
      </template>
      <template v-slot:item.majorSubject="{ item }">
        <LookupValue short :value="item.majorSubject" />
      </template>
      <template v-slot:item.majorSubjectPlus="{ item }">
        <LookupValue short :value="item.majorSubjectPlus" />
      </template>
      <template v-slot:item.complementarySubject="{ item }">
        <LookupValue short :value="item.complementarySubject" />
      </template>
      <template v-slot:item.languagePlus="{ item }">
        <LookupValue short :value="item.languagePlus" />
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { saveChildRoute } from "@/router/helper.js";
import PersonItem from "common/components/PersonItem.vue";
import PeopleSort from "@/components/settings/PeopleSort.vue";
import { sortPeople } from "common/utils/people.js";
import LookupValue from "common/components/LookupValue.vue";

export default defineComponent({
  name: "SchoolClassList",
  components: {
    LookupValue,
    PersonItem,
    PeopleSort,
  },
  props: {
    id: { type: String },
  },
  computed: {
    schoolClassId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      schoolClass: {},
      loading: false,
      items: [],
      headers: [
        { text: "Schüler*in", value: "student", sortable: false },
        { text: "BI", value: "bilingual" },
        { text: "KU", value: "artsSubject" },
        { text: "S3", value: "thirdLanguage" },
        { text: "SF", value: "majorSubject" },
        { text: "SF+", value: "majorSubjectPlus" },
        { text: "EF", value: "complementarySubject" },
        { text: "S+", value: "languagePlus" },
      ],
    };
  },
  methods: {
    async fetchData() {
      if (this.schoolClassId) {
        this.loading = true;
        this.schoolClass = await this.apiGet({
          resource: "schoolclass/schoolclass",
          id: this.schoolClassId,
        });
        this.items = sortPeople(this.schoolClass.students);
        this.loading = false;
      }
    },
  },
  watch: {
    async id() {
      await this.fetchData();
    },
  },
  async created() {
    await this.fetchData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
});
</script>
