<template>
  <div>
    <v-btn
      fab
      absolute
      right
      small
      color="success"
      :to="{ name: 'DocumentEdit', params: { docId: 0, catId: 0 } }"
      ><v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-breadcrumbs :items="[{ text: 'Suche' }, { text: this.search }]">
    </v-breadcrumbs>
    <v-card :loading="loading" class="mb-4">
      <v-card-title
        >Suchergebnisse
        <v-spacer />
        <v-chip>
          {{ items.length }}
        </v-chip>
      </v-card-title>
      <DocumentList :documents="items" />
    </v-card>
    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DocumentList from "./DocumentList.vue";
export default defineComponent({
  components: { DocumentList },
  props: ["search"],
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = [];
      this.items = await this.apiList({
        resource: "document/document",
        query: `search=${this.search}`,
      });
      this.loading = false;
    },
  },
  watch: {
    search() {
      this.fetchData();
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.update) {
      this.fetchData();
    }
    next();
  },
});
</script>
