<template>
  <v-autocomplete
    :value="value"
    :disabled="items.length < 1"
    :items="items"
    label="Semester"
    item-text="shortText"
    item-value="id"
    @change="select"
    v-bind="$attrs"
    return-object
  >
  </v-autocomplete>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "TermInput",
  props: {
    value: null,
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    select($event) {
      this.$emit("input", $event);
    },
  },
  async mounted() {
    this.items = await this.apiList({ resource: "common/term" });
  },
});
</script>
