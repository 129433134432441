<template>
  <v-alert border="left" type="error">
    <slot
      >Dieser Bereich befindet sich noch im Aufbau und funktioniert noch
      nicht.</slot
    >
  </v-alert>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Todo",
});
</script>
