<template>
  <v-card tile>
    <v-system-bar color="success" window>
      Zimmer
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('close')">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-system-bar>
    <v-card-text class="title pt-8 pb-0 text-center">
      {{ value.description }}
    </v-card-text>
    <v-card-text class="display text-center">
      <v-avatar color="success">
        <v-icon color="white">mdi-map-marker</v-icon>
      </v-avatar>
    </v-card-text>
    <v-list three-line>
      <v-list-item :href="value.site.mapUrl" target="_blank">
        <v-list-item-icon><v-icon>mdi-map</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ value.site.description }}</v-list-item-title>
          <v-list-item-subtitle>{{ value.site.address1 }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="value.site.address2">{{
            value.site.address2
          }}</v-list-item-subtitle>
          <v-list-item-subtitle
            >{{ value.site.postcode }}
            {{ value.site.town }}</v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-icon><v-icon>mdi-link</v-icon></v-list-item-icon>
      </v-list-item>
    </v-list>

    <v-divider />
    <v-card-actions>
      <v-btn block text color="primary" @click="$emit('close')">
        Schliessen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "RoomSheet",
  components: {},
  props: {
    value: null,
  },
  data() {
    return {
      dialog: false,
    };
  },
});
</script>
