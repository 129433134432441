<template>
  <div>
    <v-alert type="info" prominent border="left">
      <v-row align="center">
        <v-col class="grow">
          {{ countCreatable }} Portfolios können automatisch für
          {{ schoolYear ? schoolYear.description : "" }} erzeugt werden.
          {{ countCreated }} wurden bereits erzeugt.
        </v-col>
        <v-col class="shrink">
          <v-btn
            :disabled="countCreatable == 0"
            @click="createAll"
            :loading="creating"
            >erzeugen</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="itemsFiltered"
        :items-per-page="15"
        :loading="loading"
        item-key="index"
        show-expand
      >
        <template v-slot:item.institution="{ item }">
          <v-list-item class="px-4" nodetails>
            <v-list-item-avatar color="grey lighten-3">
              <v-icon v-if="item.placement.institution.instName"
                >mdi-office-building</v-icon
              >
              <v-icon v-else>mdi-account-multiple</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.placement.institution.instName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.placement.institution.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item.student="{ item }">
          <PersonItem small :value="item.placement.student" />
        </template>
        <template v-slot:item.course="{ item }">
          <Course :value="item.course" />
        </template>
        <template v-slot:item.id="{ item }">
          <v-btn
            icon
            v-if="item.portfolio"
            :to="{ name: 'PortfolioDetail', params: { id: item.portfolio.id } }"
            ><v-icon>mdi-text-box</v-icon></v-btn
          >
          <v-btn color="success" icon v-else @click="create(item)"
            ><v-icon>mdi-creation</v-icon></v-btn
          >
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-card max-width="400" class="my-4">
              <v-card-text>
                <Markdown :value="portfolioText(item.placement)"></Markdown>
              </v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { formatDate } from "common/utils/date.js";
import { searchPerson } from "common/utils/people.js";

import Markdown from "@/components/Markdown.vue";
import PersonItem from "common/components/PersonItem.vue";
export default defineComponent({
  name: "Placements",
  props: ["search", "schoolYear"],
  components: { Markdown, PersonItem },
  data: () => ({
    creating: false,
    headers: [
      { text: "für", value: "student", sortable: false },
      { text: "bei", value: "institution", sortable: false },
      { text: "", value: "id", sortable: false },
    ],
    items: [],
    loading: false,
  }),
  computed: {
    countCreatable() {
      return this.items.filter((item) => !item.portfolio).length;
    },
    countCreated() {
      return this.items.filter((item) => item.portfolio).length;
    },
    itemsFiltered() {
      return this.itemsWithIndex.filter(
        (item) =>
          !this.search || searchPerson(item.placement.student, this.search)
      );
    },
    itemsWithIndex() {
      return this.items.map((items, index) => ({
        ...items,
        index: index,
      }));
    },
  },
  watch: {
    schoolYear() {
      this.getData();
    },
  },
  methods: {
    async create(item) {
      const data = {
        type: 32,
        student: item.placement.student.id,
        comment: this.portfolioText(item.placement),
      };
      await this.apiPost({ resource: "portfolio/portfolio", data: data });
      this.getData();
    },
    async createAll() {
      this.creating = true;
      await Promise.all(
        this.items.map(async (item) => {
          if (!item.portfolio) {
            const data = {
              type: 32,
              student: item.placement.student.id,
              comment: this.portfolioText(item.placement),
            };
            await this.apiPost({ resource: "portfolio/portfolio", data: data });
          }
        })
      );
      this.creating = false;
      this.getData();
    },
    async getData() {
      this.loading = true;
      if (this.schoolYear) {
        this.items = await this.apiList({
          resource: "portfolio/placement",
          query: `schoolYear=${this.schoolYear.id}`,
        });
      }
      this.loading = false;
    },
    portfolioText(placement) {
      return `**${placement.student.firstName} ${
        placement.student.lastName
      } hat vom ${formatDate(placement.startDate)} bis ${formatDate(
        placement.endDate
      )} das Stage Pratique mit Erfolg absolviert** bei\n\n${
        placement.institution.address
      }`;
    },
    showPortfolio(id) {
      this.$router.push({
        name: "PortfolioDetail",
        params: { id: id },
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "PortfolioPlacements") {
      this.getData();
    }
    next();
  },
  created() {
    this.getData();
  },
});
</script>
