<template>
  <v-container>
    <v-data-iterator
      :items="items"
      item-key="title"
      hide-default-footer
      :search="search"
    >
      <template v-slot:default="{ items }">
        <v-row>
          <v-col
            v-for="(item, index) in items"
            :key="index"
            cols="12"
            sm="6"
            md="6"
            lg="4"
          >
            <v-card>
              <v-card-title>
                <h4>{{ item.title }}</h4>
              </v-card-title>
              <v-card-subtitle>
                {{ item.subtitle }}
              </v-card-subtitle>
              <v-card tile dark flat v-if="item.code">
                <v-card-text>
                  <code>{{ item.code }}</code>
                </v-card-text>
              </v-card>
              <v-card-text>{{ item.description }}</v-card-text>
              <v-divider />
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Notes",
  props: ["search"],
  data() {
    return {
      items: [
        {
          title: "Dialog",
          subtitle: "mit ESC schliessen",
          code: '@click:outside="close" @keydown.esc="close"',
          description:
            "Dialog können automatisch mit ESC geschlossen werden. Dabei wird aber die URL nicht angepasst was zu Problemen führt! Das Gleiche gilt für einen Kick ausserhalb.",
        },
        {
          title: "Route",
          subtitle: "default-Route für Gruppe",
          code: 'path: ""',
          description:
            "Standard-Unterroute für eine Gruppe als children definieren mit leerem Pfad.",
        },
        {
          title: "Route",
          subtitle: "dynamisches redirect",
          code: "beforeEnter: (to, from, next) => loadChildRoute(to, next, fullDefaultRoutePath),",
          description:
            "Redirect mit Funktion: z.B. kann die zuletzt besuchte ID aus dem localstorage gelesen werden. Die Funktion loadChildRoute übernimmt das.",
        },
        {
          title: "Route",
          subtitle: "Unterroute merken",
          code: "beforeEnter: (to, from, next) => saveChildRoute(to, next),",
          description:
            "Beim Besuchen einer child-Route sich merken wo man war, damit diese Route beim nächsten Besuch wiederverwendet werden kann. Die Hilfefunktion saveChildRoute übernimmt dies.",
        },
        {
          title: "Route",
          subtitle: "Unterroute merken für id",
          code: "beforeRouteUpdate: (to, from, next) => saveChildRoute(to, next),",
          description:
            "Bei Routen die Komponenten wiedervewenden (also nur id wechseln), muss dies im Komponent beim beforeRouteUpdate geschehen!",
        },
      ],
    };
  },
});
</script>
