<template>
  <v-chip
    v-bind="$attrs"
    outlined
    :to="{
      name: 'SchoolClass',
      params: { id: value.id },
    }"
  >
    <Icon left small>mdi-account-group</Icon>
    {{ value.code }}
  </v-chip>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SchoolClassChip",
  props: {
    value: { type: Object, default: null },
  },
});
</script>
