<template>
  <v-card class="mb-2">
    <v-system-bar>PortraitImage</v-system-bar>
    <v-card-text>
      <p>
        Lädt das Portrait herunter und präsentiert dieses in einem v-img. Lässt
        sich z.B. in einem v-avatar verwenden. Mit dem prop
        <code>large</code> wird die Print-Version geladen.
      </p>

      <v-row>
        <v-col><PortraitImage large :value="person" /></v-col
        ><v-col
          ><v-avatar><PortraitImage :value="person" /></v-avatar
        ></v-col>
        <v-col
          ><v-text-field
            type="number"
            label="personId"
            outlined
            v-model="id"
          ></v-text-field
        ></v-col>
      </v-row>

      <code
        >&lt;v-avatar&gt;&lt;PortraitImage :value="person"
        /&gt;&lt;/v-avatar&gt;</code
      ><br />
      <code>&lt;PortraitImage :value="person" large /&gt;</code>
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import PortraitImage from "common/components/PortraitImage";
export default defineComponent({
  name: "PortraitImageDemo",
  components: { PortraitImage },
  data() {
    return { id: 28, person: null };
  },
  methods: {
    async getData() {
      this.person = await this.apiGet({
        resource: "person/person",
        id: this.id,
      });
    },
  },
  watch: {
    id() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
});
</script>
