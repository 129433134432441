<template>
  <v-list-item
    v-bind="$attrs"
    v-on="{ ...$listeners }"
    v-if="value"
    :value="value"
  >
    <v-list-item-avatar>
      <PortraitImage :value="value" v-bind="$attrs" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <PersonName
          :value="value"
          :flags="flags"
          :ext="value.description != null"
        />
      </v-list-item-title>
      <v-list-item-subtitle class="text-wrap">
        {{ value.description ? value.description : personCode(value) }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <slot />
  </v-list-item>
</template>
<script>
import { defineComponent } from "vue";
import { personCode } from "common/utils/people";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
export default defineComponent({
  name: "PersonItemBasic",
  components: {
    PersonName,
    PortraitImage,
  },
  props: {
    addExt: { type: Boolean, default: false },
    value: null,
    flags: { type: Boolean, default: false },
  },
  methods: {
    personCode,
  },
});
</script>
