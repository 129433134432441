<template>
  <v-container fluid>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-owl</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>smartlearn-Proben</v-toolbar-title>
      <v-spacer />

      <v-text-field
        class="mr-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche (Titel, Kurs, Klasse, Name, Kürzel)"
        single-line
        hide-details
        clearable
        id="search"
      ></v-text-field>
    </v-app-bar>
    <v-toolbar flat>
      <TermPicker v-model="term" outlined depressed></TermPicker>
      <v-spacer> </v-spacer>
      <v-switch
        v-model="hidePast"
        label="vergangene verbergen"
        inset
        hideDetails
      ></v-switch>
    </v-toolbar>
    <v-card>
      <v-data-table
        disable-pagination
        hide-default-footer
        hide-default-header
        :headers="headers"
        :items="itemsFiltered"
        item-key="id"
        disable-filtering
        disable-sort
        show-expand
        group-by="date"
        :item-class="
          (item) => {
            return item.type.code == 'exam' ? '' : 'grey lighten-4';
          }
        "
      >
        <template v-slot:group.header="{ group, headers }">
          <th :colspan="headers.length" class="ma-0 pa-0" style="height: auto">
            <v-system-bar
              ><DateValue :value="group" full></DateValue
            ></v-system-bar>
          </th>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <RegisterLessons
              class="mt-4"
              v-for="teacher in item.course.teachers"
              :key="'register' + teacher.id"
              :person="teacher"
              :startDate="item.date"
              :endDate="item.date"
              :startTime="item.period.startTime"
              :endTime="item.period.endTime"
            ></RegisterLessons>
          </td>
        </template>
        <template v-slot:item.period="{ item }">
          <v-list-item dense>
            <v-list-item-content>
              {{ item.period.startTime }}–{{ item.period.endTime }}
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item.event="{ item }">
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title> {{ item.title }} </v-list-item-title>
              <v-list-item-subtitle
                style="max-width: 400px"
                class="text-truncate"
              >
                {{ item.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item.type="{ item }">
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.type.description }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.plannedExam ? "eingetragen" : "–" }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:item.course="{ item }">
          <Course dense nodetails hideExt :value="item.course" />
        </template>
        <template v-slot:item.room="{ item }">
          <SmartLearnRoom
            v-for="teacher in item.course.teachers"
            :key="'room' + teacher.id"
            :person="teacher"
            :date="item.date"
            :period="item.period"
          ></SmartLearnRoom>
        </template>
        <template v-slot:item.teachers="{ item }">
          <v-chip-group column>
            <PersonItem
              small
              v-for="teacher in item.course.teachers"
              :key="teacher.id"
              :value="teacher"
            />
          </v-chip-group>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import Course from "@/components/Course.vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";
import RegisterLessons from "common/components/Register/RegisterLessons.vue";
import SmartLearnRoom from "./SmartLearnRoom.vue";
import TermPicker from "common/components/TermPicker.vue";

import { today } from "common/utils/date.js";
import { searchPerson } from "common/utils/people.js";

export default defineComponent({
  name: "SmartLearn",
  components: {
    Course,
    DateValue,
    PersonItem,
    RegisterLessons,
    SmartLearnRoom,
    TermPicker,
  },
  data() {
    return {
      hidePast: true,
      headers: [
        {
          text: "Lektion",
          value: "period",
        },
        {
          text: "Typ",
          value: "type",
        },
        {
          text: "Event",
          value: "event",
        },
        {
          text: "Kurs",
          value: "course",
        },
        {
          text: "Zimmer",
          value: "room",
        },
        {
          text: "LK",
          value: "teachers",
        },
      ],
      items: [],
      loading: false,
      search: null,
      term: {},
    };
  },
  watch: {
    term() {
      this.fetchData();
    },
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          (!this.hidePast || el.date >= today()) &&
          (!this.search ||
            el.title.toLowerCase().includes(this.search.toLowerCase()) ||
            el.course.title.toLowerCase().includes(this.search.toLowerCase()) ||
            el.course.teachers.some((teacher) =>
              searchPerson(teacher, this.search)
            ))
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "register/note",
        query: `smartlearn&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });

      this.loading = false;
    },
  },
});
</script>
<style lang="css" scoped></style>
