<template>
  <div>
    <v-card v-if="loading || updating" loading v-bind="$attrs">
      <v-system-bar> </v-system-bar>
      <v-card-text></v-card-text
    ></v-card>
    <template v-if="!loading">
      <v-card
        v-bind="$attrs"
        v-for="(day, index) in register.days"
        :key="'day' + index"
        :class="index < register.days.length - 1 ? 'mb-4' : ''"
      >
        <div v-if="day.holiday">
          <v-system-bar :id="day.current ? 'today' : ''">
            <DateSpan
              long
              :value="[day.holiday.startDate, day.holiday.endDate]"
            ></DateSpan>
          </v-system-bar>
          <v-list dense class="py-0">
            <HolidayItem :value="day.holiday" />
          </v-list>
        </div>
        <template v-else>
          <v-system-bar
            :color="day.current ? 'primary' : ''"
            :dark="day.current"
            :id="day.current ? 'today' : ''"
          >
            <DateValue :value="day.date" full></DateValue>
            {{ day.canceledBy ? " – Ausfall" : "" }}
            {{ day.current ? "&nbsp;(heute)" : "" }}
          </v-system-bar>

          <v-list dense class="py-0" expand v-if="!day.divider">
            <template v-if="day.canceledBy">
              <EventItem :value="day.canceledBy" />
              <v-divider></v-divider>
            </template>
            <template v-if="showEvents && day.events && day.events.length > 0">
              <v-list-group :key="'events' + day.date" :value="false">
                <template v-slot:activator>
                  <v-list-item-title> Termine</v-list-item-title>
                  <v-chip color="transparent" small>{{
                    day.events.length
                  }}</v-chip>
                </template>
                <v-divider />
                <EventItem
                  v-for="item in day.events"
                  :key="item.id"
                  :value="item"
                />
              </v-list-group>
              <v-divider />
            </template>
            <div v-if="day.lessons && day.lessons.length > 0">
              <LessonItem
                v-for="item in day.lessons"
                :key="item.id"
                :value="item"
                :register="register"
                :day="day"
                @updated="update"
                :showAbsences="showAbsences"
                :showAbsenceToggleButton="showAbsenceToggleButton"
              />
            </div>

            <v-list-item v-else disabled>
              <v-list-item-subtitle>kein Unterricht</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </template>
      </v-card>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DateSpan from "common/components/DateSpan.vue";
import DateValue from "common/components/DateValue.vue";
import EventItem from "common/components/EventItem.vue";
import HolidayItem from "common/components/HolidayItem.vue";
import LessonItem from "./LessonItem.vue";

import { today, isCurrent, isFuture, isPast } from "common/utils/date.js";

export default defineComponent({
  name: "RegisterLessons",
  components: { DateSpan, DateValue, EventItem, HolidayItem, LessonItem },
  props: {
    startDate: String,
    startTime: String,
    endDate: String,
    endTime: String,
    course: {},
    schoolClass: {},
    person: {},
    showAbsences: { type: Boolean, default: true },
    showEvents: { type: Boolean, default: false },
    showAbsenceToggleButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      register: { days: [] },
      loading: false,
      updating: false,
    };
  },
  watch: {
    course() {
      this.reset();
      this.load();
    },
    dateSpan() {
      this.load();
    },
    schoolClass() {
      this.reset();
      this.load();
    },
    person() {
      this.reset();
      this.load();
    },
    startTime() {
      this.load();
    },
    endTime() {
      this.load();
    },
  },
  computed: {
    dateSpan() {
      return [this.startDate, this.endDate];
    },
  },
  methods: {
    isCurrent,
    isFuture,
    isPast,
    isNew(item) {
      const deadline = new Date(this.$_currentTerm.startDate).getTime();
      const value = new Date(item.lastModified).getTime();
      return deadline < value;
    },
    reset() {
      this.register = { days: [] };
    },
    async load() {
      this.loading = true;
      await this.fetchData();
      this.loading = false;
    },
    async update() {
      this.updating = true;
      await this.fetchData();
      this.updating = false;
    },
    async fetchData() {
      if (!this.startDate || !this.endDate) {
        return;
      }
      if (this.schoolClass && this.schoolClass.id) {
        this.register = await this.apiList({
          resource: "register/register",
          query: `schoolClass=${this.schoolClass.id}&startDate=${this.startDate}&startTime=${this.startTime}&endDate=${this.endDate}&endTime=${this.endTime}`,
        });
      } else if (this.course && this.course.id) {
        this.register = await this.apiList({
          resource: "register/register",
          query: `course=${this.course.id}`,
        });
        if (!this.register.days.some((el) => el.date == today())) {
          let index = this.register.days.findIndex((el) => el.date > today());
          this.register.days.splice(index, 0, {
            date: today(),
            current: true,
            divider: true,
          });
        }
      } else if (this.person && this.person.id) {
        const type =
          this.person.type.code === "student" ? "student" : "teacher";
        this.register = await this.apiList({
          resource: "register/register",
          query: `${type}=${this.person.id}&startDate=${this.startDate}&startTime=${this.startTime}&endDate=${this.endDate}&endTime=${this.endTime}`,
        });
      }
      for (const day of this.register.days) {
        if (day.events) {
          day.events = day.events.map((el) => ({
            ...el,
            changed: this.isNew(el),
            current: this.isCurrent(el),
            past: this.isPast(el),
            future: this.isFuture(el),
          }));
        }
      }
    },
  },
  async created() {
    this.load();
  },
});
</script>
