<template>
  <v-container>
    <v-app-bar dark app color="primary" clipped-right>
      <v-app-bar-nav-icon>
        <v-icon>mdi-door</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title
        >Raumreservation {{ formatDatespan(start, end) }}</v-toolbar-title
      >
      <v-spacer />

      <LookupValueInput
        :items="roomCategoryTypes"
        v-model="roomCategoryType"
        single-line
        hide-details
      ></LookupValueInput>

      <v-switch
        small
        hideDetails
        inset
        v-model="showNavigation"
        class="ml-2"
        label="Navigation"
        >neu</v-switch
      >
    </v-app-bar>

    <v-row>
      <v-col>
        <v-toolbar flat class="mb-4">
          <v-btn outlined text @click="setToday" class="mr-2">Heute</v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="prev()"
                v-if="!$vuetify.breakpoint.mobile"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span>früher</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="next()"
                v-if="!$vuetify.breakpoint.mobile"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            <span>später</span>
          </v-tooltip>

          <v-menu
            v-model="dateMenu"
            offset-y
            :nudge-left="40"
            :close-on-content-click="true"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined text v-bind="attrs" v-on="on" class="mr-4">
                {{ formatDate(focus, true) }}</v-btn
              >
            </template>

            <v-date-picker
              color="primary"
              v-model="focus"
              locale="de-CH"
              :first-day-of-week="1"
              show-week
              :locale-first-day-of-year="4"
              @input="menu = false"
            >
            </v-date-picker>
          </v-menu>

          <RoomInputMulti v-model="selectedRooms" query clearable />

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-cogs</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                ><v-switch inset v-model="showEvents" label="Termine anzeigen"
              /></v-list-item>
              <v-list-item
                ><v-switch
                  inset
                  v-model="showWeekend"
                  label="Wochenende anzeigen"
              /></v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>

        <v-card :loading="loading">
          <v-calendar
            class="mb-8"
            locale="de-CH"
            ref="calendar"
            v-model="focus"
            color="primary"
            :type="calendarView"
            :categories="categories"
            :category-text="getCategoryText"
            :events="eventsFiltered"
            :weekdays="weekDays"
            first-interval="7:00"
            interval-count="16"
            category-days="1"
            @change="fetchReservations"
            @click:date="showDate"
            @click:event="showEvent"
            @click:time-category="setRoom"
            @click:time="doubleClick"
            category-show-all
            category-hide-dynamic
          >
            <template v-slot:category="{ category }">
              <div class="v-calendar-category__category">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ category.code }}
                    </span>
                  </template>
                  <span>{{ category.description }}</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:day-body="{ date, category }">
              <div
                :style="{ position: 'absolute', bottom: 0, width: '100%' }"
                class="d-flex justify-center"
              >
                <div class="text-caption" v-if="weekView || dialog">
                  <DateValue :value="date" long />
                </div>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ category.code }}
                    </span>
                  </template>
                  <span>{{ category.description }}</span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:event="{ event, eventSummary }">
              <v-tooltip top v-if="event.comment">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="float-right"
                    v-bind="attrs"
                    v-on="on"
                    dark
                  >
                    mdi-information
                  </v-icon>
                </template>
                <span>{{ event.comment }}</span>
              </v-tooltip>
              <span>
                <component :is="{ render: eventSummary }"></component>
              </span>
            </template>
          </v-calendar>
        </v-card>
        <Todo>ab hier noch im Aufbau (Konflikte)</Todo>
        <v-alert type="error" v-if="conflictsFiltered.length > 0">
          <pre>{{ conflictsFiltered }}</pre>
        </v-alert>
        <router-view
          :date="date"
          :time="time"
          :room="room"
          @dataChanged="fetchReservations"
        />
        <v-dialog
          v-model="dialog"
          width="auto"
          :fullscreen="fullscreen"
          scrollable
        >
          <v-card :loading="loading">
            <v-toolbar outlined flat
              ><v-toolbar-title
                >{{ selectedRooms.map((el) => el.code).join(", ") }} –
                <DateVue :value="focus" long
              /></v-toolbar-title>
              <v-spacer /><v-toolbar-items>
                <v-btn icon @click="fullscreen = !fullscreen"
                  ><v-icon>{{
                    fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen"
                  }}</v-icon></v-btn
                >
                <v-btn icon @click="dialog = false"
                  ><v-icon>mdi-close</v-icon></v-btn
                ></v-toolbar-items
              ></v-toolbar
            >
            <v-card-text class="fill-height">
              <v-row>
                <v-col v-for="room in selectedRooms" :key="room.id">
                  <v-list three-line v-if="!loading">
                    <v-subheader>{{ room.description }}</v-subheader>
                    <v-list-item
                      v-for="event in eventsFiltered.filter(
                        (el) => el.category == room.description
                      )"
                      :key="event.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ event.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ event.type }}
                          {{ event.comment ? ", " + event.comment : "" }}
                        </v-list-item-subtitle>
                        {{ event.startTime }}–{{ event.endTime }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer /><v-btn text color="info" @click="print"
                >Drucken</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog> </v-col
      ><v-col class="col-auto" v-if="showNavigation">
        <v-navigation-drawer app clipped permanent right>
          <v-list nav dense>
            <v-list-item
              color="primary"
              :class="{
                'v-list-item--active': isActive(roomCategory),
              }"
              v-for="roomCategory in roomCategoriesFiltered"
              :key="`cat${roomCategory.id}`"
              @click="toggleCat(roomCategory)"
            >
              <v-list-item-content>
                <v-list-item-title>{{
                  roomCategory.description
                }}</v-list-item-title>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-subtitle v-bind="attrs" v-on="on">
                      <span
                        v-for="(room, index) in roomCategory.rooms"
                        :key="`cat${roomCategory.id}room${room.id}`"
                      >
                        <span
                          :class="{
                            'text-decoration-line-through':
                              isActive(roomCategory) &&
                              !selectedRooms.includes(room),
                          }"
                        >
                          {{ room.code }}</span
                        >{{
                          roomCategory.rooms.length - 1 != index ? ", " : ""
                        }}
                      </span>
                    </v-list-item-subtitle>
                  </template>
                  <span>{{
                    roomCategory.rooms.map((el) => el.code).join(", ")
                  }}</span>
                </v-tooltip>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-col></v-row
    >
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import RoomInputMulti from "@/components/RoomInputMulti.vue";
import {
  addDays,
  findMonday,
  formatDate,
  today,
  formatDatespan,
  formatDayOfWeek,
} from "common/utils/date.js";
import { personCode } from "common/utils/people.js";
export default defineComponent({
  name: "Reservations",
  components: {
    DateValue,
    LookupValueInput,
    RoomInputMulti,
  },
  data() {
    return {
      conflicts: [],
      delay: 700,
      roomCategories: [],
      roomCategoryTypes: [],
      roomCategoryType: {},
      clicks: 0,
      dialog: false,
      start: null,
      end: null,
      fullscreen: false,
      timer: null,
      focus: "",
      date: "",
      dateMenu: false,
      loading: false,
      search: "",
      events: [],
      reservations: [],
      selectedRooms: [],
      selectedCats: [],
      room: {},
      time: null,
      showEvents: false,
      roomSearchInput: null,
      showWeekend: false,
      showNavigation: true,
    };
  },
  computed: {
    calendarView() {
      return this.dialog ? "day" : this.weekView ? "week" : "category";
    },
    categories() {
      return this.showEvents
        ? [{ description: "Termine" }, ...this.selectedRooms]
        : this.selectedRooms;
    },
    conflictsFiltered() {
      return this.conflicts.filter(
        (el) => el.conflicts && el.conflicts.length > 0
      );
    },
    eventsFiltered() {
      return this.events.filter((el) =>
        this.categories.map((el) => el.description).includes(el.category)
      );
    },
    roomCategoriesFiltered() {
      return this.roomCategories.filter(
        (el) => el.type.id == this.roomCategoryType.id
      );
    },

    weekDays() {
      return this.showWeekend ? "1,2,3,4,5,6,0" : "1,2,3,4,5";
    },
    weekView() {
      return this.selectedRooms.length <= 1;
    },
  },
  watch: {
    focus() {
      this.date = this.focus;
    },
    selectedRooms() {
      this.fetchReservations();
      this.selectedCats = this.selectedCats.filter((cat) =>
        cat.rooms.some((room) => this.selectedRooms.includes(room))
      );
    },
  },
  methods: {
    formatDatespan,
    formatDayOfWeek,
    formatDate,
    getCategoryText(cat) {
      return cat.description;
    },
    getEventColor(event) {
      return event.color;
    },
    isActive(roomCategory) {
      return this.selectedCats.includes(roomCategory);
      //return this.roomCategories.some((el) => el.id == roomCategory.id);
    },
    setToday() {
      this.focus = today();
    },

    print() {
      this.fullscreen = true;
      window.setTimeout(() => window.print(), 2000);
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async fetchCategories() {
      this.roomCategoryTypes = await this.apiList({
        resource: "common/roomcategorytype",
      });
      this.roomCategoryType = this.roomCategoryTypes[0];
      this.roomCategories = await this.apiList({
        resource: "common/roomcategory",
      });
    },
    async fetchReservations() {
      this.loading = true;
      if (!this.focus) {
        return;
      }
      if (this.weekView && !this.dialog) {
        this.start = findMonday(this.focus);
        this.end = addDays(this.start, 6);
      } else {
        this.start = this.focus;
        this.end = this.focus;
      }

      const newConflicts = [];
      for (const room of this.selectedRooms) {
        const conflicts = await this.apiList({
          resource: "register/reservationconflict",
          query: `startDate=${this.start}&endDate=${this.end}&room=${room.id}`,
        });
        newConflicts.push(conflicts);
      }
      this.conflicts = newConflicts;

      const reservations = await this.apiList({
        resource: "register/reservation",
        query: `startDate=${this.start}&endDate=${this.end}`,
      });
      const newEvents = [];
      for (const item of reservations) {
        const eventStart = item.startTime
          ? new Date(item.startDate + "T" + item.startTime)
          : new Date(item.startDate);
        const eventEnd = item.endTime
          ? new Date(item.endDate + "T" + item.endTime)
          : new Date(item.endDate);

        newEvents.push({
          id: item.id,
          name: item.description
            ? item.description + " (" + personCode(item.reservedFor) + ")"
            : personCode(item.reservedFor),
          category: item.room.description,
          startDate: item.startDate,
          endDate: item.endDate,
          start: eventStart,
          end: eventEnd,
          startTime: item.startTime,
          endTime: item.endTime,
          timed: true,
          comment: item.comment,
          type: "Reservation",
        });
      }

      // fetch lessons
      for (const room of this.selectedRooms) {
        const lessons = await this.apiList({
          resource: "register/lesson",
          query: `startDate=${this.start}&endDate=${this.end}&room=${room.id}`,
        });
        for (const item of lessons) {
          const eventStart = new Date(
            item.date + "T" + item.period.startTime // + '+01:00'
          );
          const eventEnd = new Date(
            item.date + "T" + item.period.endTime // + '+01:00'
          );

          newEvents.push({
            name: item.course
              ? item.course.code +
                " (" +
                item.course.teachers.map((el) => el.code).join(", ") +
                ")"
              : "kein Kurs",
            category: room.description,
            startDate: item.date,
            endDate: item.date,
            start: eventStart,
            end: eventEnd,
            startTime: item.startTime,
            endTime: item.endTime,
            timed: true,
            color: "info",
            type: "Unterricht",
          });
        }
      }

      // fetch lessonChanges
      for (const room of this.selectedRooms) {
        const changes = await this.apiList({
          resource: "register/lessonchange",
          query: `startDate=${this.start}&endDate=${this.end}&room=${room.id}`,
        });
        //console.log(changes);
        for (const item of changes) {
          /*
          const eventStart = new Date(
            item.date + 'T' + item.startTime // + '+01:00'
          );
          const eventEnd = new Date(
            item.date + 'T' + item.endTime // + '+01:00'
          );
*/
          newEvents
            .filter(
              (el) =>
                el.startDate == item.date &&
                el.startTime === item.startTime &&
                el.endDate == item.date &&
                el.endTime === item.endTime &&
                item.rooms.map((r) => r.description).includes(el.category)
            )
            .forEach((el) => {
              el.color = "error";
            });
          /*
          newEvents.push({
            name: item.course
              ? item.course.code +
                ' (' +
                item.course.teachers.map((el) => el.code).join(', ') +
                ')'
              : 'kein Kurs',
            category: room.description,
            startDate: item.date,
            endDate: item.date,
            start: eventStart,
            end: eventEnd,
            startTime: item.startTime,
            endTime: item.endTime,
            timed: true,
            color: 'error',
            type: 'Unterricht',
          });*/
        }
      }

      // fetch events
      const calendarEvents = await this.apiList({
        resource: "course/event",
        query: `startDate=${this.start}&endDate=${this.end}`,
      });
      for (const item of calendarEvents) {
        const eventStart = item.startTime
          ? new Date(item.startDate + "T" + item.startTime)
          : new Date(item.startDate);
        const eventEnd = item.endTime
          ? new Date(item.endDate + "T" + item.endTime)
          : new Date(item.endDate);
        newEvents.push({
          name: item.description,
          category: "Termine",
          startDate: item.startDate,
          endDate: item.endDate,
          start: eventStart,
          end: eventEnd,
          startTime: item.startTime,
          endTime: item.endTime,
          timed: item.startTime,
          color: "success",
          type: "Termin",
        });
      }

      this.events = newEvents;

      this.loading = false;
    },
    showDate() {
      this.dialog = true;
    },
    showEvent({ nativeEvent, event }) {
      if (event.id) {
        this.$router.push({
          name: "RoomReservationEdit",
          params: { id: event.id },
          query: this.$route.query,
        });
      }

      nativeEvent.stopPropagation();
    },
    doubleClick(event) {
      //simulate doubleclick:
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.clicks = 0;
        }, this.delay);
      } else {
        this.newEvent(event.date, event.time);
      }
    },
    setRoom(event) {
      this.room = event.category;
    },
    newEvent(date, time) {
      if (this.selectedRooms.length == 1) {
        this.room = this.selectedRooms[0];
      }
      this.date = date;
      this.time = time;
      this.$router.push({
        name: "RoomReservationAdd",
        params: { id: 0 },
        query: this.$route.query,
      });
    },
    toggleCat(roomCategory) {
      if (this.selectedCats.includes(roomCategory)) {
        // remove cat
        this.selectedCats = this.selectedCats.filter(
          (el) => el.id != roomCategory.id
        );
        //remove all rooms in cat
        this.selectedRooms = this.selectedRooms.filter(
          (el) => !roomCategory.rooms.map((el) => el.id).includes(el.id)
        );
      } else {
        //if not yet selected: add cat and all rooms
        this.selectedCats.push(roomCategory);
        this.selectedRooms = [...this.selectedRooms, ...roomCategory.rooms];
      }
    },
  },

  mounted() {
    this.setToday();
    this.fetchCategories();
    this.fetchReservations();
  },
});
</script>
