<template>
  <v-container>
    <v-app-bar dark app color="primary" dense>
      <v-btn icon>
        <v-icon>mdi-database</v-icon>
      </v-btn>

      <v-toolbar-title>Objekte</v-toolbar-title>
      <v-spacer />
      <SearchField v-model="search"></SearchField>
      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab :to="{ name: 'ObjectsSubject' }"> Fächer </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <router-view :search="search"></router-view>
  </v-container>
</template>

<script>
import SearchField from "@/components/SearchField.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Objects",
  components: { SearchField },
  data() {
    return {
      search: null,
    };
  },
});
</script>
