<template>
  <v-list-item class="px-4" :to="nodetails ? to : null" @[ev]="click">
    <v-list-item-content>
      <v-list-item-title
        >{{ value.code }}
        <template v-if="subtitle">({{ subtitle }})</template></v-list-item-title
      >
      <v-list-item-subtitle v-if="value.majorSubjects">
        {{ value.majorSubjects.join(", ") }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="!nodetails && clickable">
      <v-btn :to="to" @click.stop icon>
        <v-icon> mdi-dots-horizontal </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "SchoolClass",
  props: {
    value: null,
    clickable: Boolean,
    nolink: Boolean,
    nodetails: Boolean,
  },
  computed: {
    subtitle() {
      let result = "";
      if (this.value.teacher) {
        result = this.value.teacher.code;
      }
      if (this.value.substituteTeacher) {
        if (result.length) result += ", ";
        result += this.value.substituteTeacher.code;
      }
      if (this.value.studentCount) {
        if (result.length) result += ", ";
        result += this.value.studentCount.total;
      }
      return result;
    },
    ev() {
      return this.clickable && this.nodetails ? "click" : null;
    },
    to() {
      if (this.nolink || this.nodetail) {
        return null;
      }
      return this.clickable ? "/schoolclasses/" + this.value.id : null;
    },
  },
  methods: {
    click() {
      this.$emit("click", this.value);
    },
  },
});
</script>
